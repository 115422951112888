
import React, { useState, useContext } from 'react';
import { Box, Typography, Collapse, IconButton, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Link } from 'react-router-dom';
import { MixpanelContext } from '../../contexts/MixpanelContext';

interface FaqItemProps {
    question: string;
    answer: string;
    isOpen: boolean;
    onToggle: () => void;
}

const FaqItem: React.FC<FaqItemProps> = ({
    question,
    answer,
    isOpen,
    onToggle,
}) => {
    const formattedAnswer = (answer: string) => {
        const linkStyle = {
            color: 'rgb(56, 116, 203)',
            textDecoration: 'none',
        };

        if (answer.includes('request a quote')) {
            return (
                <span>
                    {answer.split('request a quote')[0]}
                    <Link to="/contact-sales" style={linkStyle}>
                        request a quote
                    </Link>
                    {answer.split('request a quote')[1]}
                </span>
            );
        } else if (answer.includes('sales team')) {
            return (
                <span>
                    {answer.split('sales team')[0]}
                    <Link to="/contact-sales" style={linkStyle}>
                        sales team
                    </Link>
                    {answer.split('sales team')[1]}
                </span>
            );
        } else {
            return answer;
        }
    };

    const { sendAnalyticsEvent } = useContext(MixpanelContext)

    return (
        <Box
            marginBottom={3}
            bgcolor="#F2FFFF"
            onClick={onToggle}
            style={{ cursor: 'pointer' }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={2}
            >
                <Typography variant="h6">{question}</Typography>
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        onToggle();

                        sendAnalyticsEvent('FaqItemOpen', {
                          eventCategory: 'User Interaction',
                          action: 'User opened FAQ Item'
                        })
                    }}
                >
                    {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
            <Collapse in={isOpen}>
                <Typography padding={2}>{formattedAnswer(answer)}</Typography>
            </Collapse>
            <Divider />
        </Box>
    );
};

export default FaqItem;