import React from "react";

interface GetInitialsProps {
  name: string;
}

const GetInitials: React.FC<GetInitialsProps> = ({ name }) => {
  const getInitials = (name: string) => {
    const words = name.split(" ");
    return words
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  const initials = getInitials(name);

  return <>{initials}</>;
};

export default GetInitials;