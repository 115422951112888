import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
const PrivacyContent = () => {
    return (
        <Box
            width={{ xs: "95%", md: "50%" }}
            marginX="auto"
            marginTop={3}
            marginBottom={2}
        >
            <Typography variant="h3" marginBottom={3} color="primary">
                Consultant.ai Privacy Policy
            </Typography>
            <section>
                <Typography variant="h5" color="primary">
                    1. Introduction
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    At Consultant.ai, your privacy is important to us. This
                    Privacy Policy explains how we collect, use, disclose, and
                    safeguard your information when you visit our website or use
                    our services. Please read this Privacy Policy carefully. If
                    you do not agree with the terms of this policy, please do
                    not access the site or use our services.
                </Typography>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    2. Information We Collect
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    We may collect information about you in various ways:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1" marginY={1}>
                            <strong>Personal Information:</strong> We collect
                            personal information that you provide to us when you
                            register for an account, update your profile, or
                            request support. This may include your name, email
                            address, and other identifying information.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" marginY={1}>
                            <strong>Usage Information:</strong> We collect
                            information about your use of our website and
                            services, such as your search queries, the content
                            you view or interact with, and the date and time of
                            your activities.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" marginY={1}>
                            <strong>Device and Log Information:</strong> We
                            collect information about the device you use to
                            access our services, such as your IP address,
                            browser type, operating system, and device
                            identifiers.
                        </Typography>
                    </li>
                </ul>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    3. Use of Information
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    We use the information we collect for various purposes,
                    including:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1" marginY={1}>
                            To provide, maintain, and improve our services.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" marginY={1}>
                            To communicate with you and respond to your
                            inquiries or support requests.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" marginY={1}>
                            To personalize your experience on our website and
                            tailor content to your interests.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" marginY={1}>
                            To monitor and analyze usage and trends, and improve
                            the functionality and user experience of our
                            services.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" marginY={1}>
                            To detect, prevent, or investigate security
                            breaches, fraud, or other potentially illegal
                            activities.
                        </Typography>
                    </li>
                </ul>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    4. Sharing of Information
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    We may share your information with third parties in the
                    following situations:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1" marginY={1}>
                            <strong>Service Providers:</strong> We may share
                            your information with third-party service providers
                            that perform services on our behalf, such as
                            hosting, analytics, and customer support.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" marginY={1}>
                            <strong>Legal Requirements:</strong> We may disclose
                            your information if required to do so by law or in
                            response to a legal request, such as a subpoena or
                            court order.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" marginY={1}>
                            <strong>Business Transfers:</strong> We may share or
                            transfer your information in connection with a
                            merger, acquisition, or sale of assets, or in the
                            event of bankruptcy.
                        </Typography>
                    </li>
                </ul>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    5. Security
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    We take reasonable measures to protect your information from
                    unauthorized access, disclosure, or destruction. However, no
                    method of electronic transmission or storage is completely
                    secure, and we cannot guarantee the absolute security of
                    your information.
                </Typography>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    6. Cookies and Tracking Technologies
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    We may use cookies, web beacons, and other tracking
                    technologies to collect information about your use of our
                    website and services. Cookies are small text files stored on
                    your device that help us to remember your preferences,
                    analyze website traffic, and improve your experience. You
                    may choose to disable cookies through your browser settings;
                    however, doing so may limit your ability to use certain
                    features of our website and services.
                </Typography>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    7. Third-Party Websites
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    Our website may contain links to third-party websites that
                    are not controlled or operated by Consultant.ai. We
                    encourage you to review the privacy policies of these
                    websites, as we have no control over their content or
                    privacy practices.
                </Typography>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    8. Children's Privacy
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    Our services are not intended for use by children under the
                    age of 13, and we do not knowingly collect personal
                    information from children under 13. If we become aware that
                    we have collected personal information from a child under
                    the age of 13, we will take steps to remove that information
                    and terminate the child's account.
                </Typography>
            </section>
            <section>
                <Typography variant="h5" color="primary">
                    9. Changes to This Privacy Policy
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    We may update this Privacy Policy from time to time to
                    reflect changes in our practices or for other operational,
                    legal, or regulatory reasons. We will notify you of any
                    material changes by posting the updated policy on our
                    website, and we encourage you to review this policy
                    periodically to stay informed about our privacy practices.
                </Typography>
            </section>
            <section>
                <Typography variant="h5" color="primary">
                    10. Contact Us
                </Typography>
                <Typography variant="body1" marginLeft={4.5} marginY={2}>
                    If you have any questions or concerns about this Privacy
                    Policy, please contact us at support@Consultant.ai.com or by
                    using the <Link to="/contact">contact form</Link> on our
                    website.
                </Typography>
            </section>
            <footer>
                <Typography variant="body1" marginLeft={4.5} marginY={2}>
                    &copy; {new Date().getFullYear()} Consultant.ai. All rights
                    reserved.
                </Typography>
            </footer>
        </Box>
    );
};

export default PrivacyContent;
