import { Box, Grid, Typography } from "@mui/material";
import { Container } from "@mui/material";
import TitleText from "./elements/TitleText";
import MyButton from "./elements/MyButton";
import MyPlayer from "./elements/MyPlayer";
import LovedByDevelopers from "./components/LoveByDevelopers/LovedByDevelopers";
import InfoBlock from "./components/InfoBlock/InfoBlock";
import TextInfoBlock from "./components/InfoBlock/TextInfoBlock/TextInfoBlock";
import GifInfoBlock from "./components/InfoBlock/GifInfoBlock/GifInfoBlock";
import dataBlock from "./contentData/InfoBlock/textBlockData01.json";
import WelcomeSoftware from "./components/WelcomeSoftware/WelcomeSoftware";
import { ThemeProvider } from "@mui/material/styles";
import StartBuilding from "./components/StartBuilding/StartBuilding";
import { ArrowForward } from "@mui/icons-material";
import FooterBlock from "./components/FooterBlock/FooterBlock";
import Efficiency from "./components/Efficiency/Efficiency";
const gifImage1: string = "/testimages/gif1.gif";
const backgroundSrc: string = "/testimages/background34.png";

const theme = {};

const Hero = () => {
    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    fontSize: "16px",
                    fontFamily: "Inter Display,sans-serif",
                    backgroundImage: `url(${backgroundSrc})`,  
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    
                }}
            >
                <Container
                    sx={{
                        marginTop: "150px",
                        maxWidth: "1024px",
                    }}
                >

                    <Box>
                        <TitleText>
                            {"Your personal project management consultant"}
                        </TitleText>
                    </Box>
                    <Box
                        sx={{
                            textAlign: "center",
                            marginTop: "30px",
                        }}
                    >
                        <Typography
                            variant="h5"
                            component="h5"
                            sx={{
                                fontSize: {
                                    xs: "1.25em",
                                    sm: "1.3em",
                                    lg: "1.5em",
                                },
                            }}
                        >
                            A consultant guides you through each step, crafting{" "}
                            <strong>tailored work plans</strong> to ensure your
                            team has clear direction.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            textAlign: "center",
                            marginTop: "60px",
                        }}
                    >
                        <MyButton
                            slideSpeed={0.3}
                            buttonColor={"rgb(56, 116, 203)"}
                        >
                            <Typography
                                component="span"
                                color="white"
                                sx={{
                                    fontSize: "1.5em",
                                    letterSpacing: "-0.24px",
                                    position: "relative",
                                    // p: 4,
                                    paddingX: "15px",
                                    paddingY: "10px",
                                }}
                            >
                                <strong>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <span>Get Started </span>{" "}
                                        <ArrowForward
                                            sx={{ marginLeft: "10px" }}
                                        />
                                    </Grid>
                                </strong>
                            </Typography>
                        </MyButton>
                    </Box>
                    <Box
                        // className="glow"
                        sx={{
                            // textAlign: 'center',
                            marginTop: "60px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <MyPlayer url="https://youtu.be/o1chMISeTC0?si=xrePVnsNGU5fzawT" />
                    </Box>

                    <Box
                        sx={{
                            // textAlign: 'center',
                            marginTop: "60px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <LovedByDevelopers />
                    </Box>
                    <Box mt={15}>
                        <InfoBlock
                            textBlockOnLeft={true}
                            TextInfoBlock={TextInfoBlock}
                            dataBlock={dataBlock}
                            GifInfoBlock={GifInfoBlock}
                            gifImage={gifImage1}
                        />
                    </Box>
                    {/* <Box mt={15}>
                        <InfoBlock
                            textBlockOnLeft={false}
                            TextInfoBlock={TextInfoBlock}
                            dataBlock={dataBlock}
                            GifInfoBlock={GifInfoBlock}
                            gifImage={gifImage1}
                        />
                    </Box> */}
                    {/* 
                    <Box mt={20}>
                        <PrivacyFirst />
                    </Box> */}


                    <Box mt={20}>
                        <WelcomeSoftware />
                    </Box>

                    <Box mt={20}>
                        <Efficiency />
                    </Box>

                    <Box marginTop={20} marginBottom={10}>
                        <StartBuilding />
                    </Box>

                    <Box mt={15}>
                        <FooterBlock />
                    </Box>
                </Container>
            </Box>
        </ThemeProvider>
    );
};

export default Hero;
