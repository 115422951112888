import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const reviews = [
    {
        text: 'Thanks to consultant.ai, we are now able to budget the team’s time and efforts much more scientifically, leading to increased operational efficiency.',
        author: 'Samyuktha Shivakumar',
        position: 'Global Marketing Operation Manager',
        company: 'Thoughtworks',
    },
    {
        text: 'With consultant.ai, we’re 30% more efficient at delivering hundreds of campaigns seen by millions of customers.',
        author: 'Tye Rapley-Hawkins',
        position: 'Head of Delivery at 02 Priority',
        company: 'Telefonica',
    },
    {
        text: 'Since adopting consultant.ai, our global marketing department has seen a 40% improvement in cross-team collaboration for successful end-to-end campaign planning and execution.',
        author: 'Sarah Pharr',
        position: 'AVP, Marketing',
        company: 'Genpact',
    },
];

const Reviews: React.FC = () => {
    const [activeReviewIndex, setActiveReviewIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isPaused) {
                setActiveReviewIndex(
                    (prevIndex) => (prevIndex + 1) % reviews.length
                );
            }
        }, 10000);

        return () => clearInterval(interval);
    }, [isPaused]);

    const handlePointClick = (index: number) => {
        setActiveReviewIndex(index);
    };

    const handleMouseEnter = () => {
        setIsPaused(true);
    };

    const handleMouseLeave = () => {
        setIsPaused(false);
    };

    return (
        <Box
            position="relative"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="300px"
            marginBottom="7rem"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <TransitionGroup component={null}>
                <CSSTransition key={activeReviewIndex} timeout={300}>
                    {(state) => (
                        <div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                opacity: state === 'entered' ? 1 : 0,
                                transform: `translateY(${
                                    state === 'entered' ? 0 : -20
                                }px)`,
                                transition:
                                    'opacity 300ms ease-in-out, transform 300ms ease-in-out',
                            }}
                        >
                            <Box
                                bgcolor="#F2FFFF"
                                p={3}
                                borderRadius={4}
                                width="70%"
                                height="300px"
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-evenly"
                            >
                                <Typography variant="h6" align="center">
                                    {reviews[activeReviewIndex].text}
                                </Typography>
                                <Typography variant="subtitle1" align="center">
                                    {reviews[activeReviewIndex].author}
                                </Typography>
                                <Typography variant="body1" align="center">
                                    {reviews[activeReviewIndex].position}
                                    {', '}
                                    <span style={{ fontWeight: 'bold' }}>
                                        {reviews[activeReviewIndex].company}
                                    </span>
                                </Typography>
                            </Box>
                        </div>
                    )}
                </CSSTransition>
            </TransitionGroup>
            <Box
                position="absolute"
                bottom={-50}
                display="flex"
                justifyContent="center"
                gap={1}
            >
                {reviews.map((_, index) => (
                    <Box
                        key={index}
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                transform: 'scale(1.2)',
                            },
                        }}
                        onClick={() => handlePointClick(index)}
                    >
                        <Box
                            bgcolor={
                                activeReviewIndex === index ? 'black' : 'gray'
                            }
                            m={1}
                            width={6}
                            height={6}
                            borderRadius="50%"
                            display="inline-block"
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default Reviews;