import { useContext, useEffect, useState } from "react";
import { ProcessContext } from "../../contexts/ProcessContext";
import { Box, Drawer, Skeleton, Stack } from "@mui/material";

import { useWindowWidthGreaterThan } from "../../hooks/useWindowWidthGreaterThan";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view";

import TreeNode from "./TreeNode";
import {
    collectAllNodesWithChildren,
    collectNewNodes,
    foundNodesInBranch,
    foundNodesInBranchForNewNodes,
} from "./sideDrawerUtil";
import { itemPosition } from "./sideDrawerSettings";

const SideDrawer = () => {
    const processContext = useContext(ProcessContext);
    const { mobileOpen, setMobileOpen, treeData, step, setStep } =
        processContext;

    const [expandedNodes, setExpandedNodes] = useState([]);
    const [currentNodes, setCurrentNodes] = useState([]);

    useEffect(() => {
        if (treeData) {
            let nodesTempList = collectAllNodesWithChildren(treeData);
            let newNodes = collectNewNodes(currentNodes, nodesTempList);
            let additionalBranchNodes = foundNodesInBranchForNewNodes(
                treeData,
                newNodes
            );
            setCurrentNodes(nodesTempList);
            setExpandedNodes(currentValue => [
                ...currentValue,
                ...newNodes,
                ...additionalBranchNodes,
            ]);
        }
    }, [treeData]);

    useEffect(() => {
        if (treeData) {
            setExpandedNodes(currentValue => [
                ...currentValue,
                ...foundNodesInBranch(step.stepId, treeData),
            ]);
        }
    }, [step]);

    return (
        <Drawer
            variant={useWindowWidthGreaterThan(900) ? "permanent" : "temporary"}
            anchor="left"
            open={useWindowWidthGreaterThan(900) ? true : mobileOpen}
            onClose={() => setMobileOpen(!mobileOpen)}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: "240px",
                },
            }}
        >
            <Box sx={{ p: 0, pl: 0 }} marginTop="50px">
                {treeData ? (
                    <TreeView
                        aria-label="file system navigator"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        expanded={expandedNodes}
                        onNodeToggle={(event, nodeIds) => {
                            // Prevent collapse of the branch containing the active (selected node)
                            // setExpandedNodes( [...nodeIds, ...foundNodesInBranch(step.stepId, treeData)] );
                            setExpandedNodes([...nodeIds]);
                        }}
                    >
                        {treeData.map(item => (
                            <TreeNode
                                currentStep={step}
                                treeData={treeData}
                                setStep={setStep}
                                key={item.name}
                                nodes={item}
                                itemPosition={itemPosition}
                            />
                        ))}
                    </TreeView>
                ) : (
                    <Stack direction="row" alignItems="center" marginTop={2}>
                        <Skeleton variant="rectangular" width="175px" />
                    </Stack>
                )}
            </Box>
        </Drawer>
    );
};

export default SideDrawer;
