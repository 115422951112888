import React, {useState} from 'react';
import {Box, Grid} from "@mui/material";
import NewFeaturesBlock from "../NewFeaturesBlock/NewFeaturesBlock";
import {blockData} from "../welcomeSoftwareType"
import newFeaturesData from "../../../contentData/NewFeaturesBlock/newFeatures.json"
import BrowserMockup from "../BrowserMockup/BrowserMockup";
import MyFadeInAnimation from "../../../elements/MyFadeInAnimation";

const temp:NewFeaturesData= newFeaturesData
interface NewFeaturesData{
    features: blockData[];
}
const BodyBlock = () => {
    const [featuresState, setFeatureState] = useState( [ true, ...Array(temp.features.length -1).fill(false)] );

    const changeFeaturesState = ( k:number ) =>{
        if ( !featuresState[k] ){
            const newState:boolean[] = Array(temp.features.length).fill(false);
            newState[k] = true;
            setFeatureState([...newState]);
        }
    }

    return (
        <Box>
            <Grid container spacing={2} >
                <Grid item xs={12} sm={4} sx={{marginTop: "3rem"}}>
                    {temp.features.map((data, k) => {
                        return (
                            <MyFadeInAnimation duration={500} rootMargin={-50}>
                                <Box pt={2} key={k}>
                                    <NewFeaturesBlock
                                        blockData={data}
                                        k={k}
                                        isOpen={featuresState[k]}
                                        changeFeaturesState={changeFeaturesState}
                                    />
                                </Box>
                            </MyFadeInAnimation>
                        )
                    })}
                </Grid>
                <Grid item xs={12} sm={8}>
                    <BrowserMockup/>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BodyBlock;