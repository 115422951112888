import React from 'react';
import {Box} from "@mui/material";
import WelcomeSoftwareTitle from "./WelcomeSoftwareTitle/WelcomeSoftwareTitle";
import BodyBlock from "./BodyBlock/BodyBlock";

const WelcomeSoftware = () => {
    return (
        <Box>
            <WelcomeSoftwareTitle/>
            <BodyBlock />
        </Box>
    );
};

export default WelcomeSoftware;