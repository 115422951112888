import { useEffect, useState, useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Alert, CircularProgress } from "@mui/material";
import FormData from 'form-data';
import axios from '../../utils/AxiosInstance';
import { AxiosError } from 'axios';

const baseStyle = {
  alignItems: 'center',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  cursor: 'pointer',
  display: 'flex',
  flex: 1,
  flexDirection: 'row' as 'row',
  padding: '20px',
  paddingTop: '60px',
  paddingBottom: '60px',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const thumb = {
  alignSelf: "center",
  borderRadius: 2,
  border: '1px solid #eaeaea',
  boxSizing: 'border-box' as 'border-box',
  height: '100%',
  width: "50%",
  padding: 4,
};

const img = {
  maxWidth: '100%',
  height: 'auto'
};


function refreshPage() {
  window.location.reload();
}


function DropZone(props: any) {
  const [files, setFiles] = useState<any[]>([]);
  const [rejected, setRejected] = useState<any[]>([]);
  const [showUploadingAlert, setShowUploadingAlert] = useState(false);
  const [uploadingAlertMessage, setUploadingAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { setShowImageModal, processId } = props;

  const onDrop = useCallback((acceptedFiles: any, rejectedFiles: any) => {
    setShowUploadingAlert(false);

    if (acceptedFiles?.length) {
      setRejected([]);
      setFiles(acceptedFiles.map((file: any) => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }

    if (rejectedFiles?.length) {
      setFiles([]);
      setRejected(rejectedFiles);
    }
  }, [])

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {
      'image/*': []
    },
    maxSize: 4096 * 1000, // 4Mb upload limit
    multiple: false,
    onDrop
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);
  
  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <img
        src={file.preview}
        style={img}
        // Revoke data uri after image is loaded
        onLoad={() => { URL.revokeObjectURL(file.preview) }}
      />
    </div>
  ));

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setIsLoading(true);

    if (!files?.length) return

    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('processId', processId)

    try {
      const res = await axios.post(
        '/update-image',
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      
      refreshPage();
    } catch (err) {
      console.error(err)
      if (err instanceof AxiosError && err.response) {
        setUploadingAlertMessage(err.response.data)
      } else {
        setUploadingAlertMessage(
          'Sorry, there was a problem uploading your image. Are you offline?'
        )
      }
      setShowUploadingAlert(true);
    }
  }

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <form onSubmit={handleSubmit}    
      encType="multipart/form-data" 
      style={{
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    }}>
      {isLoading ? (
        <CircularProgress
          color="primary"
          thickness={5}
          size="4em"
        />
      ) : (
        <>
          <div style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          }}>
            <div {...getRootProps({style})}>
              <input name='image' type='file' {...getInputProps()} />
              <p>Drag and drop your image here, or click to select an image. Max size is 4Mb</p>
            </div>
            {thumbs}
          </div>
          {rejected?.length > 0 && (
            <Alert
              severity="error"
              sx={{
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              {rejected[0]?.errors[0]?.message}
            </Alert>
          )}
          {showUploadingAlert && (
            <Alert
              severity="error"
              sx={{
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              {uploadingAlertMessage}
            </Alert>
          )}
          {files?.length ? 
            <Button
              variant="contained"
              type='submit'
              sx={{
                marginBottom: 2,
                marginTop: 2,
              }}
          >
              Upload
          </Button>
          : <></>}
        </>
      )}
    </form>
  );
}

export default DropZone;
