import { useEffect } from "react";
import Header from "../components/global/Header";
import ContactContent from "../components/Contact/ContactContent";
import { useLocation } from "react-router-dom";

const Contact = () => {
    useEffect(() => {
        document.title = "Contact | Consultant.AI";
    }, []);

    const location = useLocation();
    const title = location.state?.title || 'Contact Us / Provide Feedback';

    return (
        <div id="contact" className="page">
            <Header />
            <ContactContent title={title}/>
        </div>
    );
};

export default Contact;
