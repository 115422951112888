import { Dispatch, SetStateAction } from "react";
import { IActiveUser } from "../models/IActiveUser";

export const logUserIn = (
    authToken: string,
    firstName: string,
    isAdmin: boolean,
    setActiveUser: Dispatch<SetStateAction<IActiveUser>>,
    setIsLoggedIn?: Dispatch<SetStateAction<boolean>>
) => {
    localStorage.setItem("authToken", authToken);
    setActiveUser({
        firstName,
        isAdmin,
    });
    if (setIsLoggedIn) setIsLoggedIn(true);
};
