import React from 'react';
// MUI
import  {
  EditOutlined, 
  SaveOutlined,
  CloseOutlined,
  AccountTreeOutlined,
  WorkOutlineOutlined,
  SettingsSuggestOutlined,
  HighlightOffOutlined,
  DeveloperBoardOutlined,
  ImportContactsOutlined,
  NotificationImportantOutlined,
  ReportGmailerrorredOutlined,
  UpgradeOutlined,
  EastOutlined,
  WestOutlined,
} from '@mui/icons-material';

export interface IconType {
  [key: string]: JSX.Element;
}

export const iconType: IconType = {
  "1": <AccountTreeOutlined color="primary"/>,
  "2": <WorkOutlineOutlined color="primary"/>,
  "3": <SettingsSuggestOutlined color="primary"/>,
  "4": <EditOutlined color="primary"/>,
  "5": <SaveOutlined color="primary"/>,
  "6": <CloseOutlined color="primary"/>,
  "7": <HighlightOffOutlined color="primary"/>,
  "8": <DeveloperBoardOutlined color="primary"/>,
  "9": <ImportContactsOutlined color="primary"/>,
  "10": <NotificationImportantOutlined color="primary"/>,
  "11": <ReportGmailerrorredOutlined color="primary"/>,
  "12": <UpgradeOutlined color="primary"/>,
  "13": <EastOutlined color="primary"/>,
  "14": <WestOutlined color="primary"/>,
}