import React, { memo  } from 'react';
import { Handle, Position, NodeResizer, NodeResizeControl } from 'reactflow';

// Import IconType from the file
import { iconType } from './IconTypes';

// Import process Map name - label for functions and Process Types info
import { processTypes, processMapForFunctions, imageLinks, backgroundImageLink } from './infoMapForFunctions';

import { IProcessType } from "../../models/IProcessType";


// MUI
import { 
  Typography, 
  Tooltip,
  Card,
  CardMedia,
  CardContent,
  Paper, 
  Box,
  BackdropRoot

} from "@mui/material";

interface CustomNodeProps {
  id: string;
  data: {
    icon: string;
    header: string;
    text: string;
    tooltip?: string;
    isTooltip?: boolean;
    process: string | boolean;
    isHandles: boolean;
    isComingSoon?: boolean;
    isResize?: boolean; 
    image?: string;
    };
  selected: boolean;
}

export const OutlinedBlueNode = memo(({ id, data, selected }:  CustomNodeProps) => {
 
  
  return (
    <div className= { "node-border" }  onClick={(e)=>{}}>
      <Tooltip 
       placement="bottom"
       title={data.isTooltip? data.tooltip: ""} 
       arrow
       enterDelay={700} 
      >
        
      <div className="flex-box">
        <div className="icon-box">
          {iconType[data.icon]}
        </div>
        <div className="text-box">
          <div className="node-header">{data.header}</div>
          <div className="node-text">{data.text}</div>
        </div>
        {/* Process icon */}
        {data.process && (
        <div className="process-symbol-box">
          <div className="process-symbol"></div>
        </div>
        )}
      </div>
     </Tooltip>
      <Handle type="target" position={Position.Top} id='top' style={{ opacity: data.isHandles? 1 : 0 }}/>
      <Handle type="source" position={Position.Bottom} id='bottom' style={{ opacity: data.isHandles? 1 : 0 }}/>
      <Handle type="target" position={Position.Left} id='left' style={{ opacity: data.isHandles? 1 : 0 }}/>
      <Handle type="source" position={Position.Right}id='right' style={{ opacity: data.isHandles? 1 : 0 }}/>
     
     
      {/* Delete button */}
      {/* <IconButton>
          <HighlightOffOutlined/>
      </IconButton> */}
    </div>
  );
});


export const CardNode = memo(({ id, data, selected }:  CustomNodeProps) => {
    
  return (
    <>
    
    <Card
      onClick={(e)=>{}}
      sx={{
          height: "300px",
          // marginX: { xs: 1, md: 1 },
          cursor: 'pointer',
          // width: '100%',
          // maxWidth: "300px",
          width: "300px",
          // marginY: 1,
      }}
    >
      
      <CardMedia
      // take image link from node data or
          image={
            (typeof data.process === 'string' ? 
            (data.process !== "" ? imageLinks[data.process] : (data.image ? backgroundImageLink[data.image]:""))
            : '')
          } //(typeof data.process === 'string' ? imageLinks[data.process] : '')
          sx={{ 
            height: '40%', 
            bgcolor: 'text.disabled', 
            // display: 'flex', 
            // alignItems: 'start', 
            // justifyContent: 'end',
            paddingTop: 0, 
            paddingRight: 0, 
           
          }}
      >
        <div className={data.isComingSoon? "filter-box": ""}>
     
        {/* !data.process && */}
        { data.isComingSoon && (
        <Typography
            component={'span'}
            variant="h5"
            color="white"
            bgcolor="primary.main"
            sx={{
                textTransform: "none",
                textAlign: "center",
                borderRadius: "5px",
                paddingLeft: 1,
                paddingRight: 1
            }}
        >
            Coming soon
        </Typography>
        )}
        </div>
      </CardMedia>
     
      <Tooltip 
       placement="bottom"
       title={data.isTooltip? data.header + " - " + data.text : ""} 
       arrow
       enterDelay={700} 
      >
        <CardContent>
          <Typography
              fontSize="1.3em"
              overflow="hidden"
              textOverflow="ellipsis"
              color={ !data.isComingSoon ? "primary" : "text.disabled" } // { data.process ? "primary" : "grey" }
              marginBottom={1}
              sx={{
                  textTransform: "none",
                  textAlign: "left",
              }}
          >
              {data.header}
          </Typography>
          <Typography
              variant="body1"
              overflow="hidden"
              textOverflow="ellipsis"
              color={ !data.isComingSoon ? "" : "text.disabled" } // { data.process ? "primary" : "grey" }
              sx={{
                  textTransform: "none",
                  textAlign: "left",
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
              }}
          >
              {data.text}
          </Typography>
        </CardContent>
      </Tooltip>
    </Card>
    <Handle type="target" position={Position.Top} id='top' style={{ opacity: data.isHandles? 1 : 0, top: -2   }}/>
    <Handle type="source" position={Position.Bottom} id='bottom' style={{ opacity: data.isHandles? 1 : 0, bottom: -3  }}/>
    <Handle type="target" position={Position.Left} id='left' style={{ opacity: data.isHandles? 1 : 0, left: -3 }}/>
    <Handle type="source" position={Position.Right}id='right' style={{ opacity: data.isHandles? 1 : 0, right: -3 }}/>
  </>
  );
});


const controlStyle = {
  background: 'transparent',
  border: 'none',
};

export const PaperNode = memo(({ id, data, selected }:  CustomNodeProps) => {
 
  
  return (
    <>
      <NodeResizer color="#ff0071" isVisible={selected && data.isResize} minWidth={100} minHeight={50} maxWidth={600} />
  
      <Tooltip 
          placement="bottom"
          title={data.isTooltip? data.tooltip: ""} 
          arrow
          enterDelay={700} 
          sx = {{
            visibility: "hidden",
          }}
        >
  
        <Paper 
          elevation={6} 
          variant="elevation"  
          sx={{ 
          // width: 150,
          // height: 150,
          padding: 1
            }}
        > 
          <Typography
            fontSize="1.3em"
            // color=""
            marginBottom={1}
            sx={{
                textTransform: "none",
                textAlign: "left",
                
            }}
          >
            {data.header}
          </Typography>
          <Typography
            variant="body1"
            overflow="hidden"
            textOverflow="ellipsis"
            sx={{
                textTransform: "none",
                textAlign: "left",
                display: "-webkit-box",
                // WebkitLineClamp: 5,
                WebkitBoxOrient: "vertical",
            }}
          >
            {data.text}
          </Typography> 
        </Paper>
      </Tooltip>
      {/* <Handle type="target" position={Position.Top} id='top' style={{ opacity: data.isHandles? 1 : 0, top: -2   }}/>
      <Handle type="source" position={Position.Bottom} id='bottom' style={{ opacity: data.isHandles? 1 : 0, bottom: -3  }}/>
      <Handle type="target" position={Position.Left} id='left' style={{ opacity: data.isHandles? 1 : 0, left: -3 }}/>
      <Handle type="source" position={Position.Right}id='right' style={{ opacity: data.isHandles? 1 : 0, right: -3 }}/> */}
    </>
  );
});



