import { useContext, useState } from 'react'
import axios from '../../utils/AxiosInstance'
import {
  Alert,
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import { IContactFormData } from '../../models/IContactForm'
import { AxiosError } from 'axios'
import { MixpanelContext } from '../../contexts/MixpanelContext'

interface ContactFormProps {
  title: string // Title for the page
}

const ContactForm: React.FC<ContactFormProps> = ({ title }) => {
  const initialData: IContactFormData = {
    contactName: '',
    contactEmail: '',
    contactMessage: '',
  }

  const [contactFormData, setContactFormData] =
    useState<IContactFormData>(initialData)

  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertSeverity, setAlertSeverity] = useState<AlertColor | undefined>(
    undefined
  )
  const [alertMessage, setAlertMessage] = useState<string>('')
  const {sendAnalyticsEvent} = useContext(MixpanelContext)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setShowAlert(false)
    setContactFormData((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value }
    })
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') handleSubmit(e)
  }

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement | HTMLInputElement>
  ) => {
    e.preventDefault()
    const { contactName, contactEmail, contactMessage } = contactFormData
    setShowSpinner(true)
    setShowAlert(false)
    setAlertSeverity(undefined)
    setAlertMessage('')
    try {
      // Secondary validation to prevent submitting whitespace, which passes initial validation
      if (
        contactName.trim().length === 0 ||
        contactEmail.trim().length === 0 ||
        contactMessage.trim().length === 0
      )
        throw new Error('All fields are required.')
      await axios.post('https://formspree.io/f/fainir2006@gmail.com', {
        name: contactName,
        email: contactEmail,
        message: contactMessage,
      })
      setAlertSeverity('success')
      setAlertMessage('Message sent successfully!')
      setShowAlert(true)
      setContactFormData(initialData)
    } catch (err) {
      sendAnalyticsEvent('ContactFormSubmissionError', {
        eventCategory: 'User Interaction',
        action: 'Submit Contact Form Error',
      })
      setAlertSeverity('error')
      setAlertMessage(
        err instanceof Error && !(err instanceof AxiosError)
          ? err.message
          : 'Sorry, there was an error sending your message. Please try again later.'
      )
      setShowAlert(true)
    } finally {
      setShowSpinner(false)
    }
  }
  
  return (
    <Box
      id="contact-form"
      width={{ xs: '100%', md: '70%' }}
      padding={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <form onSubmit={handleSubmit}>
        <Stack alignItems="center">
          <Typography variant="h3" marginBottom={3} color="primary">
            {title}
          </Typography>
          <Stack width={{ xs: '90vw', md: '50vw' }}>
            <TextField
              id="contactName"
              value={contactFormData.contactName}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              variant="filled"
              label="Name"
              color="primary"
              margin="dense"
              required
            />
            <TextField
              id="contactEmail"
              type="email"
              value={contactFormData.contactEmail}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              variant="filled"
              label="Email"
              color="primary"
              margin="dense"
              required
            />
            <TextField
              id="contactMessage"
              value={contactFormData.contactMessage}
              onChange={handleChange}
              multiline
              variant="filled"
              minRows={4}
              label="Message"
              color="primary"
              margin="dense"
              required
            />
            {showAlert && (
              <Alert severity={alertSeverity} sx={{ marginTop: 1 }}>
                {alertMessage}
              </Alert>
            )}
            <Button
              type="submit"
              variant="contained"
              disabled={showSpinner ? true : false}
              sx={{ marginTop: 1 }}
              onClick={() => {
                sendAnalyticsEvent('ContactFormSubmission', {
                  eventCategory: 'User Interaction',
                  action: 'Submit Contact Form',
                })
              }}
            >
              {showSpinner ? (
                <CircularProgress color="primary" thickness={5} size="1.75em" />
              ) : (
                'Send'
              )}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  )
}

export default ContactForm
