import { createContext, useState } from "react";

export const ProcessContext = createContext();

const ProcessContextProvider = ({ children }) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [processId, setProcessId] = useState("");
    const [treeData, setTreeData] = useState();
    const [step, setStep] = useState();
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showNextButton, setShowNextButton] = useState(false);

    return (
        <ProcessContext.Provider
            value={{
                mobileOpen,
                setMobileOpen,
                step,
                setStep,
                processId,
                setProcessId,
                treeData,
                setTreeData,
                messages,
                setMessages,
                loading,
                setLoading,
                showNextButton,
                setShowNextButton,
            }}
        >
            {children}
        </ProcessContext.Provider>
    );
};

export default ProcessContextProvider;
