import { 
  useState 
} from "react";

import { 
  Button,
  Menu,
  MenuItem,
  Tooltip,
  
 } from "@mui/material";

import  {
  AddBoxOutlined,
} from '@mui/icons-material';

import { processMapForFunctions } from './infoMapForFunctions';

interface AddNewNodeProps {
  handleAddNewProcessNode: (type: string ) => void;
}


const AddNewProcessCard: React.FC<AddNewNodeProps> = ({ handleAddNewProcessNode}) => {
  // Sub Menu to choose new Node type
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title="Add Process" placement="left-start" enterDelay={500}>
      <Button 
        variant="contained" 
        // onClick={()=>{handleAddNode();}}
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <AddBoxOutlined />
      </Button> 
      </Tooltip>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        
        { Object.keys(processMapForFunctions).length > 0 && (Object.entries(processMapForFunctions).map(([key, value]) => {
            return (
              <MenuItem value={key} key={key} onClick={()=>{handleClose(); handleAddNewProcessNode(key)}}>{value}</MenuItem>
              );
          })
        )}
        <MenuItem onClick={()=>{handleClose(); handleAddNewProcessNode("")}}>No process</MenuItem>
        {/* <MenuItem onClick={()=>{handleClose(); handleAddNode("paper")}}>Paper</MenuItem>
        <MenuItem onClick={()=>{handleClose(); handleAddNode("custom")}}>Custom</MenuItem> */}
      </Menu>
    </>
  );
};

export default AddNewProcessCard;
