import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "../../utils/AxiosInstance";
import { AxiosError } from "axios";
import { ILoginFormData, ILoginErrorStatus } from "../../models/ILoginForm";
import { IAuthContext } from "../../models/IAuthContext";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import {
    validateEmail,
    validatePassword,
} from "../../utils/validation/LoginValidation";

import { logUserIn } from "../../utils/logUserIn";
import { MixpanelContext } from "../../contexts/MixpanelContext";

const LoginForm = () => {
    const authContext = useContext(AuthContext);
    const { setIsLoggedIn, setActiveUser } = authContext as IAuthContext;
    const { sendAnalyticsEvent } = useContext(MixpanelContext)

    const navigate = useNavigate();

    const initialData: ILoginFormData = {
        loginEmail: "",
        loginPassword: "",
    };

    const [loginFormData, setLoginFormData] =
        useState<ILoginFormData>(initialData);

    const [currentFieldDisplayed, setCurrentFieldDisplayed] =
        useState<string>("email");

    const [helperText, setHelperText] = useState<ILoginFormData>({
        loginEmail: "",
        loginPassword: "",
    });
    const [hasError, setHasError] = useState<ILoginErrorStatus>({
        loginEmail: false,
        loginPassword: false,
    });

    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setHelperText(prevState => {
            return { ...prevState, [e.target.id]: "" };
        });
        setHasError(prevState => {
            return { ...prevState, [e.target.id]: false };
        });
        setLoginFormData(prevState => {
            return { ...prevState, [e.target.id]: e.target.value };
        });
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === "Enter") handleValidate();
    };

    const handleValidate = (): void => {
        sendAnalyticsEvent('LoginAttempt', {
          eventCategory: 'Authentication',
          action: 'User Attempted Login',
        })
        if (currentFieldDisplayed === "email") {
            validateEmail(
                loginFormData.loginEmail,
                setHelperText,
                setHasError,
                setCurrentFieldDisplayed
            );
        } else {
            validatePassword(
                loginFormData.loginPassword,
                setHelperText,
                setHasError,
                handleSubmitLogin
            );
        }
    };

    const handleSubmitLogin = async () => {
        const { loginEmail, loginPassword } = loginFormData;
        setShowSpinner(true);
        setShowAlert(false);
        try {
            const res = await axios.post("/auth/login", {
                email: loginEmail.trim(),
                password: loginPassword,
            });
            const { authToken, firstName, isAdmin } = res.data;
            logUserIn(
                authToken,
                firstName,
                isAdmin,
                setActiveUser,
                setIsLoggedIn
            );
            sendAnalyticsEvent('LoginSuccess', {
              eventCategory: 'Authentication',
              action: 'User Successfully Logged In',
            })
            navigate("/dashboard");
        } catch (err) {
            sendAnalyticsEvent('LoginFailed', {
              eventCategory: 'Authentication',
              action: 'User Login Failed',
            })
            console.error(err);
            if (err instanceof AxiosError && err.response) {
                setAlertMessage(err.response.data);
            } else {
                setAlertMessage(
                    "Sorry, we could not log you in. Are you offline?"
                );
            }
            setShowAlert(true);
        } finally {
            setShowSpinner(false);
        }
    };

    return (
        <Box
            id="login-form"
            width={{ xs: "100%", md: "70%" }}
            padding={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={{ xs: "flex-start", sm: "center" }}
            sx={{
                backgroundImage:
                    "url(https://consultantaiassetstorage.blob.core.windows.net/assets/images/auth-form-background.jpg)",
                backgroundPositionX: {
                    xs: "35%",
                    sm: "80%",
                    md: "70%",
                    lg: "90%",
                    xl: "120%",
                },
                backgroundPositionY: { xs: "60%", sm: "80%" },
            }}
        >
            <Typography variant="h4" sx={{ position: 'relative', top: '-4px' }}>Log in to your account</Typography>
            <Typography marginTop={5}>
                {currentFieldDisplayed === "email" ? "Enter your work email address" : "Enter your password"}
            </Typography>

            
            <Stack width={{ xs: "100%", md: "400px" }} marginTop={0.6}>
                {currentFieldDisplayed === "email" && (
                    <TextField
                        autoFocus
                        id="loginEmail"
                        type="email"
                        value={loginFormData.loginEmail}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        placeholder="name@company.com"
                        helperText={helperText.loginEmail}
                        error={hasError.loginEmail}
                        variant="filled"
                        label="Email"
                        color="primary"
                        margin="dense"
                    />
                )}
                {currentFieldDisplayed === "password" && (
                    <TextField
                        autoFocus
                        id="loginPassword"
                        value={loginFormData.loginPassword}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        helperText={helperText.loginPassword}
                        error={hasError.loginPassword}
                        type="password"
                        variant="filled"
                        label="Password"
                        color="primary"
                        margin="dense"
                    />
                )}
                {showAlert && (
                    <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                        <Alert severity="error" sx={{ marginTop: 1 }}>
                            {alertMessage}
                        </Alert>
                    </Stack>
                )}
                <Button
                    variant="contained"
                    onClick={handleValidate}
                    disabled={showSpinner ? true : false}
                    sx={{ marginTop: 1 }}
                >
                    {showSpinner ? (
                        <CircularProgress
                            color="primary"
                            thickness={5}
                            size="1.75em"
                        />
                    ) : currentFieldDisplayed === "email" ? (
                        "Next"
                    ) : (
                        "Log In"
                    )}
                </Button>
            </Stack>
            <Box height="235px">
                <Typography marginTop={41}>
                Don't have an account yet?{" "}
                    <Typography color="primary" component="span">
                        <Link to="/signup">
                            Sign up
                        </Link>
                    </Typography>
                </Typography>
            </Box>
        </Box>
    );
};

export default LoginForm;
