import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Stack, Typography, Box } from "@mui/material";

const DashboardGreeting = () => {
    let greeting = "";
    const hour = new Date().getHours();
    if (hour >= 0 && hour < 12) greeting = "morning";
    if (hour >= 12 && hour < 18) greeting = "afternoon";
    if (hour >= 18 && hour < 21) greeting = "evening";
    if (hour >= 21 && hour < 24) greeting = "night";

    const authContext = useContext(AuthContext);
    const { activeUser } = authContext!;
    const { firstName } = activeUser;
    return (
        <Stack>
            <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
                variant="h1"
                fontSize={{ xs: "1.2em", md: "2em" }}
                fontFamily="'Unica One', Arial, Helvetica, sans-serif"
                marginLeft={1}
                sx={{ textTransform: "uppercase" }}
            >
                {`Greetings, ${firstName}!`}
            </Typography>
            <Typography
                variant="h1"
                fontSize={{ xs: "1.2em", md: "2em" }}
                fontFamily="'Unica One', Arial, Helvetica, sans-serif"
                marginLeft={1}
                sx={{ textTransform: "uppercase" }}
            >
                Create your own Story
            </Typography>
            </Box>
        </Stack>
    );
};

export default DashboardGreeting;
