import React from 'react';
import {Box} from "@mui/material";

interface GifInfoBlockProps {
    gifImage:string;
}

const GifInfoBlock:React.FC<GifInfoBlockProps> = ({gifImage}) => {
    return (
        <Box px={3}>
            <Box
                sx={{
                    backgroundImage:`url("/testimages/bgGif.png")`,
                    backgroundSize: "100% 100%",
                    // border:"2px solid black",
                    height: "100%",
                    borderRadius:"10px",
                }}
            >
                <img width={"100%"} height={"100%"} src={gifImage}
                     style={{
                         padding: "5% 2% 4% 2%",
                         // paddingTop:"3%",
                         // paddingBottom:"3%",
                     }}
                />
                {/*<img*/}
                {/*    src={"https://uploads-ssl.webflow.com/6290be8d112ee934eeb6aaf2/6290be8e112ee94308b6ad02_Rectangle-8.jpg"}*/}
                {/*    // loading={"lazy"} sizes={"(max-width: 479px) 90vw, (max-width: 767px) 71vw, 96vw"}*/}
                {/*    width={"100%"}*/}
                {/*    srcSet={"https://uploads-ssl.webflow.com/6290be8d112ee934eeb6aaf2/6290be8e112ee94308b6ad02_Rectangle-8-p-500.jpeg 500w, https://uploads-ssl.webflow.com/6290be8d112ee934eeb6aaf2/6290be8e112ee94308b6ad02_Rectangle-8-p-800.jpeg 800w, https://uploads-ssl.webflow.com/6290be8d112ee934eeb6aaf2/6290be8e112ee94308b6ad02_Rectangle-8.jpg 1024w"}*/}
                {/*    alt={""} className={"image-background"} />*/}
            </Box>
        </Box>
    );
};

export default GifInfoBlock;