import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from "react";
import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";

import DashboardMenu from "../Dashboard/DashboardMenu";
import ChatHistory from "./ChatHistory";

import { IChatHistoryRecord } from "../../models/IChatHistoryRecord";

const ChatHistoryTable = ({
    data,
    selectedChatIds,
    setSelectedChatIds,
    renderedWithin,
    allowSelectMultiple,
    handleRedirectToNewProcess,
    handlePopulateModal,
}: {
    data: IChatHistoryRecord[];
    selectedChatIds?: string[];
    setSelectedChatIds?: Dispatch<SetStateAction<string[]>>;
    renderedWithin: (props?: any) => JSX.Element;
    allowSelectMultiple: boolean;
    handleRedirectToNewProcess?: (parentProcessId: string) => void;
    handlePopulateModal?: (chat: IChatHistoryRecord) => void;
}) => {
    const [indeterminate, setIndeterminate] = useState<boolean>(false);
    const [selectAllCheckbox, setSelectAllCheckbox] = useState<boolean>(false);

    useEffect(() => {
        if (allowSelectMultiple) setSelectedChatIds!([]);
    }, [allowSelectMultiple, setSelectedChatIds]);

    useEffect(() => {
        const handleSelectAllCheckbox = () => {
            let allChatsAreSelected = true;
            data.forEach(chat => {
                if (!selectedChatIds?.includes(chat.id)) {
                    allChatsAreSelected = false;
                }
            });
            setIndeterminate(false);
            setSelectAllCheckbox(false);
            if (allChatsAreSelected) {
                setIndeterminate(false);
                setSelectAllCheckbox(true);
            } else if (!selectedChatIds?.length) {
                setIndeterminate(false);
                setSelectAllCheckbox(false);
            } else {
                setIndeterminate(true);
                setSelectAllCheckbox(true);
            }
        };
        if (allowSelectMultiple) handleSelectAllCheckbox();
    }, [selectedChatIds, allowSelectMultiple, data]);

    const handleRowClick = useCallback(
        (chat: IChatHistoryRecord) => {
            if (renderedWithin === DashboardMenu) {
                if (
                    allowSelectMultiple &&
                    setSelectedChatIds &&
                    selectedChatIds
                ) {
                    setSelectedChatIds(
                        selectedChatIds.includes(chat.id)
                            ? selectedChatIds.filter(
                                  selectedChatId => selectedChatId !== chat.id
                              )
                            : [...selectedChatIds, chat.id]
                    );
                } else {
                    handleRedirectToNewProcess!(chat.id);
                }
            }
            if (renderedWithin === ChatHistory) {
                handlePopulateModal!(chat);
            }
        },
        [
            allowSelectMultiple,
            handlePopulateModal,
            handleRedirectToNewProcess,
            renderedWithin,
            selectedChatIds,
            setSelectedChatIds,
        ]
    );

    return (
        <Table size="small" sx={{ minWidth: "100%" }}>
            <TableHead>
                <TableRow>
                    {allowSelectMultiple && (
                        <TableCell>
                            <Checkbox
                                size="small"
                                indeterminate={indeterminate}
                                checked={selectAllCheckbox}
                                onClick={() => {
                                    if (selectedChatIds!.length) {
                                        setSelectedChatIds!([]);
                                    }
                                    if (
                                        !selectedChatIds!.length ||
                                        selectedChatIds!.length !== data.length
                                    ) {
                                        const allChatIds: string[] = [];
                                        data.forEach(chat =>
                                            allChatIds.push(chat.id)
                                        );
                                        setSelectedChatIds!(allChatIds);
                                    }
                                }}
                                sx={{ padding: 0 }}
                            />
                        </TableCell>
                    )}
                    <TableCell sx={{ fontWeight: "bold" }}>Title</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Created at</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Created by</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(chat => (
                    <TableRow
                        onClick={() => handleRowClick(chat)}
                        key={chat.id}
                        sx={{
                            "&:hover": {
                                cursor: "pointer",
                                backgroundColor: theme =>
                                    selectedChatIds?.includes(chat.id)
                                        ? theme.palette.primary.main
                                        : "#ddd",
                            },
                            backgroundColor: theme =>
                                allowSelectMultiple &&
                                selectedChatIds?.includes(chat.id)
                                    ? theme.palette.primary.main
                                    : "initial",
                            "& > td": {
                                color:
                                    allowSelectMultiple &&
                                    selectedChatIds?.includes(chat.id)
                                        ? "white"
                                        : "initial",
                            },
                        }}
                    >
                        {allowSelectMultiple && (
                            <TableCell
                                width="10%"
                                sx={{
                                    backgroundColor: "white",
                                }}
                            >
                                <Checkbox
                                    size="small"
                                    checked={
                                        selectedChatIds?.includes(chat.id)
                                            ? true
                                            : false
                                    }
                                    sx={{ padding: 0 }}
                                />
                            </TableCell>
                        )}
                        <TableCell width="20%">
                            {chat.process_ticket.ticket_title}
                        </TableCell>
                        <TableCell width="20%">
                            {new Date(chat.created_at).toLocaleString([], {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                            })}
                        </TableCell>
                        <TableCell width="40%">
                            {chat.user_id}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default ChatHistoryTable;
