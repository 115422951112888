import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MuiTheme from "./styles/global/MuiTheme";
import "./styles/global/App.css";

import MixpanelContextProvider from "./contexts/MixpanelContext";
import AuthContextProvider from "../src/contexts/AuthContext";
import ProcessContextProvider from "./contexts/ProcessContext";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import Process from "./pages/Process";
import Profile from "./pages/Profile";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import WorkspacePage from "./components/Workspace/WorkspacePage"
import WorkspacePageNew from "./components/Workspace/WorkspacePageNew"

import PrivateRoute from "./PrivateRoute";
import Pricing from "./pages/Pricing";
import PostSignupContent from "./components/PostSignupPage/PostSignupContent";

function App() {
    const theme = createTheme(MuiTheme);
    return (
        <div id="App">
            <ThemeProvider theme={theme}>
                <MixpanelContextProvider>
                    <AuthContextProvider>
                        <ProcessContextProvider>
                            <BrowserRouter>
                                <Routes>
                                    <Route
                                        path="/"
                                        element={
                                            <PrivateRoute userShouldBe="loggedOut">
                                                <Home />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/about"
                                        element={
                                            <PrivateRoute userShouldBe="loggedIn">
                                                <Home />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/login"
                                        element={
                                            <PrivateRoute userShouldBe="loggedOut">
                                                <Login />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/signup"
                                        element={
                                            <PrivateRoute userShouldBe="loggedOut">
                                                <Signup />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/dashboard"
                                        element={
                                            <PrivateRoute userShouldBe="loggedIn">
                                                <Dashboard />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/post-signup"
                                        element={
                                            <PrivateRoute userShouldBe="loggedIn">
                                                <PostSignupContent />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/chat"
                                        element={
                                            <PrivateRoute userShouldBe="loggedIn">
                                                <Navigate to="/dashboard" />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/chat/*"
                                        element={
                                            <PrivateRoute userShouldBe="loggedIn">
                                                <Process />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/profile"
                                        element={
                                            <PrivateRoute userShouldBe="loggedIn">
                                                <Profile />
                                            </PrivateRoute>
                                        }
                                    />
                                    {/* <Route
                                        path="/logout"
                                        element={<Home isLoggedOut={true} />}
                                    /> */}
                                    <Route
                                        path="/contact"
                                        element={<Contact />}
                                    />
                                    <Route
                                        path="/contact-sales"
                                        element={<Contact />}
                                    />
                                    <Route
                                        path="/pricing"
                                        element={<Pricing />}
                                    />
                                    <Route path="/terms" element={<Terms />} />
                                    <Route
                                        path="/privacy"
                                        element={<Privacy />}
                                    />
                                    <Route
                                        path="*"
                                        element={
                                            <PrivateRoute userShouldBe="loggedOut">
                                                <Home />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/workspace/:workspaceId/:workspaceName"
                                        element={
                                            <PrivateRoute userShouldBe="loggedIn">
                                                <WorkspacePageNew/>
                                            </PrivateRoute>
                                        }
                                    />
                                </Routes>
                            </BrowserRouter>
                        </ProcessContextProvider>
                    </AuthContextProvider>
                </MixpanelContextProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
