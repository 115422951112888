import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import { blockData } from "../welcomeSoftwareType";

import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";

import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./NewFeaturesBlock.css";
import CollapsedText from "./CollapsedText";

interface NewFeaturesBlockProps {
    blockData: blockData;
    k: number;
    isOpen: boolean;
    changeFeaturesState: any;
}

const imageToIconMap: Record<string, React.ReactNode> = {
    "newFeaturesMemo.png": (
        <AutoStoriesOutlinedIcon style={{ color: "rgb(56, 116, 203)" }} />
    ),
    "newFeaturesRobot.png": (
        <PsychologyOutlinedIcon style={{ color: "rgb(56, 116, 203)" }} />
    ),
    "newFeaturesMagnifire.png": (
        <TaskOutlinedIcon style={{ color: "rgb(56, 116, 203)" }} />
    ),
};

const NewFeaturesBlock: React.FC<NewFeaturesBlockProps> = ({
    blockData,
    k,
    isOpen,
    changeFeaturesState,
}) => {
    const [showText, setShowText] = useState(isOpen);

    useEffect(() => {
        setShowText(isOpen);
    }, [isOpen]);

    // useEffect(() =>)
    return (
        <div>
            <Box>
                <Paper
                    elevation={2}
                    sx={{
                        "&:hover": {
                            transform: "scale(0.95)",
                            transition: "transform 1000ms",
                        },
                        transform: "scale(1)",
                        transition: "transform 1000ms",
                    }}
                >
                    <Box p={2}>
                        <Box
                            onClick={() => changeFeaturesState(k)}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                cursor: "pointer",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    width={50}
                                    height={50}
                                    mt={1}
                                    style={{ backgroundColor: "white" }}
                                    sx={{
                                        display: "flex",
                                        borderRadius: "999px",
                                        justifyContent: "Center",
                                        alignItems: "Center",
                                        border: "2px solid rgb(56, 116, 203)",
                                        flexShrink: "0",
                                    }}
                                >
                                    {imageToIconMap[blockData.image]}
                                    {/* <img width={"32px"}  src={`/testimages/${blockData.image}`}/> */}
                                </Box>
                                <Box pl={2}>
                                    <Typography variant="body1">
                                        {blockData.title}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                {showText ? (
                                    <ExpandMoreIcon />
                                ) : (
                                    <KeyboardArrowRightIcon />
                                )}
                            </Box>
                        </Box>
                        <CollapsedText showText={showText}>
                            <Typography variant="body2" height={"100%"}>
                                {blockData.text}
                            </Typography>
                        </CollapsedText>
                    </Box>
                </Paper>
            </Box>
        </div>
    );
};

export default NewFeaturesBlock;
