import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CreateWorkspaceModal from '../Workspace/CreateWorkspace';
import { MixpanelContext } from '../../contexts/MixpanelContext';

const PostSignupForm = () => {
    const [newWorkspaceModal, setNewWorkspaceModal] = useState(false);
    const navigate = useNavigate();
    const { sendAnalyticsEvent } = useContext(MixpanelContext);

    const activateModalNew = () => {
        setNewWorkspaceModal(true);
        sendAnalyticsEvent('CreateWorkspaceModal', {
            eventCategory: 'User Interaction',
            action: 'Open Create Workspace Modal',
        });
    };

    const deactivateModalNew = () => {
        setNewWorkspaceModal(false);
    };

    return (
        <Box
            id="signup-form"
            height={'100vh'}
            width={{ xs: '100%', md: '70%' }}
            padding={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            sx={{
                backgroundImage:
                    'url(https://consultantaiassetstorage.blob.core.windows.net/assets/images/auth-form-background.jpg)',
                backgroundPositionX: {
                    xs: '35%',
                    sm: '80%',
                    md: '70%',
                    lg: '90%',
                    xl: '120%',
                },
                backgroundPositionY: { xs: '60%', sm: '80%' },
            }}
        >
            <Button
                variant="outlined"
                onClick={() => navigate('/dashboard')}
                style={{ marginBottom: '25px', padding: '5px 25px', width: '400px' }}
            >
                Private Workspace
            </Button>
            <Button variant="outlined" onClick={activateModalNew} style={{width: '400px'}}>
            Create workspace for your Team <AddIcon />
            </Button>
            <CreateWorkspaceModal
                open={newWorkspaceModal}
                onClose={deactivateModalNew}
            />
        </Box>
    );
};

export default PostSignupForm;