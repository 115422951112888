import React from 'react';
import {Box} from "@mui/material";
// import ReactHtmlParser from 'react-html-parser';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import SyncAltIcon from "@mui/icons-material/SyncAlt";

import Typography from "@mui/material/Typography";
import MessageInfoBlock from "./MessageInfoBlock";
import {dataBlockType} from "../InfoBlockTypes";

type IconKeys = 'ManageAccountsIcon' | 'PlaylistAddCheckIcon' | 'CloudSyncOutlinedIcon' | 'SyncAltIcon';

// interface dataBlockType {
//     icon: IconKeys;
//     title: string;
//     main_text: string;
//     messages: Array<Object>;
// }

interface TextInfoBlockProps{
    dataBlock:dataBlockType
}

const icons: Record<IconKeys, React.ElementType> = {
    ManageAccountsIcon,
    PlaylistAddCheckIcon,
    CloudSyncOutlinedIcon,
    SyncAltIcon,
}
const TextInfoBlock: React.FC<TextInfoBlockProps> = ({dataBlock}) => {
    const MainIconComponent = icons[dataBlock.icon as IconKeys];
    // const MainIconComponent = icons[dataBlock.icon];
    return (
        <Box>
            <Box display={"flex"}>
                <Box>
                
                    <Box width={40} height={40} mt={1} style={{backgroundColor: "rgb(56, 116, 203)"}}
                        sx={{
                            display:"flex",
                            backgroundColor: "blue",
                            borderRadius: "999px",
                            justifyContent: "Center",
                            alignItems:"Center",
                        }}
                    >
                        <MainIconComponent style={{color: "white", fontSize:"30px"}}/>
                    </Box>
                </Box>
                <Box pl={2}>
                    <Typography variant="h3"
                        sx={{whiteSpace: 'pre-line',}}
                    >
                        <span className="gradient-text"><strong>{dataBlock.title}</strong></span>
                    </Typography>
                    <Typography pt={2} variant="h5"
                        sx={{whiteSpace: 'pre-line',}}
                    >
                        {dataBlock.main_text}
                    </Typography>
                </Box>
            </Box>
            <Box>
                {dataBlock.messages.map ( (message) => {
                    const iconVar = icons[message.m_icon as IconKeys];
                    return <MessageInfoBlock message={message} MIcon={iconVar}/>
                })}
            </Box>
        </Box>
    );
};

export default TextInfoBlock;