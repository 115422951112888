import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useContext,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/AxiosInstance";
import ChatHistoryTable from "./ChatHistoryTable";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Modal,
    Paper,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import { Close, ExpandMore, InfoOutlined } from "@mui/icons-material";
import { MixpanelContext } from "../../contexts/MixpanelContext";

import { IChatHistoryRecord } from "../../models/IChatHistoryRecord";
import { IGroupedChatHistory } from "../../models/IGroupedChatHistory";
import { ITicket } from "../../models/ITicket";
import { AxiosError } from "axios";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { alignProperty } from "@mui/material/styles/cssUtils";

const ChatHistory = ({
    chatHistoryData,
    setChatHistoryData,
    isHistoryLoading,
    setIsHistoryLoading,
}: {
    chatHistoryData?: IGroupedChatHistory | null;
    setChatHistoryData?: Dispatch<SetStateAction<IGroupedChatHistory | null>>;
    isHistoryLoading?: boolean;
    setIsHistoryLoading?: Dispatch<SetStateAction<boolean>>;
}) => {
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [expanded, setExpanded] = useState<string | false>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [chatDetails, setChatDetails] = useState<IChatHistoryRecord>();
    const [ticketDetails, setTicketDetails] = useState<ITicket>();
    const [showOrgHistory, setshowOrgHistory] = useState<boolean>(true);
    const { sendAnalyticsEvent } = useContext(MixpanelContext);
    const [privateWorkspace, setPrivateWorkspace] = useState<boolean>(false)

    const navigate = useNavigate();

    const getChatHistory = useCallback(async () => {
        setShowAlert(false);
        setAlertMessage("");
        let url, res;
        try {
            url = `/store/chat-history`;
            let chatHistoryRaw, chatHistory
            if (localStorage.getItem('chatHistory')){
                chatHistoryRaw = localStorage.getItem('chatHistory') || '' //typescript throws an error if variable has a possible value of null so I add the optional value of empty string
                chatHistory = JSON.parse(chatHistoryRaw)
            }
            else{
                res = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                });
                chatHistory = await res.data.chatHistory;
                const temp = await res.data.private
                setPrivateWorkspace(temp) 
                localStorage.setItem("chatHistory", JSON.stringify(res.data.chatHistory))
            }
            let groupedChatHistory: IGroupedChatHistory = {};

            if (chatHistory.length) {
                chatHistory.forEach((record: IChatHistoryRecord) => {
                    if (
                        !Object.keys(groupedChatHistory).includes(
                            record.process_type
                        )
                    ) {
                        groupedChatHistory[record.process_type] = [];
                    }
                    groupedChatHistory[record.process_type] = [
                        ...groupedChatHistory[record.process_type],
                        record,
                    ];
                });
            }

            if (Object.keys(groupedChatHistory).length)
                setChatHistoryData!(groupedChatHistory);
            else{
                setChatHistoryData!({})
            }
        } catch (err) {
            console.error(err);
            if (err instanceof AxiosError && err.response) {
                setAlertMessage(err.response.data);
            } else {
                setAlertMessage(
                    "Sorry, there was a problem retrieving your history. Please try again later."
                );
            }
            setShowAlert(true);
        } finally {
            setIsHistoryLoading!(false);
        }
    }, [setChatHistoryData, setIsHistoryLoading]);

    const getChatHistoryWorkspace = useCallback(async () => {
        setShowAlert(false);
        setAlertMessage("");
        let url, res;
        try {

            let chatHistoryRaw, chatHistory;
            if (localStorage.getItem('workspaceChatHistory')) {
                chatHistoryRaw = localStorage.getItem('workspaceChatHistory') || '' //typescript throws an error if variable has a possible value of null so I add the optional value of empty string
                chatHistory = JSON.parse(chatHistoryRaw)
            }
            else {
                url = '/store/workspace-chat-history'
                res = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                });
                chatHistory = await res.data.chatHistory;
                const temp = await res.data.private
                setPrivateWorkspace(temp)              
                localStorage.setItem("workspaceChatHistory", JSON.stringify(res.data.chatHistory))
            }
            let groupedChatHistory: IGroupedChatHistory = {};

            if (chatHistory.length) {
                chatHistory.forEach((record: IChatHistoryRecord) => {
                    if (
                        !Object.keys(groupedChatHistory).includes(
                            record.process_type
                        )
                    ) {
                        groupedChatHistory[record.process_type] = [];
                    }
                    groupedChatHistory[record.process_type] = [
                        ...groupedChatHistory[record.process_type],
                        record,
                    ];
                });
            }

            if (Object.keys(groupedChatHistory).length)
                setChatHistoryData!(groupedChatHistory);
            else{
                setChatHistoryData!({})
            }
        } catch (err) {
            console.error(err);
            if (err instanceof AxiosError && err.response) {
                setAlertMessage(err.response.data);
            } else {
                setAlertMessage(
                    "Sorry, there was a problem retrieving your history. Please try again later."
                );
            }
            setShowAlert(true);
        } finally {
            setIsHistoryLoading!(false);
        }
    }, [setChatHistoryData, setIsHistoryLoading]);

    useEffect(() => {
        if (showOrgHistory == true) {
            getChatHistoryWorkspace()
            console.log(privateWorkspace)
        }
        else {
            getChatHistory()
        }
    }, [showOrgHistory, privateWorkspace]);

    console.log(chatHistoryData)

    return (
        <>
            <Stack
                width={{ xs: "100%", md: "40%" }}
                paddingX={{ xs: 2, md: 4 }}
                paddingTop={{ xs: 1, md: 3 }}
                borderLeft={{ md: "2px solid #aaa" }}
            >
                <Typography variant="h4" color="primary">
                    History
                </Typography>
                <Tabs
                    value={showOrgHistory ? "organisation" : "my"}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    onChange={() => {
                        setshowOrgHistory((prevState) => !prevState);
                    }}
                >
                    {!privateWorkspace && (<Tab label="Organization History" value="organisation" />)}
                    <Tab label="My History" value="my" />
                </Tabs>

                <Stack
                    height="100%"
                    justifyContent={isHistoryLoading ? "center" : "flex-start"}
                    alignItems="center"
                >
                    { isHistoryLoading ? (
                        <CircularProgress color="primary" size={100} />
                    ) : chatHistoryData ? (
                        <Box width="100%" marginTop={2}>
                            {Object.keys(chatHistoryData).map((processType) => {
                                return (
                                    <Accordion
                                        expanded={expanded === processType}
                                        onChange={() =>
                                            setExpanded(
                                                expanded === processType
                                                    ? false
                                                    : processType
                                            )
                                        }
                                        key={processType}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                        >
                                            <Typography variant="body1">
                                                {processType}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ChatHistoryTable
                                                data={
                                                    chatHistoryData[processType]
                                                }
                                                renderedWithin={ChatHistory}
                                                allowSelectMultiple={false}
                                                handlePopulateModal={(chat) => {
                                                    setChatDetails(chat);
                                                    setTicketDetails(
                                                        chat!.process_ticket
                                                    );
                                                    setShowModal(true);
                                                }}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </Box>
                    ) : (
                        <Stack
                            direction="row"
                            marginTop={3}
                            alignItems="center"
                        >
                            <InfoOutlined sx={{ marginRight: 1 }} />
                            <Typography variant="body1">
                                Your history is currently empty
                            </Typography>
                        </Stack>
                    )}
                    {showAlert && (
                        <Alert severity="error" sx={{ marginTop: 3 }}>
                            {alertMessage}
                        </Alert>
                    )}
                </Stack>
            </Stack>
            {chatDetails && (
                <Modal
                    open={showModal}
                    onClose={() => setShowModal(false)}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: { xs: "flex-start", md: "center" },
                    }}
                >
                    <Paper
                        sx={{
                            maxHeight: "96%",
                            width: { xs: "95%", md: "80%" },
                            marginY: 2,
                            paddingX: 2,
                            paddingY: 1,
                            overflowY: { xs: "auto", md: "hidden" },
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            marginBottom={2}
                        >
                            <Stack
                                direction={{ md: "row" }}
                                alignItems={{ xs: "flex-start", md: "center" }}
                            >
                                <Typography
                                    variant="h5"
                                    color="primary"
                                    fontWeight="bold"
                                    marginRight={2}
                                >
                                    {chatDetails!.process_type}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    marginRight={2}
                                >
                                    {ticketDetails!.ticket_title}
                                </Typography>
                                <Typography variant="body1" marginTop="2px">
                                    {`Saved at ${new Date(
                                        chatDetails.created_at
                                    ).toLocaleString([], {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    })}`}
                                </Typography>
                                {chatDetails.process_type ===
                                    "Plan a User Story" && (
                                    <Button
                                        onClick={() => {
                                          sendAnalyticsEvent(
                                            'NavigateToTasksForUserStory',
                                            {
                                              eventCategory: 'User Interaction',
                                              action:
                                                'Navigate to Break this User Story into Tasks Process',
                                            }
                                          )
                                            navigate(
                                                `/chat/break-user-story-into-tasks?parentProcessIds=${chatDetails.id}`
                                            )
                                        }
                                        }
                                        variant="outlined"
                                        sx={{
                                            textTransform: "none",
                                            marginLeft: { md: 2 },
                                        }}
                                    >
                                        Break this User Story into Tasks
                                    </Button>
                                )}
                            </Stack>
                            <IconButton
                                onClick={() => {
                                    setShowModal(false);
                                    sendAnalyticsEvent('ChatModalClosed', {
                                      eventCategory: 'User Interaction',
                                      action: 'Close Chat Modal',
                                    })

                                }}
                            >
                                <Close />
                            </IconButton>
                        </Stack>
                        <Stack>
                            <Typography variant="h6" color="secondary">
                                Ticket
                            </Typography>
                            <Box
                                overflow="auto"
                                maxHeight={{
                                    md: "calc(100vh - 200px)",
                                }}
                            >
                                <ReactMarkdown>
                                    {ticketDetails!.ticket_body}
                                </ReactMarkdown>
                            </Box>
                        </Stack>
                    </Paper>
                </Modal>
            )}
        </>
    );
};

export default ChatHistory;
