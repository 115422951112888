import React from 'react';
import {Box, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import UserPost from "./UserPost/UserPost";
import posts from "../../contentData/LoveByDevelopers/loveByDevelopersPosts.json";
import MyFadeInAnimation from "../../elements/MyFadeInAnimation";

const LovedByDevelopers = () => {
    return (
        <Box
            sx = {{
                width: "100%",
            }}
        >
            <Typography  variant="h3"
                        sx = {{
                            letterSpacing:"-0.025em",
                            width:"100%",
                            textAlign: "center",
                        }}
            >
                <span className="gradient-text"><strong>Trusted by leaders.</strong></span>  
            </Typography>

            <Grid container
                  spacing={4}
                  mt={3}
                  style={{ display: 'flex' }}
            >
                {posts.posts.map ( (post,i) => {
                    return(
                        <Grid item xs={12} sm={6} md={4} style={{ display: 'flex' }}>
                            <MyFadeInAnimation duration={1000} rootMargin={-50}>
                                <UserPost post={post}/>
                            </MyFadeInAnimation>
                        </Grid>
                    )
                })}
           </Grid>
        </Box>
    );
};

export default LovedByDevelopers;