import { useEffect, useContext } from "react";
import Header from "../components/global/Header";
import Hero from "../components/Home/Hero";
import { AuthContext } from "../contexts/AuthContext";
import { IAuthContext } from "../models/IAuthContext";

const Home = () => {
    const authContext = useContext(AuthContext);
    const { isLoggedOut } = authContext as IAuthContext;

    useEffect(() => {
        document.title = isLoggedOut
            ? "Logged Out | Consultant.AI"
            : "Consultant.AI";
    }, [isLoggedOut]);

    return (
        <div id="home" className="page">
            <Header color='rgb(240, 243, 250)' />
            <Hero />
        </div>
    );
};

export default Home;