import React, {useContext} from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { MixpanelContext } from "../../../contexts/MixpanelContext";

interface MyButtonProps {
    children: React.ReactNode | string;
    slideSpeed: number;
    buttonColor?: string | boolean;
}

const MyButton: React.FC<MyButtonProps> = ({
    children,
    buttonColor = false,
}) => {
    const navigate = useNavigate();
    const {sendAnalyticsEvent} = useContext(MixpanelContext);

    return (
        <>
            <Button
                onClick={() => {
                    sendAnalyticsEvent('NavigateToSignup', {
                      eventCategory: 'User Interaction',
                      action: 'Navigate to Signup',
                    })
                    navigate("/signup")
                }}
                focusRipple
                style={{
                    fontSize: "1em",
                    backgroundColor: `${buttonColor ? buttonColor : "red"}`,
                    overflow: "hidden",
                }}
            >
                {children}
            </Button>
        </>
    );
};

export default MyButton;
