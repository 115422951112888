import React from 'react';
import {Grid} from "@mui/material";
import GifInfoBlock from "./GifInfoBlock/GifInfoBlock";
import TextInfoBlock from "./TextInfoBlock/TextInfoBlock";
import {dataBlockType} from "./InfoBlockTypes";
import MyFadeInAnimation from "../../elements/MyFadeInAnimation";
import {styled} from "@mui/material/styles";

interface InfoBlockProps {
    textBlockOnLeft:boolean;
    TextInfoBlock: React.FC<{dataBlock:dataBlockType}>;
    dataBlock: dataBlockType;
    GifInfoBlock: React.FC<{ gifImage: string }>;
    gifImage:string;
}

const ItemGrigGoLast = styled(Grid)(({ theme }) => ({
    order: 0,
    // Меняем порядок на некоторых breakpoints
    [theme.breakpoints.down("md")]: {
        order: 5,
    },
}));

const InfoBlock: React.FC<InfoBlockProps> = ({textBlockOnLeft, TextInfoBlock, dataBlock,
                                                 GifInfoBlock, gifImage}) => {
    return (
        <Grid container spacing={4}>
            {
                textBlockOnLeft ?
                    <>
                        <Grid item  xs={12} md={6}>
                            <TextInfoBlock dataBlock={dataBlock}/>
                        </Grid>
                        <Grid item  xs={12} md={6}>
                            <MyFadeInAnimation duration={1000} rootMargin={-150}>
                                <GifInfoBlock gifImage={gifImage}/>
                            </MyFadeInAnimation>
                        </Grid>
                    </>
                :
                    <>
                        <ItemGrigGoLast item xs={12} md={6}>
                            <MyFadeInAnimation duration={1000} rootMargin={-150}>
                                <GifInfoBlock gifImage={gifImage}/>
                            </MyFadeInAnimation>
                        </ItemGrigGoLast>
                        <Grid item xs={12} md={6}>
                            <TextInfoBlock dataBlock={dataBlock}/>
                        </Grid>
                    </>
            }
       </Grid>
     );
};

export default InfoBlock;

// "https://uploads-ssl.webflow.com/6290be8d112ee934eeb6aaf2/6290be8e112ee94308b6ad02_Rectangle-8.jpg"