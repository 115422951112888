function flattenTree(tree) {
    let result = [];

    for (let node of tree) {
        result.push(node);

        if (node.children) {
            result = result.concat(flattenTree(node.children));
        }
    }

    return result;
}

export const findNextStep = (tree, stepName, skip) => {
    const flatTree = flattenTree(tree);

    for (let i = 0; i < flatTree.length; i++) {
        if (flatTree[i].name === stepName) {
            // Check if there's a next step
            if (i < flatTree.length - 1) {
                return skip ? flatTree[i + 2] : flatTree[i + 1];
            } else {
                return null;
            }
        }
    }

    return null; // If stepName is not found in the tree
};
