import { useEffect, useState } from "react";

export const useWindowWidthGreaterThan = (width: number): boolean => {
    const [isWidthGreaterThan, setIsWidthGreaterThan] =
        useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            setIsWidthGreaterThan(window.innerWidth > width);
        };
        // Initial check
        handleResize();
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Clean up event listener on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [width]);

    return isWidthGreaterThan;
};
