import { useCallback, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/AxiosInstance";
import ChatHistoryTable from "../Chat/ChatHistoryTable";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DropZone from "../global/DropZone";
import {
    Alert,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    CircularProgress,
    Grid,
    IconButton,
    Modal,
    Paper,
    Stack,
    Tooltip,
    Typography,
    // for Tabs switch
    Tab,
    Tabs
    // end for Tabs switch
} from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext";
import { Close, InfoOutlined } from "@mui/icons-material";
import "../../styles/components/DashboardMenu.css";
import { MixpanelContext } from "../../contexts/MixpanelContext";

import { ICustomProcessType } from "../../models/ICustomProcessType";
import { IProcessType } from "../../models/IProcessType";
import { IChatHistoryRecord } from "../../models/IChatHistoryRecord";
import { AxiosError } from "axios";

// Flowchart import
import DashboardFlowEditor from "../FlowEditor/DashboardFlowEditor";
// Hook to store Tab value
import useHistoryState from "../FlowEditor/useHistoryState";

const baseURL = "https://consultantaiassetstorage.blob.core.windows.net/assets/images";

// Tabs functions start
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

// Tabs function end

const DashboardMenu = () => {
    const processTypes: IProcessType[] = [
        {
            name: "userStory",
            label: "Plan a User Story",
            urlSlug: "plan-a-user-story",
            image: `${baseURL}/user-story-card-photo.jpg`,
            description:
                "Confidently create a new feature for your users, step by step.",
        },
        {
            name: "breakUserStoryIntoTasks",
            label: "Break a User Story into Tasks",
            pickerTitle: "Select a User Story to Break into Tasks",
            urlSlug: "break-user-story-into-tasks",
            image: `${baseURL}/break-user-story-into-tasks-card-photo.jpg`,
            description:
                "Easily identify actionable steps towards implementing a new feature.",
        },
        {
            name: "task",
            label: "Plan a Task",
            urlSlug: "plan-a-task",
            image: `${baseURL}/plan-task-card-photo.jpg`,
            description:
                "Quickly outline and delegate tasks, from small to complex.",
        },
        {
            name: "dailyProgressReport",
            label: "Create a Daily Progress Report",
            pickerTitle:
                "Select previous item(s) to provide context for your daily progress report (optional)",
            urlSlug: "daily-progress-report",
            image: `${baseURL}/daily-progress-report-card-photo.jpg`,
            description:
                "Efficiently summarize and reflect on what's been accomplished so far. Identify goals and next steps.",
        },
        {
            name: "generalChat",
            label: "General Chat",
            urlSlug: "general-chat",
            image: "/testimages/genchat.png",
            description:
                "Connect with our AI-powered chatbot, ready to assist you 24/7.",
        },
    ];

    useEffect(() => {
        if (isLoggedIn){
            setIsLoadingProcesses(true);
            const fetchProcesses = async () => {
                try {
                    const response = await axios.get("/store/get-processes", {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                        },
                    });

                    setProcesses(response.data);
                    setIsLoadingProcesses(false);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchProcesses();
        }
    }, []);

    const [processes, setProcesses] = useState({});
    const authContext = useContext(AuthContext);
    const { isLoggedIn, activeUser } = authContext!;
    const [showPickerModal, setShowPickerModal] = useState<boolean>(false);
    const [processTypeForPicker, setProcessTypeForPicker] =
        useState<IProcessType>();
    const [pickerTitle, setPickerTitle] = useState<string>();
    const [selectedChatIds, setSelectedChatIds] = useState<string[]>([]);
    const [chatHistoryData, setChatHistoryData] =
        useState<IChatHistoryRecord[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingProcesses, setIsLoadingProcesses] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [showOrgHistory, setshowOrgHistory] = useState<boolean>(true);
    const [showImageModal, setShowImageModal] = useState<boolean>(false);
    const [imageEditingProcessId, setImageEditingProcessId] = useState<string>("");
    const { sendAnalyticsEvent } = useContext(MixpanelContext);

    const navigate = useNavigate();

    const fetchChatHistory = useCallback(async (processType: IProcessType) => {
        setShowAlert(false);
        setIsLoading(true);
        setShowPickerModal(true);
        try {
            let chatHistory: IChatHistoryRecord[] = [];
            if (processType.name === "breakUserStoryIntoTasks") {
                const url = `/store/user-story-history`;
                const res = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                });
                chatHistory = await res.data.userStoryHistory;
            }
            if (processType.name === "dailyProgressReport") {
                const url = `/store/chat-history`;
                const res = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                });
                chatHistory = await res.data.chatHistory;
            }
            setChatHistoryData(chatHistory);
        } catch (err) {
            console.error(err);
            setAlertMessage(
                err instanceof AxiosError
                    ? err.response!.data
                    : `Sorry, there was a problem retrieving your history for ${processType.label}. Are you offline?`
            );
            setShowAlert(true);
        } finally {
            setIsLoading(false);
        }
    }, []);

    // Flowchart start
    // Start process from Flow => Node click
    const startProcessFromFlow = (processName: string) => {
        // get process => processType by processName from processTypes
        const processType = processTypes.find(processType => processType.name === processName);
        //start onClick function
        if (!processType) {
            console.error("There is no process for process type => ", processName ); 
            return
        }
        if (
            [
                "breakUserStoryIntoTasks",
                "dailyProgressReport",
            ].includes(processType.name)
        ) {
            setProcessTypeForPicker(processType);
            setPickerTitle(processType.pickerTitle);
            fetchChatHistory(processType);
        } else {
            navigate(`/chat/${processType.urlSlug}`);
        }
    };
    // Flowchart end

    // Tabs number with custom hook
    const [value, setValue] = useHistoryState("dashboardMenuTabNumber", 0);

    const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    };
    // Tabs end

    const handleImageUpload = async (e: any, processId: any) => {
        e.stopPropagation()

        setShowImageModal(true);
        setImageEditingProcessId(processId)
    };

    return (
        <>
        {/* Tabs start */}
            <Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChangeTabs} aria-label="basic tabs example">
                        <Tab label="Processes" {...a11yProps(0)} />
                        <Tab label="Workflow" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    {/* Dashboard Cards Tab start */}
                    <Stack
                        id="dashboard-menu"
                        width="100%"
                        minHeight="400px"
                        marginTop={3}
                        display="grid"
                        gridTemplateColumns={
                            "repeat(2, minmax(0, 400px))"
                        }
                        gap={2}
                        sx={{
                            justifyContent: 'center',
                            alignContent: 'center',
                        }}
                    >
                        {processTypes.map((processType) => (
                            <Card
                                onClick={() => {
                                    if (
                                        [
                                            "breakUserStoryIntoTasks",
                                            "dailyProgressReport",
                                        ].includes(processType.name)
                                    ) {
                                        setProcessTypeForPicker(processType);
                                        setPickerTitle(processType.pickerTitle);
                                        fetchChatHistory(processType);
                                    } else {
                                        navigate(
                                            `/chat/${processType.urlSlug}`,
                                            {state :
                                                {processType : processType}
                                            }
                                        );
                                    }
                                }}
                                sx={{
                                    height: "250px",
                                    marginX: { xs: 1, md: 1 },
                                    cursor: "pointer",
                                    width: "100%",
                                    maxWidth: "400px",
                                }}
                                key={processType.urlSlug}
                            >
                                <CardMedia
                                    image={processType.image}
                                    sx={{ height: "50%" }}
                                />
                                <Tooltip title={processType.description} arrow>
                                    <CardContent>
                                        <Typography
                                            fontSize="1.5em"
                                            color="primary"
                                            marginBottom={1}
                                            sx={{
                                                textTransform: "none",
                                                textAlign: "left",
                                            }}
                                        >
                                            {processType.label}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                            sx={{
                                                textTransform: "none",
                                                textAlign: "left",
                                                display: "-webkit-box",
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: "vertical",
                                            }}
                                        >
                                            {processType.description}
                                        </Typography>
                                    </CardContent>
                                </Tooltip>
                            </Card>
                        ))}
                    </Stack>
                    <Stack
                        id="dashboard-accordions"
                        width="100%"
                        marginTop={3}
                        marginBottom={3}
                        display="grid"
                        gridTemplateColumns={
                            isLoadingProcesses ? "" : 
                            "repeat(1, minmax(0, 800px))"
                        }
                        gap={2}
                        sx={{
                            justifyContent: 'center',
                            alignContent: 'center',
                        }}
                    >{isLoadingProcesses ? 
                        <CircularProgress color="primary" size={50} />
                     : <div>
                            {Object.entries(processes).map(([name, processesArray]: [string, any]) => {
                                name = name.replace(/[0-9]/g, '')

                                return (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        disabled={processesArray?.length < 1 && name == "verified"}
                                    >
                                        <Typography
                                            sx={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {name === "verified" || name === "unverified" ?
                                                `Public Processes - ${name}` :
                                                `${name} Processes `
                                            }
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}>
                                        <Stack
                                            width="100%"
                                            display="grid"
                                            gridTemplateColumns={
                                                "repeat(2, minmax(0, 400px))"
                                            }
                                            gap={2}
                                            sx={{
                                                justifyContent: 'center',
                                                alignContent: 'center',
                                            }}
                                        >
                                            {processesArray?.map((process: ICustomProcessType) => (
                                                <Card
                                                    onClick={() => {
                                                        navigate(
                                                            `/chat/${process.urlSlug}`,
                                                            {state:
                                                                {processType: process}
                                                            }
                                                        );
                                                    }}
                                                    sx={{
                                                        height: "250px",
                                                        cursor: "pointer",
                                                        width: "100%",
                                                        maxWidth: "400px",
                                                    }}
                                                    key={process.id}
                                                >
                                                    <CardMedia
                                                        image= {baseURL + '/' + process.image}
                                                        sx={{ 
                                                            alignItems: "flex-start",
                                                            display: "flex",
                                                            height: "50%",
                                                            justifyContent: "flex-end",
                                                        }}
                                                    >
                                                        {process.owned && (
                                                            <IconButton
                                                                sx={{ 
                                                                    marginTop: ".2em",
                                                                    marginRight: ".2em",
                                                                    color: "transparent",
                                                                    "&:hover": {
                                                                        color: "#000000",
                                                                    }
                                                                    }}
                                                                onClick={(e) => {
                                                                    handleImageUpload(e, process.id);
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        )}
                                                    </CardMedia>
                                                    <Tooltip title={process.description} arrow>
                                                        <CardContent>
                                                            <Typography
                                                                fontSize="1.5em"
                                                                color="primary"
                                                                marginBottom={1}
                                                                sx={{
                                                                    textTransform: "none",
                                                                    textAlign: "left",
                                                                }}
                                                            >
                                                                {process.label}
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                overflow="hidden"
                                                                textOverflow="ellipsis"
                                                                sx={{
                                                                    textTransform: "none",
                                                                    textAlign: "left",
                                                                    display: "-webkit-box",
                                                                    WebkitLineClamp: 2,
                                                                    WebkitBoxOrient: "vertical",
                                                                }}
                                                            >
                                                                {process.description}
                                                            </Typography>
                                                        </CardContent>
                                                    </Tooltip>
                                                </Card>
                                            ))}
                                        </Stack>
                                        {name !== "verified" && (
                                            <Button
                                                id="basic-button"
                                                aria-haspopup="true"
                                                onClick={() => {navigate(
                                                        `/chat/new-process`
                                                    );
                                                }}
                                                variant="contained"
                                                size="small"
                                                sx={{ 
                                                    my: 1,
                                                    marginTop: 3,
                                                    py: 1,

                                                }}
                                            >
                                                New Process
                                            </Button>
                                        )}
                                    </AccordionDetails>
                                </Accordion>)
                            })}
                        </div>}
                    </Stack>
                </CustomTabPanel>
        {/* Dashboard cards tab end  */}
                <CustomTabPanel value={value} index={1}>
                   
        {/* Flow editor start */}
                    <DashboardFlowEditor startProcessFromFlow={startProcessFromFlow}/>
        {/* Flow editor end */}
                  
                </CustomTabPanel>
            </Box>
        {/* Tabs end */}

        {/* History start */}
            <Modal
                open={showPickerModal}
                onClose={() => setShowPickerModal(false)}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: { xs: "flex-start", md: "center" },
                }}
            >
                <Paper
                    sx={{
                        maxHeight: "96%",
                        width: { xs: "95%", md: "40%" },
                        marginY: 2,
                        paddingX: 2,
                        paddingY: 1,
                        overflowY: "auto",
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding={1}
                    >
                        <Typography
                            variant="h5"
                            color="primary"
                            fontWeight="bold"
                            marginBottom={2}
                        >
                            {pickerTitle}
                        </Typography>
                        <IconButton
                            sx={{ marginBottom: "1.5em" }}
                            onClick={() => {
                                sendAnalyticsEvent('DashboardModalClosed', {
                                  eventCategory: 'User Interaction',
                                  action: 'Close Dashboard Modal',
                                })
                                setShowPickerModal(false);
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Stack>
                    <Stack
                        minHeight="200px"
                        justifyContent={isLoading ? "center" : "flex-start"}
                        alignItems={
                            isLoading || !chatHistoryData?.length
                                ? "center"
                                : "flex-start"
                        }
                    >
                        {isLoading ? (
                            <CircularProgress color="primary" size={50} />
                        ) : chatHistoryData ? (
                            <Stack width="100%">
                                {chatHistoryData.length ? (
                                    <Stack>
                                        <ChatHistoryTable
                                            data={chatHistoryData}
                                            selectedChatIds={selectedChatIds}
                                            setSelectedChatIds={
                                                setSelectedChatIds
                                            }
                                            renderedWithin={DashboardMenu}
                                            allowSelectMultiple={
                                                [
                                                    "dailyProgressReport",
                                                ].includes(
                                                    processTypeForPicker!.name
                                                )
                                                    ? true
                                                    : false
                                            }
                                            handleRedirectToNewProcess={(
                                                parentProcessId
                                            ) =>
                                                navigate(
                                                    `/chat/break-user-story-into-tasks?parentProcessIds=${parentProcessId}`
                                                )
                                            }
                                        />
                                    </Stack>
                                ) : (
                                    <Stack
                                        direction="row"
                                        marginTop={3}
                                        justifyContent="center"
                                    >
                                        <InfoOutlined sx={{ marginRight: 1 }} />
                                        <Typography variant="body1">
                                            {processTypeForPicker?.name ===
                                            "breakUserStoryIntoTasks"
                                                ? "You currently have no saved user story tickets to break into tasks"
                                                : "You currently have no saved tickets to provide context. Please continue."}
                                        </Typography>
                                    </Stack>
                                )}
                                {["dailyProgressReport"].includes(
                                    processTypeForPicker!.name
                                ) && (
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        width="100%"
                                        paddingTop={4}
                                    >
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                let processIdsUrlSlug = "";
                                                selectedChatIds.forEach(
                                                    (id, idx) =>
                                                        (processIdsUrlSlug +=
                                                            idx ===
                                                            selectedChatIds.length -
                                                                1
                                                                ? `${id}`
                                                                : `${id}+`)
                                                );
                                                sendAnalyticsEvent(
                                                  'NavigateToDailyProgress',
                                                  {
                                                    eventCategory:
                                                      'User Interaction',
                                                    action:
                                                      'Navigate to Daily Progress Report Creation',
                                                  }
                                                )
                                                navigate(
                                                    processIdsUrlSlug
                                                        ? `/chat/daily-progress-report?parentProcessIds=${processIdsUrlSlug}`
                                                        : "/chat/daily-progress-report"
                                                );
                                            }}
                                            sx={{ textTransform: "none" }}
                                        >
                                            {processTypeForPicker?.label}
                                        </Button>
                                    </Stack>
                                )}
                            </Stack>
                        ) : (
                            <Stack
                                direction="row"
                                marginTop={3}
                                alignItems="center"
                            >
                                <InfoOutlined sx={{ marginRight: 1 }} />
                                <Typography variant="body1">
                                    Your history is currently empty
                                </Typography>
                            </Stack>
                        )}
                        {showAlert && (
                            <Alert severity="error" sx={{ marginTop: 3 }}>
                                {alertMessage}
                            </Alert>
                        )}
                    </Stack>
                </Paper>
            </Modal>
            {/* History end */}

            {/* Image Upload Modal start */}
            <Modal
                open={showImageModal}
                onClose={() => setShowImageModal(false)}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: { xs: "flex-start", md: "center" },
                }}
            >
                <Paper
                    sx={{
                        maxHeight: "96%",
                        width: { xs: "95%", md: "40%" },
                        marginY: 2,
                        paddingX: 1,
                        paddingY: 1,
                        overflowY: "auto",
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding={1}
                    >
                        <Typography
                            variant="h5"
                            color="primary"
                            fontWeight="bold"
                            marginBottom={2}
                        >
                            Upload Image
                        </Typography>
                        <IconButton
                            sx={{ marginBottom: "1.5em" }}
                            onClick={() => {
                                setShowImageModal(false);
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Stack>
                    <Stack
                        minHeight="200px"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <DropZone setShowImageModal={ setShowImageModal } processId={ imageEditingProcessId } />
                        {showAlert && (
                            <Alert severity="error" sx={{ marginTop: 3 }}>
                                {alertMessage}
                            </Alert>
                        )}
                    </Stack>
                </Paper>
            </Modal>
            {/* Image Upload Modal end */}
        </>
    );
};

export default DashboardMenu;
