import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";

const TermsContent = () => {
    return (
        <Stack
            width={{ xs: "95%", md: "50%" }}
            marginX="auto"
            marginTop={3}
            marginBottom={2}
        >
            <Typography variant="h3" marginBottom={3} color="primary">
                Consultant.ai Terms of Service
            </Typography>
            <section>
                <Typography variant="h5" color="primary">
                    1. Acceptance of Terms
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    By using the Consultant.ai website, you agree to be bound by
                    these Terms of Service, all applicable laws and regulations,
                    and agree that you are responsible for compliance with any
                    applicable local laws. If you do not agree with any of these
                    terms, you are prohibited from using or accessing this site.
                </Typography>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    2. Registration and User Information
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    To use certain features of Consultant.ai, you may be
                    required to create an account and provide accurate and
                    complete information. You are responsible for maintaining
                    the confidentiality of your account and password, and for
                    any activities that occur under your account.
                </Typography>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    3. User Conduct
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    As a user of Consultant.ai, you agree to not use the service
                    for any unlawful purpose or in a way that violates these
                    Terms of Service. You also agree to not use the service to
                    harass, threaten, or defame others. Consultant.ai reserves
                    the right to terminate or suspend your access to the service
                    for any violation of these terms.
                </Typography>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    4. Intellectual Property Rights
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    All content on the Consultant.ai website, including text,
                    graphics, logos, and software, is the property of
                    Consultant.ai or its content suppliers and is protected by
                    international copyright laws. You agree not to reproduce,
                    duplicate, copy, sell, resell or exploit any portion of the
                    service without express written permission from
                    Consultant.ai.
                </Typography>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    5. Limitations
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    In no event shall Consultant.ai or its suppliers be liable
                    for any damages (including, without limitation, damages for
                    loss of data or profit, or due to business interruption)
                    arising out of the use or inability to use the Consultant.ai
                    website, even if Consultant.ai or a Consultant.ai authorized
                    representative has been notified orally or in writing of the
                    possibility of such damage.
                </Typography>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    6. Revisions and Errata
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    The content on the Consultant.ai website may include
                    technical, typographical, or photographic errors.
                    Consultant.ai does not warrant that any of the materials on
                    its website are accurate, complete, or current.
                    Consultant.ai may make changes to the materials contained on
                    its website at any time without notice. Consultant.ai does
                    not, however, make any commitment to update the materials.
                </Typography>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    7. Modifications to Terms of Service
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    Consultant.ai may revise these Terms of Service at any time
                    without notice. By using this website, you agree to be bound
                    by the then-current version of these Terms of Service.
                </Typography>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    8. Governing Law
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    Any claim relating to the Consultant.ai website shall be
                    governed by the laws of the country in which Consultant.ai
                    operates, without regard to its conflict of law provisions.
                </Typography>
            </section>
            <section>
                <Typography variant="h5" color="primary">
                    9. Third-Party Services and Links
                </Typography>
                <Typography variant="body1" marginLeft={3.5} marginY={2}>
                    Consultant.ai may provide links to third-party websites or
                    services that are not owned or controlled by Consultant.ai.
                    Consultant.ai has no control over, and assumes no
                    responsibility for, the content, privacy policies, or
                    practices of any third-party websites or services. By using
                    the Consultant.ai website, you acknowledge and agree that
                    Consultant.ai shall not be responsible or liable, directly
                    or indirectly, for any damage or loss caused or alleged to
                    be caused by or in connection with the use of or reliance on
                    any such content, goods or services available on or through
                    any such third-party websites or services.
                </Typography>
            </section>
            <section>
                <Typography variant="h5" color="primary">
                    10. Indemnification
                </Typography>
                <Typography variant="body1" marginLeft={4.5} marginY={2}>
                    You agree to indemnify, defend, and hold harmless
                    Consultant.ai, its officers, directors, employees, agents,
                    licensors, and suppliers from and against all losses,
                    expenses, damages, and costs, including reasonable
                    attorneys' fees, resulting from any violation of these Terms
                    of Service or any activity related to your account
                    (including negligent or wrongful conduct) by you or any
                    other person accessing the Consultant.ai website using your
                    account.
                </Typography>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    11. Disclaimer of Warranties
                </Typography>
                <Typography variant="body1" marginLeft={4.5} marginY={2}>
                    The Consultant.ai website and its services are provided on
                    an "as is" and "as available" basis. Consultant.ai makes no
                    representations or warranties of any kind, express or
                    implied, as to the operation of the website, the
                    information, content, materials, or products included on the
                    website. To the full extent permissible by applicable law,
                    Consultant.ai disclaims all warranties, express or implied,
                    including, but not limited to, implied warranties of
                    merchantability and fitness for a particular purpose.
                    Consultant.ai does not warrant that the website, its
                    servers, or email sent from Consultant.ai are free of
                    viruses or other harmful components. Consultant.ai will not
                    be liable for any damages of any kind arising from the use
                    of the website, including, but not limited to, direct,
                    indirect, incidental, punitive, and consequential damages.
                </Typography>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    12. Termination
                </Typography>
                <Typography variant="body1" marginLeft={4.5} marginY={2}>
                    Consultant.ai reserves the right, in its sole discretion, to
                    terminate your access to the website and the related
                    services or any portion thereof at any time, without notice.
                    Upon termination, you must cease all use of the website and
                    destroy any downloaded or printed materials obtained from
                    the website.
                </Typography>
            </section>

            <section>
                <Typography variant="h5" color="primary">
                    13. Contact Information
                </Typography>
                <Typography variant="body1" marginLeft={4.5} marginY={2}>
                    If you have any questions or concerns about these Terms of
                    Service, please contact us at [support@Consultant.ai.com] or
                    by using the <Link to="/contact">contact form</Link> on our
                    website.
                </Typography>
            </section>

            <footer>
                <Typography variant="body1" marginLeft={4.5} marginY={2}>
                    &copy; {new Date().getFullYear()} Consultant.ai. All rights
                    reserved.
                </Typography>
            </footer>
        </Stack>
    );
};

export default TermsContent;
