import { IProcessType } from "../../models/IProcessType";

const generateImageLinkMap = (param: string) => {
  return processTypes.reduce((acc: any, type: any) => {
    return { ...acc, [type.name] : type[param]};
  }, {}); 
}; 

export interface ProcessMapForFunctions {
  [key: string]: string | boolean;
}

export const processMapForFunctions: ProcessMapForFunctions = {
  "userStory" : "Plan a User Story",
  "breakUserStoryIntoTasks" : "Break a User Story into Tasks",
  "task" : "Plan a Task",
  "dailyProgressReport" : "Create a Daily Progress Report",
  "generalChat": "General Chat",
}

export interface ImageLinkType {
  [key: string]: string;
}

export const imageLinks: ImageLinkType = {
  userStory: "https://consultantaiassetstorage.blob.core.windows.net/assets/images/user-story-card-photo.jpg",
  breakUserStoryIntoTasks: "https://consultantaiassetstorage.blob.core.windows.net/assets/images/break-user-story-into-tasks-card-photo.jpg",
  task: "https://consultantaiassetstorage.blob.core.windows.net/assets/images/plan-task-card-photo.jpg",
  dailyProgressReport: "https://consultantaiassetstorage.blob.core.windows.net/assets/images/daily-progress-report-card-photo.jpg",
  generalChat: "/testimages/genchat.png"
}

export const backgroundImageLink: ImageLinkType = {
  Img1: "https://consultantaiassetstorage.blob.core.windows.net/assets/images/user-story-card-photo.jpg",
  Img2: "https://consultantaiassetstorage.blob.core.windows.net/assets/images/break-user-story-into-tasks-card-photo.jpg",
  Img3: "https://consultantaiassetstorage.blob.core.windows.net/assets/images/plan-task-card-photo.jpg",
  Img4: "https://consultantaiassetstorage.blob.core.windows.net/assets/images/daily-progress-report-card-photo.jpg",
  Img5: "/testimages/genchat.png"
}


export interface TextType {
  [key: string]: string;
}

export const headerMapForProcess: TextType = {
  userStory : "Plan a User Story",
  breakUserStoryIntoTasks : "Break a User Story into Tasks",
  task : "Plan a Task",
  dailyProgressReport : "Create a Daily Progress Report",
  generalChat: "General Chat",
  test : "Test process",
  "" : "Click to edit Header"
}
export const textMapForProcess: TextType = {
  userStory: "Confidently create a new feature for your users, step by step.",
  breakUserStoryIntoTasks: "Easily identify actionable steps towards implementing a new feature.",
  task: "Quickly outline and delegate tasks, from small to complex.",
  dailyProgressReport: "Efficiently summarize and reflect on what's been accomplished so far. Identify goals and next steps.",
  generalChat: "Connect with our AI-powered chatbot, ready to assist you 24/7.",
  test : "Test process text",
  "" : "Click to edit Text"
}

export const tooltipMapForProcess: TextType = {
  userStory: "New user feature step by step",
  breakUserStoryIntoTasks: "Select a User Story to Break into Tasks",
  task: "Efficient task delegation",
  dailyProgressReport: "Select previous item(s) to provide context for your daily progress report (optional)",
  generalChat: "Connect with our AI-powered chatbot, ready to assist you 24/7."
}


export const processTypes: IProcessType[] = [
  {
      name: "userStory",
      label: "Plan a User Story",
      urlSlug: "plan-a-user-story",
      image: "https://consultantaiassetstorage.blob.core.windows.net/assets/images/user-story-card-photo.jpg",
      description:
          "Confidently create a new feature for your users, step by step.",
  },
  {
      name: "breakUserStoryIntoTasks",
      label: "Break a User Story into Tasks",
      pickerTitle: "Select a User Story to Break into Tasks",
      urlSlug: "break-user-story-into-tasks",
      image: "https://consultantaiassetstorage.blob.core.windows.net/assets/images/break-user-story-into-tasks-card-photo.jpg",
      description:
          "Easily identify actionable steps towards implementing a new feature.",
  },
  {
      name: "task",
      label: "Plan a Task",
      urlSlug: "plan-a-task",
      image: "https://consultantaiassetstorage.blob.core.windows.net/assets/images/plan-task-card-photo.jpg",
      description:
          "Quickly outline and delegate tasks, from small to complex.",
  },
  {
      name: "dailyProgressReport",
      label: "Create a Daily Progress Report",
      pickerTitle:
          "Select previous item(s) to provide context for your daily progress report (optional)",
      urlSlug: "daily-progress-report",
      image: "https://consultantaiassetstorage.blob.core.windows.net/assets/images/daily-progress-report-card-photo.jpg",
      description:
          "Efficiently summarize and reflect on what's been accomplished so far. Identify goals and next steps.",
  },
  {
      name: "generalChat",
      label: "General Chat",
      urlSlug: "general-chat",
      image: "/testimages/genchat.png",
      description:
          "Connect with our AI-powered chatbot, ready to assist you 24/7.",
  },
];
