import React, {useEffect, useRef} from 'react';
import Typography from "@mui/material/Typography";

interface CollapsedTextProps {
    showText:boolean
    children: React.ReactNode | null
}
const CollapsedText:React.FC<CollapsedTextProps> = ({showText, children}) => {
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const div = divRef.current;

        if (!div) {
            return;
        }

        if (showText) {
            const actualHeight = `${div.scrollHeight}px`;
            div.style.height = actualHeight;
            div.addEventListener("transitionend", () => {
                div.style.height = "auto";
            }, { once: true });
        } else {
            div.style.height = `${div.scrollHeight}px`;
            requestAnimationFrame(() => {
                div.style.height = "0px";
            });
        }

    },[showText])

    return (
        <div
            ref={divRef}
            style={{
                height: "0",
                overflow: "hidden",
                transition: "height 500ms ease-in-out"
            }}
        >
            {children}
        </div>
    );
};

export default CollapsedText;