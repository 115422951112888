import React from 'react';
import ReactPlayer from "react-player/youtube";

interface MyPlayerProps {
    url: string;
}
const MyPlayer: React.FC<MyPlayerProps> = (url) => {
    return (
        <div
        style={{
            position: "relative",
            width: "100%",
            paddingBottom: "56.25%",
        }}
    >
        <iframe
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
            }}
            src="https://www.youtube.com/embed/63NfEkYCLz0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
        />
    </div>
            // <ReactPlayer
            //     url='https://youtu.be/o1chMISeTC0?si=xrePVnsNGU5fzawT'
            //     width='100%'
            //     // style={{
            //     //     // height:"50px !important",
            //     //     height:"50px",
            //     //     padding:"30px",
            //     // }}
            //     // height="calc(20vw)",
            //     // height='100%'
            //     // previewTabIndex={2}
            //     // light={true}
            //     // playing={true}
            //     // playing={true}
            //     // controls={true}
            //     // onReady={() => console.log('onReady callback')}
            //     // onStart={() => console.log('onStart callback')}
            // />
    );
};

export default MyPlayer;