import React from 'react';
import { useState, useEffect, useCallback } from "react";
import FlowEditor from './FlowEditor'; 
import {
  CircularProgress,
  Box,
  Stack,
  Alert, 
  Button,
  Typography, 
  Card,
  CardMedia,
  CardContent
  } from "@mui/material";
import { FlowData } from './types';

import axios from "../../utils/AxiosInstance";
import { AxiosError } from "axios";

// const with Flow data 
// import response from './test.json'

// functions interface
interface DashboardFlowEditorProps {
  startProcessFromFlow: (processName: string) => void;
}

const DashboardFlowEditor: React.FC<DashboardFlowEditorProps> = ({startProcessFromFlow}) => {
  //Connection and requests - Alert message 
  const [showSpinner, setShowSpinner] = useState<boolean>(false); 
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  // show Save (update) spinner
  const [showSaveSpinner, setShowSaveSpinner] = useState<boolean>(false);
  // is flow changed (for save button and exit check)
  const [isFlowChanged, setIsFlowChanged] = useState<boolean>(false);
  
  //  set flow data for component
  const [flowParams, setFlowParams] = useState<FlowData | null>(null);

   // name in Local storage
   const flowKey = 'flowchart';
   
  // get flow data from API
  const getFlowData = useCallback(async () => {
    
    // console.log("start post data");
    const url = `/flowchart/data-default`;
    // show spinner
    // set alert message
    setShowSpinner(true);
    setShowAlert(false);
    try {
      // check if exist in local storage
      if (localStorage.getItem(flowKey)) {
        readDataFromLocalStorage();
      } else {
        setAlertMessage("");
        // else request API
        const res = await axios.post(url, {}, {
          headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        const flowData = await res.data.flowchart;
        // console.log("flowData=>", flowData)
        // set all flows
        setFlowParams(flowData);
        // save Flow data to Local storage
        saveFlowDataLocalStorage(flowData);
        setShowSpinner(false);
        
      }
    } catch (err) {
      console.error(err);
      if (err instanceof AxiosError && err.response) {
        setAlertMessage(err.response.data);
      } else {
        setAlertMessage(
            "Check connection."
        );
    }
      setShowAlert(true);
    } finally {
      // set is loading to false
      setShowSpinner(false);
    }
  },[]);

  // Save Flow
 
  const saveFlowDataLocalStorage = useCallback((data: FlowData | null) => {
    // save to Local storage
    if (data) {localStorage.setItem(flowKey, JSON.stringify(data));}
  },[]) 

  // on Save Button Click => update flows and store to storage
  const updatedFlowAndSave = async (newFlowData: FlowData | null): Promise<boolean> => {
    // console.log("start update data");
    
    setShowSaveSpinner(true);
    const url = `/flowchart/update`;
    try {
    // get new flow data from component 
      if (newFlowData) {
        setIsFlowChanged(false);
        setAlertMessage("");
        // send new data to API
        const res = await axios.put(url, {data: newFlowData}, {
          headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        //  new info from API
        const resFlowData = await res.data.flowchart;
        // console.log("resFlowData=>", resFlowData)
        // set flow data
        setFlowParams(resFlowData);
        // store in Local Storage
        saveFlowDataLocalStorage(resFlowData);
        return true;
       
      } else {
        console.error(`Wrong data format`);
        setAlertMessage('Wrong data format');
        setShowAlert(true);
        setIsFlowChanged(true);
        return false;
      }
      // setShowSaveSpinner(false);
      
    } catch (err) {
      console.error('Save data error=>', err);
      if (err instanceof AxiosError && err.response) {
        setAlertMessage(err.response.data);
      } else {
        setAlertMessage(
            "Save problem. Check connection."
        );
    }
      
      setShowAlert(true);
      setIsFlowChanged(true);
      return false;

    }  finally {
      // set is loading to false
      setShowSaveSpinner(false);
    }
    
  };
 
  // read data from Local Storage
  const readDataFromLocalStorage = useCallback(() => {
    const restoreFlow = async () => {
      const response = localStorage.getItem(flowKey);
      if (response) {
        // console.log("Ok")
        const data = JSON.parse(response);
        
        // Set Flow params (data) get flow by it's ID => 1
        setFlowParams(data);
      } else {
        console.log("No Flow info in local storage")
      }
    };

    // call function
    restoreFlow();
  },[]);

  // On mount get Flow data
  useEffect(() => {
    // get flow data API request
    getFlowData();
  },[]);

  // send with props  
  return (
    <Box height="106vh">
    {/* Test */}
    {/* <Typography
      variant="h6"
      color="secondary"
      fontSize={{ xs: "1.5em", md: "2.5rem" }}
      >
      Test:
      <Button variant='contained' onClick={()=>{console.log("Read=>");readDataFromLocalStorage();}}>
        Read from Local Storage
      </Button>
    </Typography> */}

     {/* Header */}
    {/* <Typography
      variant="h2"
      color="secondary"
      fontSize={{ xs: "1.5em", md: "2.5rem" }}
      >
      Work Flow
    </Typography> */}
    {showAlert && (
      <Box>
        <Alert severity="error" sx={{ marginTop: 1 }}>
            {alertMessage}
        </Alert>
      </Box>
    )}
    { showSpinner ?
    <Box 
      marginTop={3}
      sx={{
      display: 'flex',
      justifyContent: 'center'}}
      >
      <CircularProgress
          color="primary"
          thickness={5}
          size="3em"
      />
    </Box>
    : (flowParams? <FlowEditor 
        showAlert={showAlert}
        setShowAlert={setShowAlert} 
        setAlertMessage={setAlertMessage}  
        flowParams={flowParams} 
        setFlowParams={setFlowParams} 
        startProcessFromFlow={startProcessFromFlow} 
        updatedFlowAndSave={updatedFlowAndSave}
        showSaveSpinner={showSaveSpinner} 
        setShowSaveSpinner={setShowSaveSpinner}
        isFlowChanged={isFlowChanged}
        setIsFlowChanged={setIsFlowChanged}
        getFlowData={getFlowData}
      /> 
      : 
      <Stack
          id="no-data"
          direction="row"
          width="100%"
          justifyContent="center"
          marginTop={3}
          flexWrap="wrap"
      >
        <Card
          sx={{
          height: "80px",
          marginX: { xs: 1, md: 1 },
          cursor: "pointer",
          width: "100%",
          maxWidth: "300px",
          marginY: 1,
          }}
        >
          {/* <CardMedia
              image={""}
              sx={{ height: "50%", bgcolor: 'text.disabled',  }}
          /> */}
          <CardContent>
            <Typography
                fontSize="1.5em"
                color='text.disabled'
                marginBottom={1}
                sx={{
                    textTransform: "none",
                    textAlign: "center",
                }}
            >
                There is no Workflow!
            </Typography>
          </CardContent>
        </Card>
      </Stack>
      // <Box sx={{ width: 1 }}>
      //   <Alert sx={{marginTop:1}}  severity="info">There is no Workflow!</Alert>
      // </Box>
      )
    }
    </Box>
  )
}

export default DashboardFlowEditor;