import React, {useContext} from 'react';
import { Button, Typography, Box } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { MixpanelContext } from '../../contexts/MixpanelContext'

const Start: React.FC = () => {
    const { sendAnalyticsEvent } = useContext(MixpanelContext)

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={3.5}
        margin="5rem 0"
      >
        <Typography variant="h3" align="center">
          Start using consultant.ai today
        </Typography>
        <Typography variant="h6" color="textSecondary">
          Try it now for free / No credit card needed
        </Typography>
        <NavLink to="/signup">
          <Button
            variant="contained"
            size="small"
            sx={{
              marginX: 0.5,
            }}
            onClick={() => {
              sendAnalyticsEvent('NavigateToSignup', {
                eventCategory: 'Navigation',
                action: 'Navigate to Signup',
              })
            }}
          >
            Get Started
            <ArrowForward sx={{ marginLeft: 1 }} />
          </Button>
        </NavLink>
      </Box>
    )
};

export default Start;