import { useContext } from "react";
import { Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import MyButton from "../../elements/MyButton";
import { ArrowForward, AutoAwesome } from "@mui/icons-material";
import ContactSalesButton from "../../elements/ContactSalesButton";
import { MixpanelContext } from "../../../../contexts/MixpanelContext";

const StartBuilding = () => {
    const {sendAnalyticsEvent} = useContext(MixpanelContext)
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Box>
                <img width={"100%"} src="\testimages\logo.png" alt="" />
            </Box>
            <Box>
                <Typography variant="h2" textAlign={"center"}>
                    <span className="gradient-text">
                        <strong>
                            Start optimizing your planning strategy with{" "}
                        </strong>
                    </span>
                </Typography>
                <Typography variant="h2" textAlign={"center"}>
                    <span className="gradient-text">
                        <strong>Consultant.ai</strong>
                    </span>
                </Typography>
            </Box>
            <Box>
                <Box
                    // className="glow"
                    p={6}
                    mt={7}
                    sx={{
                        backgroundColor: "rgb(56, 116, 203)",
                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        borderRadius: "10px",
                    }}
                >
                    <Typography
                        variant={"h3"}
                        color={"white"}
                        textAlign={"center"}
                    >
                        Deliver your best work with
                    </Typography>
                    <Typography
                        variant={"h3"}
                        color={"white"}
                        textAlign={"center"}
                    >
                        <strong>Consultant.ai</strong>
                    </Typography>

                    <Box
                        sx={{
                            textAlign: "center",
                            marginTop: "60px",
                        }}
                    >
                            <MyButton slideSpeed={0.3} buttonColor={"white"}>
                                <Typography
                                    component="span"
                                    color="grey"
                                    sx={{
                                        fontSize: "1.5em",
                                        letterSpacing: "-0.24px",
                                        position: "relative",
                                        paddingX: "40px",
                                        paddingY: "10px",
                                    }}
                                >
                                    <strong>
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <span>Get Started </span>{" "}
                                            <ArrowForward
                                                sx={{ marginLeft: "10px" }}
                                            />
                                        </Grid>
                                    </strong>
                                </Typography>
                            </MyButton>

                        <Box>
                            <Grid
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                wrap="nowrap"
                                sx={{
                                    marginTop: '10px',
                                    lineHeight: '0.5em',
                                    marginBottom: '30px'
                                }}
                            >
                            <span>Free trial </span>
                            <AutoAwesome sx={{
                                width: '40px',
                                paddingTop: '10px'
                                
                            }}/>
                            <span> No credit card needed</span>
                            </Grid>
            
                                <Button
                                onClick={()=>{

                                    sendAnalyticsEvent('NavigateToContactSales', {
                                    eventCategory: 'User Interaction',
                                    action: 'Navigate to Contact Sales',
                                    })
                                }}
                                href="/contact-sales"
                                    variant="outlined"
                                    size="large"
                                    sx={{
                                        color: "white",
                                        fontWeight: "bold",
                                        borderColor: "white",
                                        mt: '20px'

                                    }}
                                >Contact Sales</Button>
                            {/* <ContactSalesButton
                             slideSpeed={0.3} buttonColor={"transparent"}>
                                <Typography
                                    component="span"
                                    color="white"
                                    sx={{
                                        fontSize: "1em",
                                        letterSpacing: "-0.24px",
                                    }}
                                >
                                    <strong>
                                            <span>Contact Sales</span>
                                    </strong>
                                </Typography>                            
                            </ContactSalesButton>     */}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default StartBuilding;
