import { useCallback, useEffect, useState } from "react";
import axios from "../../utils/AxiosInstance";
import { AxiosError } from "axios";
import { Alert, CircularProgress, Stack } from "@mui/material";

import ProfileDisplay from "./ProfileDisplay";

import { IUserProfile } from "../../models/IUserProfile";

const ProfileContent = () => {
    const [userProfileData, setUserProfileData] = useState<IUserProfile | null>(
        null
    );
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>("");

    const fetchProfile = useCallback(async () => {
        setShowAlert(false);
        try {
            const res = await axios.get("/store/user-profile", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "authToken"
                    )}`,
                },
            });
            const userProfile = await res.data;
            setUserProfileData(userProfile);
        } catch (err) {
            console.error(err);
            if (err instanceof AxiosError && err.response) {
                setAlertMessage(err.response.data);
            } else {
                setAlertMessage(
                    "Sorry, there was a problem retrieving your profile information. Please try again later."
                );
            }
            setShowAlert(true);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchProfile();
    }, [fetchProfile]);

    return (
        <Stack
            width={{ xs: "100%", md: "100%" }}
            marginX="auto"
        >
            <Stack
                height="100%"
                justifyContent={isLoading ? "center" : "flex-start"}
                alignItems={isLoading || showAlert ? "center" : "flex-start"}
            >
                {isLoading && <CircularProgress color="primary" size={100} />}
                {showAlert && <Alert severity="error">{alertMessage}</Alert>}
                {userProfileData && (
                    <ProfileDisplay
                        userProfileData={userProfileData}
                        setUserProfileData={setUserProfileData}
                    />
                )}
            </Stack>
        </Stack>
    );
};

export default ProfileContent;
