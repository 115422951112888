import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import FaqItem from './FaqItem';

const faqData = [
    {
        question: 'Is there a free version of consultant.ai?',
        answer: 'consultant.ai has a free plan which is ideal for freelancers and individual professionals. In addition, we offer a 14-day free trial for the Pro plan.',
    },
    {
        question: 'How much does consultant.ai cost?',
        answer: 'consultant.ai starts from $24 per month for three users and goes up from there based on your chosen plan and team size. If you are over 40 users, you can request a quote to get an exact price.',
    },
    {
        question: 'Can you pay for consultant.ai monthly?',
        answer: 'Yes, you can pay monthly for your account. Choose the monthly option on the pricing page and you will be able to pay on a per-month basis. The monthly plan is not discounted so if you are looking to save, we recommend the yearly plan.',
    },
    {
        question: 'Which pricing plan is right for me?',
        answer: 'We understand that each organization is unique, requiring specific features to support its workflows and projects. Above you can see the features included in the different plans to support your needs. If you need help in choosing the right plan for you, check out this article or reach out to our sales team.',
    },
    {
        question: 'How does our pricing work?',
        answer: 'Our pricing is based on two factors; the feature plan you’d like to use, and the number of users on the platform. Once you’ve decided on the feature plan and users, choose your preferred subscription payment: month-to-month or annual. Payment is made in one upfront installment. If you purchase a plan for one year, you will pay for one year upfront.',
    },
    {
        question: 'Do you offer any discounted plans?',
        answer: 'Yes, by choosing a yearly plan, you will receive an 18% discount.',
    },
    {
        question: 'Does consultant.ai offer plans for students?',
        answer: 'Yes, our Student Program offers great options for students and student organizations who would like to implement consultant.ai in their studies and activities. University/college students, student organizations, fraternities, and sororities can all join the student program.',
    },
    {
        question: 'Does consultant.ai offer plans to nonprofits and NGOs?',
        answer: 'consultant.ai is a proud supporter of organizations that do great things around the world. That’s why we offer discounted pricing for qualified nonprofit organisations. Click here to find out more about our nonprofit plan and pricing.',
    },
    {
        question: 'How can I manage my billing?',
        answer: 'If you are an admin of your account, you can access your purchase history, invoices, payment details, plan type, and more. Learn how to manage your billing details here.',
    },
    {
        question: 'Can I change my plan?',
        answer: "You can make changes to your plan at any time by changing your plan type and by adding or removing users. However, past the refund period, we offer no refunds for downgrades. To change your plan, simply go into the Admin section and click on the Billing option. From there, click 'change plan' and choose your desired plan.",
    },
    {
        question: 'How secure is consultant.ai?',
        answer: 'The security of our customer’s data is our top priority. We use advanced security measures to preserve the integrity and privacy of your data, and adhere to strict data protection laws. consultant.ai is built with internationally recognized security standards and protocols in place, such as ISO/IEC 27001 and ISO/IEC 27018, and we undergo annual security audits and assessments.',
    },
    {
        question: 'What payment methods do you accept?',
        answer: 'We accept payments from the following major credit cards, excluding debit cards; Visa, Master Card, American Express, Discover, Diners Club, JCB, Carte Bleue, Union Pay. You can also purchase your consultant.ai subscription with PayPal. Please note this may be subject to change depending on your location. In addition, we accept invoices for Enterprise orders that meet a minimum fee - Feel free to reach out to support@consultant.ai for more information.',
    },
    {
        question: 'Do you have mobile apps?',
        answer: 'Yes, the platform is available on both iOS and Android devices. You can download them on the Apple or Google Play Stores. Learn more here.',
    },
];

const Faq: React.FC = () => {
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const handleToggle = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <Box bgcolor="#F2FFFF" style={{ minHeight: '100vh' }}>
            <Box paddingTop={8} paddingBottom={2} maxWidth={800} margin="auto">
                <Typography
                    variant="h4"
                    align="center"
                    marginBottom={4}
                    fontWeight={600}
                >
                    Frequently Asked Questions
                </Typography>
                {faqData.map((item, index) => (
                    <FaqItem
                        key={index}
                        {...item}
                        isOpen={openIndex === index}
                        onToggle={() => handleToggle(index)}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default Faq;