import { Stack } from "@mui/material";

import ContactForm from "./ContactForm";
import ContactHero from "./ContactHero";

const ContactContent = ({ title }: { title: string }) => {
    return (
        <Stack direction="row">
            <ContactForm title={title} />
            <ContactHero />
        </Stack>
    );
};

export default ContactContent;
