import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { NavLink, useLocation, useNavigate, Link } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import {
  AccountCircle,
  Add,
  ArrowDownward,
  ArrowDropDown,
  ArrowForward,
  MoreVert,
} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CreateWorkspaceModal from "../Workspace/CreateWorkspace";
import EditIcon from "@mui/icons-material/Edit";
import axios from "../../utils/AxiosInstance";
import { AxiosError } from "axios";
import { MixpanelContext } from "../../contexts/MixpanelContext";

import PopoverMenu from "./PopoverMenu";
import GetInitials from "../Profile/GetInitials";

interface Workspace {
  id: string;
  name: string;
  creator: string;
  created_at: string;
  updated_at: string;
  private: boolean;
  owned: boolean;
}

const NavMenu = () => {
  const authContext = useContext(AuthContext);
  const { isLoggedIn, activeUser } = authContext!;
  const { firstName } = activeUser;
  const { sendAnalyticsEvent } = useContext(MixpanelContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    sendAnalyticsEvent("WorkspaceMenuOpen", {
      eventCategory: "User Interaction",
      action: "Open Workspace Menu",
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChoice = async (ws_id: string) => {
    const res = await axios.post(
      "/workspace/change-workspace",
      { workspace_id: ws_id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      }
    );
    setCurrentWorkspace(res.data.new_workspace_name);
    localStorage.setItem(
      "lastChosenWorkspace",
      JSON.stringify({ name: res.data.new_workspace_name, id: ws_id })
    );
    setAnchorEl(null);
    if (localStorage.getItem("workspaceChatHistory")) {
      localStorage.removeItem("workspaceChatHistory");
    }
    window.location.reload();
  };
  const handleIconClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    sendAnalyticsEvent("NavigateToMenuItem", {
      eventCategory: "Navigation",
      action: "Navigate to Menu Item",
    });
  };
  const [showPopoverMenu, setShowPopoverMenu] = useState<boolean>(false);
  const [popoverMenuAnchorEl, setPopoverMenuAnchorEl] =
    useState<null | HTMLElement>(null);



  const togglePopoverMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setShowPopoverMenu(!showPopoverMenu);
    if (!popoverMenuAnchorEl) setPopoverMenuAnchorEl(e.currentTarget);
    sendAnalyticsEvent("NavbarMenuOpen", {
      eventCategory: "User Interaction",
      action: "Open Navbar Menu",
    });
  };
  const [newWorkspaceModal, setNewWorkspaceModal] = useState(false);
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [currentWorkspace, setCurrentWorkspace] = useState<string>("Workspace");

  useEffect(() => {
    if (isLoggedIn) {
      const fetchWorkspaces = async () => {
        try {
          const response = await axios.get("/store/workspaces-list", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          });
          console.log(response.data);
          setWorkspaces(response.data.workspaces as Workspace[]);
          if (response.data.active_workspace != "") {
            setCurrentWorkspace(response.data.active_workspace);
          }
        } catch (err) {
          console.error(err);
          if (err instanceof AxiosError && err.response) {
            console.log(err.response.data);
          }
        }
      };
      const lastChosenWorkspace = JSON.parse(
        localStorage.getItem("lastChosenWorkspace") || "{}"
      );
      if (lastChosenWorkspace && lastChosenWorkspace.name) {
        setCurrentWorkspace(lastChosenWorkspace.name);
      }
      fetchWorkspaces();
    }
  }, [isLoggedIn]);

  const activateModalNew = () => {
    setNewWorkspaceModal(true);
    sendAnalyticsEvent("CreateWorkspaceModal", {
      eventCategory: "User Interaction",
      action: "Open Create Workspace Modal",
    });
  };

  const deactivateModalNew = () => {
    setNewWorkspaceModal(false);
  };

  const isCurrentWorkspacePage = (
    workspaceId: string,
    workspaceName: string
  ) => {
    return location.pathname === `/workspace/${workspaceId}/${workspaceName}`;
  };

  return (
    <Stack direction="row" alignItems="center" id="nav-menu">
      {activeUser.isAdmin && (
        <Box marginRight={1} padding={1} sx={{ backgroundColor: "black" }}>
          <Typography color="white">Admin</Typography>
        </Box>
      )}
      {isLoggedIn ? (
        // changed align to center
        <Stack direction="row" alignItems="center">
          {/* <NavLink to="/dashboard">
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{
                                marginX: 0.5,
                            }}
                        >
                            Dashboard
                        </Button>
                    </NavLink> */}
          <div>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              variant="outlined"
              size="small"
              sx={{ p: 0, mx: 0.5, pl: "10px", lineHeight: 1.15 }}
            >
              {currentWorkspace} <ArrowDropDown />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              title="Workspaces"
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {workspaces.map((workspace) => (
                <MenuItem
                  divider
                  onClick={() => {
                    handleChoice(workspace.id);
                  }}
                  style={{
                    pointerEvents:
                      currentWorkspace === workspace.name ? "none" : "auto",
                  }}
                >
                  {workspace.name}
                  {!workspace.private && workspace.owned ? (
                    <Link
                      to={`/workspace/${workspace.id}/${workspace.name}`}
                      style={{ marginLeft: "auto" }}
                    >
                      <div
                        style={{
                          pointerEvents: isCurrentWorkspacePage(
                            workspace.id,
                            workspace.name
                          )
                            ? "none"
                            : "auto",
                        }}
                      >
                        <IconButton
                          color="primary"
                          onClick={handleIconClick}
                          disabled={isCurrentWorkspacePage(
                            workspace.id,
                            workspace.name
                          )}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Link>
                  ) : (
                    ""
                  )}
                </MenuItem>
              ))}
              {/* <MenuItem divider onClick={handleClose}>Private</MenuItem>
                            <MenuItem divider onClick={handleClose}>Consultant.ai</MenuItem>
                            <MenuItem divider onClick={handleClose}>Google</MenuItem> */}
              {/* <MenuItem><Button variant="outlined" onClick={activateModalMy}>Edit Workspaces </Button></MenuItem> */}
              <MenuItem>
                <Button variant="outlined" onClick={activateModalNew}>
                  Create Workspace <Add />
                </Button>
              </MenuItem>
              <CreateWorkspaceModal
                open={newWorkspaceModal}
                onClose={deactivateModalNew}
              ></CreateWorkspaceModal>
              {/* <MyWorkspacesModal open={myWorkspaceModal} onClose={deactivateModalMy}></MyWorkspacesModal> */}
            </Menu>
          </div>
          <NavLink to="/profile" style={{ marginLeft: "10px" }}>
            <Avatar
              sx={{
                bgcolor: "rgb(56, 116, 203)",
                width: 25,
                height: 25,
              }}
            >
              <GetInitials name={firstName} />
            </Avatar>
            {/* <AccountCircle
                                sx={{
                                    marginTop: 0.5,
                                    color: "#555",
                                }}
                            /> */}
            {/* <Typography sx={{ textTransform: "none" }}>
                                {firstName}
                            </Typography> */}
          </NavLink>
        </Stack>
      ) : (
        <Box>
          <Stack direction="row" alignItems="center">
            {!isLoggedIn && document.location.pathname !== "/login" && (
              <Stack direction="row" alignItems="center">
                {document.location.pathname === "/" && (
                  <>
                    <NavLink
                      className="pricing-button"
                      to="/pricing"
                      state={{ title: "Pricing" }}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        size="small"
                        sx={{
                          marginX: 0.5,
                          color: "rgb(25,118,210)",
                          backgroundColor: "transparent",
                          borderRadius: "15px",
                        }}
                        onClick={() => {
                          sendAnalyticsEvent("NavigateToPricing", {
                            eventCategory: "Navigation",
                            action: "Navigate to Pricing",
                          });
                        }}
                      >
                        Pricing
                      </Button>
                    </NavLink>
                    <NavLink
                      className="contact-sales-button"
                      to="/contact-sales"
                      state={{ title: "Contact Sales" }}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        size="small"
                        onClick={() => {
                          sendAnalyticsEvent("NavigateToContactSales", {
                            eventCategory: "Navigation",
                            action: "Navigate to Contact Sales",
                          });
                        }}
                        sx={{
                          marginX: 0.5,
                          color: "rgb(25,118,210)",
                          backgroundColor: "transparent",
                          borderRadius: "15px",
                        }}
                      >
                        Contact Sales
                      </Button>
                    </NavLink>
                  </>
                )}
                <NavLink to="/signup">
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      marginX: 0.5,
                    }}
                    onClick={() => {
                      sendAnalyticsEvent("NavigateToSignup", {
                        eventCategory: "Navigation",
                        action: "Navigate to Signup",
                      });
                    }}
                  >
                    Get Started <ArrowForward sx={{ marginLeft: 1 }} />
                  </Button>
                </NavLink>
              </Stack>
            )}
          </Stack>
        </Box>
      )}
      <IconButton onClick={togglePopoverMenu}>
        <MoreVert />
      </IconButton>
      <PopoverMenu
        anchorEl={popoverMenuAnchorEl}
        showPopoverMenu={showPopoverMenu}
        setShowPopoverMenu={setShowPopoverMenu}
        onClose={togglePopoverMenu}
      />
    </Stack>
  );
};

export default NavMenu;
