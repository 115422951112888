import { createContext, useState, useEffect } from "react";
import mixpanel from "mixpanel-browser";

export const MixpanelContext = createContext()

const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN || 'default_token'
const nodeEnv = process.env.NODE_ENV || 'development' 

if (nodeEnv === 'production') {
  mixpanel.init(mixpanelToken, {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
  })
} else {
  mixpanel.init(nodeEnv, {
    debug: true,
    track_pageview: false,
    persistence: 'localStorage',
  })
}

// console.log('node env', process.env.NODE_ENV)
// console.log('mixpanelToken:', process.env.REACT_APP_MIXPANEL_TOKEN)

// mixpanel.init("df6a9a59ac8d41abe2eb400ea15b5c1a");

const MixpanelContextProvider = ({ children }) => {
    const [url, setUrl] = useState(window.location.href);
    const [eventName, setEventName] = useState('');
    const [eventData, setEventData] = useState({});

    useEffect(() => {
        const handleLocationChange = () => {
            setUrl(window.location.href);
        };

        window.addEventListener("popstate", handleLocationChange);

        return () => {
            window.removeEventListener("popstate", handleLocationChange);
        };
    }, []);

    useEffect(() => {
        mixpanel.track("Page View", { url });
        window.gtag('event', 'Page View', {
            page_title: document.title,
            event_action: 'Viewed Page',
            page_path: url,
        })
    }, [url]);

    useEffect(() => {
        mixpanel.track(eventName, eventData);
        window.gtag('event', eventName, eventData);
    }, [eventName, eventData])

    const sendAnalyticsEvent = (eventName, eventData) => {
        setEventName(eventName)
        setEventData(eventData)
    }

    // console.log('eventname', eventName);
    // console.log('eventdata', eventData);

    return (
        <MixpanelContext.Provider value={{ sendAnalyticsEvent }}>
            {children}
        </MixpanelContext.Provider>
    );
};

export default MixpanelContextProvider;
