import React, { useState } from 'react';
import {
    Container,
    Grid,
    Typography,
    Box,
    Select,
    MenuItem,
} from '@mui/material';
import PricingCard from '../components/Pricing/PricingCard';
import Header from '../components/global/Header';
import Faq from '../components/Pricing/Faq';
import Reviews from '../components/Pricing/Reviews';
import Start from '../components/Pricing/Start';

const pricingData = [
    {
        title: 'Free',
        price: '$0',
        subtitle: 'free forever',
        description: 'For individuals looking to keep track of their work',
        buttonText: 'Try for free',
        featureHeader: 'Free plan includes:',
        features: [
            'Up to 3 boards',
            'Unlimited docs',
            '200+ templates',
            '8 column types',
            'Up to 2 team members',
            'iOS and Android apps',
        ],
    },
    {
        title: 'Basic',
        price: '$8',
        subtitle: 'seat / month',
        description: 'Manage all your teams’ work in one place',
        buttonText: 'Try for free',
        featureHeader: 'Includes Free, plus:',
        features: [
            'Unlimited free viewers',
            'Unlimited items',
            '5 GB file storage',
            'Prioritised customer support',
            'Create a dashboard based on 1 board',
        ],
    },
    {
        title: 'Standard',
        price: '$10',
        subtitle: 'seat / month',
        description: 'Collaborate and optimize your team processes',
        buttonText: 'Try for free',
        featureHeader: 'Includes Basic, plus:',
        features: [
            'Timeline & Gantt views',
            'Calendar view',
            'Guest access',
            'Automations (250 actions per month)',
            'Integrations (250 actions per month)',
            'Create a dashboard that combines up to 5 boards',
        ],
    },
    {
        title: 'Pro',
        price: '$19',
        subtitle: 'seat / month',
        description: 'Streamline and run your teams’ complex workflows',
        buttonText: 'Try for free',
        featureHeader: 'Includes Standard, plus:',
        features: [
            'Private boards and docs',
            'Chart view',
            'Time tracking',
            'Formula column',
            'Dependency column',
            'Automations (25,000 actions per month)',
            'Integrations (25,000 actions per month)',
            'Create a dashboard that combines up to 10 boards',
        ],
    },
    {
        title: 'Enterprise',
        price: 'X',
        description: 'For organizations seeking enterprise-grade features',
        buttonText: 'Contact us',
        featureHeader: 'Includes Pro, plus:',
        features: [
            'Enterprise-scale Automations & Integrations',
            'Enterprise-grade security & governance',
            'Advanced reporting & analytics',
            'Multi-level permissions',
            'Tailored onboarding',
            'Premium support',
            'Create a dashboard that combines up to 50 boards',
        ],
    },
];

const Pricing: React.FC = () => {
    const [selectedSeats, setSelectedSeats] = useState<number>(3);
    const seatsDropdown = (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={5}
        >
            <Box display="flex" alignItems="center" flex="1">
                <Typography
                    variant="body1"
                    style={{
                        marginRight: '12px',
                        alignSelf: 'center',
                        whiteSpace: 'nowrap',
                    }}
                >
                    Choose team size:
                </Typography>
                <Select
                    value={selectedSeats}
                    onChange={(e) => setSelectedSeats(Number(e.target.value))}
                    style={{
                        minWidth: 50,
                        maxWidth: 150,
                        maxHeight: 35,
                        flexGrow: 1,
                        marginRight: '12px',
                    }}
                >
                    {[3, 5, 10, 15, 20, 25, 30, 40, 50, 100, 200].map(
                        (seat) => (
                            <MenuItem value={seat} key={seat}>
                                {seat} Seats
                            </MenuItem>
                        )
                    )}
                </Select>
            </Box>

            <Box display="flex" alignItems="center">
                <Typography
                    variant="subtitle1"
                    color="primary"
                    style={{ marginRight: '3.5px', whiteSpace: 'nowrap' }}
                >
                    Yearly SAVE 18% |
                </Typography>
                <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    Monthly
                </Typography>
            </Box>
        </Box>
    );

    const determineMostPopular = (planTitle: string) => {
        if ([3, 5].includes(selectedSeats) && planTitle === 'Standard')
            return true;
        if (
            [10, 15, 20, 25, 30, 40, 50].includes(selectedSeats) &&
            planTitle === 'Pro'
        )
            return true;
        if ([100, 200].includes(selectedSeats) && planTitle === 'Enterprise')
            return true;
        return false;
    };

    return (
        <>
            <Header />
            <Container
                style={{
                    marginTop: '8rem',
                    marginBottom: '7rem',
                    maxWidth: '1400px',
                }}
            >
                <Typography
                    variant="h2"
                    align="center"
                    gutterBottom
                    style={{ fontWeight: 600, marginBottom: '4rem' }}
                >
                    Supercharge your teamwork. Start{' '}
                    <span style={{ color: 'rgb(56, 116, 203)' }}>free</span>.
                </Typography>
                {seatsDropdown}
                <Grid container spacing={0} rowSpacing={2}>
                    {pricingData.map((plan, index) => (
                        <PricingCard
                            key={index}
                            {...plan}
                            selectedSeats={selectedSeats}
                            isMostPopular={determineMostPopular(plan.title)}
                        />
                    ))}
                </Grid>
            </Container>
            <Reviews />
            <Faq />
            <Start />
        </>
    );
};

export default Pricing;