import { useState } from "react";
import { Stack } from "@mui/material";

import { IGroupedChatHistory } from "../../models/IGroupedChatHistory";

import DashboardMain from "./DashboardMain";
import ChatHistory from "../Chat/ChatHistory";
import DashboardHero from "./DashboardHero";

const DashboardContent = () => {
    const [chatHistoryData, setChatHistoryData] =
        useState<IGroupedChatHistory | null>(null);
    const [isHistoryLoading, setIsHistoryLoading] = useState<boolean>(true);

    return (
        <Stack direction={{ md: "row" }}>
            <DashboardMain />
            {((!chatHistoryData && isHistoryLoading) ||
                (chatHistoryData && !isHistoryLoading)) && (
                <ChatHistory
                    chatHistoryData={chatHistoryData}
                    setChatHistoryData={setChatHistoryData}
                    isHistoryLoading={isHistoryLoading}
                    setIsHistoryLoading={setIsHistoryLoading}
                />
            )}
            {!chatHistoryData && !isHistoryLoading && <DashboardHero />}
        </Stack>
    );
};

export default DashboardContent;
