import { 
  useState, 
  useCallback, 
  useEffect, 
 
} from "react";

import { 
  Typography, 
  Box, 
  Button,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  SelectChangeEvent,
  Switch,
  Tooltip,
  Collapse,
  Alert,
  Paper,
  CircularProgress,
  useMediaQuery

 } from "@mui/material";
import  {
  EditOutlined, 
  SaveOutlined,
  CloseOutlined,
  DeleteForeverOutlined,
  AirlineStopsOutlined,
  EastOutlined,
  WestOutlined,
  AddBoxOutlined,
 

} from '@mui/icons-material';
// react flow
  
  import ReactFlow, {
  addEdge,
  Background,
  BackgroundVariant,
  FitViewOptions,
  applyNodeChanges,
  applyEdgeChanges,
  Node,
  Edge,
  OnNodesChange,
  OnEdgesChange,
  OnConnect,
  SelectionMode,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
  MarkerType,
  useStoreApi, // to to access the internal store with flow parameters
  useViewport,
  Panel,
  useOnViewportChange,
  useReactFlow,
  Controls,
  useStore 
} from 'reactflow';

import { OutlinedBlueNode, CardNode, PaperNode } from './CustomNode'; 

// Import IconType from the file
import { iconType } from './IconTypes';

// Import process Map name - label for functions
import { processTypes, imageLinks, backgroundImageLink, processMapForFunctions, headerMapForProcess, textMapForProcess, tooltipMapForProcess  } from './infoMapForFunctions';

// Import components
import AddNewNode from './AddNewNode';
import AddNewProcessCard from './AddNewProcessCard'

// default styling
import 'reactflow/dist/style.css';
// additional css
import './Flow.css';

// data type
import { FlowData } from './types';

const fitViewOptions: FitViewOptions = {
  padding: 0.2,
};

// Edge style
const defaultEdgeOptions = {
  animated: false,
  type: 'default',
  // end of edge line
  // markerEnd: {
  //   type: MarkerType.Arrow,
  //   width: 10,
  //   height: 10,
  // },
  label: ''
};

const nodeTypes = {
  custom: OutlinedBlueNode,
  card: CardNode,
  paper: PaperNode,
};

interface Props {
  showAlert: boolean; 
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertMessage: React.Dispatch<React.SetStateAction<any>>;
  startProcessFromFlow: (processName: string) => void; 
  flowParams: FlowData | null; 
  setFlowParams: React.Dispatch<React.SetStateAction<any>>;
  updatedFlowAndSave: (data: FlowData) => Promise<boolean>;
  showSaveSpinner: boolean; 
  setShowSaveSpinner: React.Dispatch<React.SetStateAction<boolean>>; 
  isFlowChanged: boolean; 
  setIsFlowChanged: React.Dispatch<React.SetStateAction<boolean>>;
  getFlowData:() => void;
}



const FlowProcess: React.FC<Props> = ({ setShowAlert, setAlertMessage, flowParams, setFlowParams, startProcessFromFlow, updatedFlowAndSave, showSaveSpinner, setShowSaveSpinner, showAlert, isFlowChanged, setIsFlowChanged, getFlowData }) => {
  // set initial elements:
  // const initialNodes: Node[] = flowParams.nodes;
  // const initialEdges: Edge[] = flowParams.edges;
  // Media
   // media query desktop - true
  const isNonMobile = useMediaQuery("(min-width: 767px)");
  
  // Flow Name
  const [flowName, setFlowName] = useState<string>(flowParams?.name || "");

  // Flow parameters for export (save)
  const [rfInstance, setRfInstance] = useState<any>(null);
  const { setViewport } = useReactFlow();
  
  // const flowStyles = {height: "100px"}

  //  Update Nodes and Edges if flow data from main component updates
  useEffect(() => {
    // console.log('Data changed:', flowParams);
    if (flowParams) {
      setNodes(flowParams.nodes);
      setEdges(flowParams.edges);
      // process name
      setFlowName(flowParams.name);
    }
  }, [flowParams]);

  // Auto resize start
  const widthSelector = (state: { width: any }) => state.width;
  const heightSelector = (state: { height: any }) => state.height;
  const reactFlowWidth = useStore(widthSelector);
  const reactFlowHeight = useStore(heightSelector);
  const reactFlowInstance = useReactFlow();
  
  useEffect(() => {
    reactFlowInstance.fitView();
  }, [reactFlowWidth, reactFlowHeight, reactFlowInstance]);
  // Auto resize end

  // Flow editor store access
  const store = useStoreApi();
  // const { x, y, zoom } = useViewport();




  //  Function to clear selection for Nodes and Edges
  const clearEditSelectionsNodes = (arrOfElements: Node[]) => {
    return arrOfElements.map(item => ({
      ...item,
      selected: item.selected ? false : item.selected,
      data: {
        ...item.data, 
        isHandles: item.type !== "custom"? !isEditMenu : item.data.isHandles, 
        isResize: item.type === "paper"? (!isEditMenu? true: false ) : false,
        
      }

    }));
  };

  const unselectEdges = (arrOfElements: Edge[]) => {
    return arrOfElements.map(item => ({
      ...item,
      selected: item.selected ? false : item.selected
    }));
  };


  // edit menu open
  const [isEditMenu, setIsEditMenu] = useState<boolean>(false);
  
  
  // view port changed listener
  useOnViewportChange({
        onChange: (viewport) => {if (isEditMenu) {
          setIsFlowChanged(true);
          setIsSaveAlertOpen(false);
        };
       },
  });
  // Save on exit status
  const [isSaveAlertOpen, setIsSaveAlertOpen] = useState<boolean>(false);


  //buttons and menu
  // Edit button
  const handleClickEdit = () => {
    // edit button logics
  
    if (isEditMenu && isFlowChanged) {
      // console.log("Save needed?");
      // open Alert to Save or Exit Close
      setIsSaveAlertOpen(true);
      return
    }
    exitEditMenu();
    // Hide Alert and set message to ""
    setAlertMessage("");
    setShowAlert(false);
  };

  // Exit from Edit Menu and close it
  const exitEditMenu = () => {
   // unselect Nodes and Edges
    setNodes(clearEditSelectionsNodes(nodes));
    setEdges(unselectEdges(edges));
    // Clear selected elements
    setNodeEdit({});
    setEdgeEdit({});

    setIsSaveAlertOpen(false);
    setIsEditMenu(!isEditMenu);
    setVariant(isEditMenu? '' : 'lines');
    
    // setIsNodeEditMenu(false);
    // fitView test
    rfInstance.fitView();
    setIsFlowChanged(false);
 
    
  }

  // Save button click Save
  const handleClickSave = async () => {
       
    // save button logics
    // unselect Nodes and Edges
    setNodes(clearEditSelectionsNodes(nodes));
    setEdges(unselectEdges(edges));
    // clear selection
    setNodeEdit({});
    setEdgeEdit({});
    
    setIsSaveAlertOpen(false);

    setIsEditMenu(false);
    setVariant('');
    
    // setIsNodeEditMenu(false);
    // fitView test
    rfInstance.fitView();
    // console.log('Save :', isFlowChanged);
    // console.log(edges, nodes);
  
   
    // console.log("flowParams=>", flowParams);
    // save flow params in parent component
    if (flowParams && rfInstance) {
      // het info current flow chart
      const flow = rfInstance.toObject();
      // const unselect Node or Edge
     
      const saveResult = await updatedFlowAndSave({
        name: flowName,
        nodes: clearEditSelectionsNodes(nodes),
        edges: unselectEdges(edges)
    });
    // console.log("saveResult=>", saveResult);
    // Logic depends on result
    if (saveResult) {
      // exit 
      // setIsSaveAlertOpen(false);
      // setIsEditMenu(false);
      // setVariant('');
      
      // setIsNodeEditMenu(false);
      // fitView test
      rfInstance.fitView();
      // setIsFlowChanged(false);
    } else {
      setIsEditMenu(true);
      setVariant('lines');
    }

       
    
    }
  };


  //  Node Edit Menu

   // edit menu open
  // const [isNodeEditMenu, setIsNodeEditMenu] = useState<boolean>(false);
  
  // Flow Name
  const handleChangeFlowName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFlowChanged(true);
    setFlowName(event.target.value);



  };

  //buttons and menu
  // Delete Node
  const handleClickNodeDelete = () => {
   
    if (nodeEdit.id) {
     
      // Remove the node with the corresponding id
      const updatedNodes = nodes.filter(node => node.id !== nodeEdit.id);
      setNodes(updatedNodes);
      
      // remove any edges connected to this node
      const updatedEdges = edges.filter(edge => edge.source !== nodeEdit.id && edge.target !== nodeEdit.id);
      setEdges(updatedEdges);

       // Clear the selected node from the state
      //  setIsNodeEditMenu(false);
       setNodeEdit({});
   };
  };

  // Node Edit menu button
  // const handleClickNodeEdit = () => {
  //   // edit button logics
  //   console.log('Edit button clicked!');
  //   setIsNodeEditMenu(!isNodeEditMenu);
  // };

  
  // Change Node data
  // Node Header
  const handleChangeNodeHeader = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('Changed', nodeEdit, (event.target as HTMLInputElement).value);
    // modify Node list
    setNodeEdit({
      ...nodeEdit,
      data: { ...nodeEdit.data, header: event.target.value }
    });

    const newNode = nodes.map((item) => {
      if (item.id === nodeEdit.id) {
        return {
          ...item,
          data: { ...item.data, header: event.target.value }
        };
      }
      return item;
    });

    setNodes(newNode);
  };

  // Node  text
  const handleChangeNodeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('Changed', nodeEdit, (event.target as HTMLInputElement).value);
    // modify Node list
    setNodeEdit({
      ...nodeEdit,
      data: { ...nodeEdit.data, text: event.target.value }
    });

    const newNode = nodes.map((item) => {
      if (item.id === nodeEdit.id) {
        return {
          ...item,
          data: { ...item.data, text: event.target.value }
        };
      }
      return item;
    });
    
    setNodes(newNode);
  };

  // Node  text
  const handleChangeNodeTooltip = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('Changed', nodeEdit, (event.target as HTMLInputElement).value);
    // modify Node list
    setNodeEdit({
      ...nodeEdit,
      data: { ...nodeEdit.data, tooltip: event.target.value }
    });

    const newNode = nodes.map((item) => {
      if (item.id === nodeEdit.id) {
        return {
          ...item,
          data: { ...item.data, tooltip: event.target.value }
        };
      }
      return item;
    });
    
    setNodes(newNode);
  };


  // Node  Icon with Select
  const handleChangeNodeIconSelect = (event: SelectChangeEvent) => {
    // console.log('Changed', nodeEdit, event.target.value);
    // modify Node list
    setNodeEdit({
      ...nodeEdit,
      data: { ...nodeEdit.data, icon: event.target.value }
    });

    const newNode = nodes.map((item) => {
      if (item.id === nodeEdit.id) {
        return {
          ...item,
          data: { ...item.data, icon: event.target.value }
        };
      }
      return item;
    });
    
    setNodes(newNode);
  };


  // Node Background Image  Select
  const handleChangeNodeImageSelect = (event: SelectChangeEvent) => {
    // console.log('Changed', nodeEdit, event.target.value);
    // modify Node list
    setNodeEdit({
      ...nodeEdit,
      data: { ...nodeEdit.data, image: event.target.value }
    });

    const newNode = nodes.map((item) => {
      if (item.id === nodeEdit.id) {
        return {
          ...item,
          data: { ...item.data, image: event.target.value }
        };
      }
      return item;
    });
    
    setNodes(newNode);
  };
    
  // Process ( function ) for Node => handle
  const handleChangeNodeProcessSelect = (event: SelectChangeEvent) => {
    // console.log('Changed', nodeEdit, event.target.value);
    // modify Node list
    setNodeEdit({
      ...nodeEdit,
      data: { ...nodeEdit.data, 
        process: event.target.value,
        header: headerMapForProcess[event.target.value], 
        text: textMapForProcess[event.target.value],
        isComingSoon: event.target.value? false: true,
        image: ""
      }
    });
    // setProcessNodeDefaultText();

    const newNode = nodes.map((item) => {
      if (item.id === nodeEdit.id) {
        return {
          ...item,
          data: { ...item.data, 
            process: event.target.value,
            header: headerMapForProcess[event.target.value], 
            text: textMapForProcess[event.target.value],
            isComingSoon: event.target.value? false: true,
            image: ""
          }
        };
      }
      return item;
    });
    
   
    setNodes(newNode);
    setIsFlowChanged(true);
  };
  // Node X Handle
  const handleNodeXHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // modify Edge list
    setNodeEdit({
      ...nodeEdit, 
      data: { ...nodeEdit.data, isHandles: event.target.checked }
    });

    const newNode = nodes.map((item) => {
      if (item.id === nodeEdit.id) {
        return {
          ...item, 
          data: { ...item.data, isHandles: event.target.checked }
        };
      }
      return item;
    });
  
    setNodes(newNode);
  };


   // Node Tooltip
   const handleNodeTooltipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // modify Edge list
    setNodeEdit({
      ...nodeEdit, 
      data: { ...nodeEdit.data, isTooltip: event.target.checked }
    });

    const newNode = nodes.map((item) => {
      if (item.id === nodeEdit.id) {
        return {
          ...item, 
          data: { ...item.data, isTooltip: event.target.checked }
        };
      }
      return item;
    });
  
    setNodes(newNode);
  };

  // Node ComingSoon switch
  const handleNodeComingSoonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // modify Edge list
    setNodeEdit({
      ...nodeEdit, 
      data: { ...nodeEdit.data, isComingSoon: event.target.checked }
    });

    const newNode = nodes.map((item) => {
      if (item.id === nodeEdit.id) {
        return {
          ...item, 
          data: { ...item.data, isComingSoon: event.target.checked }
        };
      }
      return item;
    });
  
    setNodes(newNode);
  };


   //  Edge Edit Menu

   // edit menu open
  const [isEdgeEditMenu, setIsEdgeEditMenu] = useState<boolean>(true);

  // Edit button
  // const handleClickEdgeEdit = () => {
  //   // edit button logics
  //   console.log('Edge edit button clicked!');
  //   setIsEdgeEditMenu(!isEdgeEditMenu);
  // };

  // Change Edge data
  // Edge Label
  const handleChangeEdgeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('Changed', edgeEdit, (event.target as HTMLInputElement).value);
    // modify Edge list
    setEdgeEdit({
      ...edgeEdit, label: event.target.value
    });

    const newEdge = edges.map((item) => {
      if (item.id === edgeEdit.id) {
        return {
          ...item, label: event.target.value
        };
      }
      return item;
    });

    setEdges(newEdge);
  };

  // Delete Edge
  const handleClickEdgeDelete = () => {
    // console.log('Edge Delete!');
    if (edgeEdit.id) {
      // Remove the Edge with the corresponding id
      const updatedEdges  = edges.filter(edge => edge.id !== edgeEdit.id);
      setEdges(updatedEdges);
      
      // Close Edit Menu
      setIsEdgeEditMenu(false);
      // Clear the selected Edge from the state
      setEdgeEdit({});
      
    }
  };

  // Edge type
   const handleChangeEdgeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('Changed', edgeEdit, (event.target as HTMLInputElement).value);
    // modify Edge list
    setEdgeEdit({
      ...edgeEdit, type: event.target.value
    });

    const newEdge = edges.map((item) => {
      if (item.id === edgeEdit.id) {
        return {
          ...item, type: event.target.value
        };
      }
      return item;
    });

    setEdges(newEdge);
  };
  
  // Edge animated
  

  const handleEdgeAnimatedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // modify Edge list
    setEdgeEdit({
      ...edgeEdit, animated: event.target.checked 
    });

    const newEdge = edges.map((item) => {
      if (item.id === edgeEdit.id) {
        return {
          ...item, animated: event.target.checked
        };
      }
      return item;
    });

    setEdges(newEdge);

  };

  // Edge Marker Change (mange from arrow buttons)
  const handleEdgeMarkerChange = (type: string) => {
  
     // modify Edge list
    if (type === 'markerEnd') {
      handleEdgeMarkerEndChange();
    } else if (type === 'markerStart') {
      handleEdgeMarkerStartChange();
    }
  };

  // Edge Marker End
  const handleEdgeMarkerEndChange = () => {
    // modify Edge list
   
    // Check if markerEnd exists in edgeEdit
    if (edgeEdit.hasOwnProperty('markerEnd')) {
      // If it exists, remove it
      const { markerEnd, ...updatedEdge } = edgeEdit;
      setEdgeEdit(updatedEdge);
    } else {
      // If it doesn't exist, add it
      setEdgeEdit({
          ...edgeEdit,
          markerEnd: {
              type: MarkerType.Arrow
          }
      });
    }

    // modify Edge list
    const newEdge = edges.map((item) => {
      if (item.id === edgeEdit.id) {
        if (item.hasOwnProperty('markerEnd')) {
          const { markerEnd, ...updatedEdge } = item;
          return updatedEdge;
        } else {
          return {
            ...item, 
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
            }
          };
        }
      }
      
      return item;
      });

    setEdges(newEdge);
  };

  // Edge Marker Start
  const handleEdgeMarkerStartChange = () => {
    // modify Edge list
   
    // Check if marker exists in edgeEdit
    if (edgeEdit.hasOwnProperty('markerStart')) {
      // If it exists, remove it
      const { markerStart, ...updatedEdge } = edgeEdit;
      setEdgeEdit(updatedEdge);
    } else {
      // If it doesn't exist, add it
      setEdgeEdit({
          ...edgeEdit,
          markerStart: {
              type: MarkerType.Arrow
          }
      });
    }

    // modify Edge list
    const newEdge = edges.map((item) => {
      if (item.id === edgeEdit.id) {
        if (item.hasOwnProperty('markerStart')) {
          const { markerStart, ...updatedEdge } = item;
          return updatedEdge;
        } else {
          return {
            ...item, 
            markerStart: {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
            }
          };
        }
      }
      
      return item;
      });

    setEdges(newEdge);
  };

  // Flow chart
  const [nodes, setNodes] = useState<Node[]>(flowParams?.nodes || []);
  const [edges, setEdges] = useState<Edge[]>(flowParams?.edges || []);

  
  //Object to change on click
  // const [nodeEdit, setNodeEdit] = useState({});
  const [nodeEdit, setNodeEdit] = useState<{ [key: string]: any }>({});
  const [edgeEdit, setEdgeEdit] = useState<{ [key: string]: any }>({});

  // background type
  const [variant, setVariant] = useState<string>('');
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVariant((event.target as HTMLInputElement).value);
  };

  
  // Button Add New Node
  const handleAddNode = ( type: string ) => {
    
    // find new ID
    let maxId = '0';

    nodes.forEach(node => {
      if (parseInt(node.id) > parseInt(maxId)) {
        maxId = node.id;
      }
    });
    const nextAvailableId = String(parseInt(maxId) + 1);
    // console.log("maxId=>", maxId);
    
    // get random icon from icon list
    const keys = Object.keys(iconType);
    const randomIconKey = keys[Math.floor(Math.random() * keys.length)];
    
    
    // // Calculate position in the center of screen
   
    const NODE_WIDTH = 200;
    const NODE_HEIGHT = 200;
    let currentOverlapOffset = Math.floor(Math.random() * (50 - 20)) + 20;
    //   // Get the basic info about the viewport
    const {
      height,
      width,
      transform: [transformX, transformY, zoomLevel]
    } = store.getState();
    const zoomMultiplier = 1 / zoomLevel;

    // Figure out the center of the current viewport
    const centerX = -transformX * zoomMultiplier + (width * zoomMultiplier) * 2  / 3;
    const centerY = -transformY * zoomMultiplier + (height * zoomMultiplier) / 3;

    // Add offsets for the height/width of the new node 
    
    const nodeWidthOffset = NODE_WIDTH / 2;
    const nodeHeightOffset = NODE_HEIGHT / 2;
    
    // create new Node
    // Node params depends on type
    
    // Custom Outlined Node
    let newNodeHeader = "New Custom Node";
    let newNodeText = "Click to edit text, tooltip and set process";
    let newNodeProcess: string | boolean  = false;
    let newNodeTooltip = "Tooltip";
    let newNodeIsHandles = true;

    switch (type) {
      // Process
      case 'card':
        newNodeHeader = "New Process Node";
        newNodeText = "Click to set process and edit text, tooltip";
        newNodeProcess = "dailyProgressReport"
        break;
      // Text
      case 'paper':
        newNodeHeader = "New Text Node";
        newNodeText = "Click to edit text";
        newNodeProcess = false;
        newNodeIsHandles = false;
        break;
  };

    const newNode = { 
      id: nextAvailableId, 
      type: type, 
      data: { header: newNodeHeader, 
      text: newNodeText,
      tooltip: newNodeTooltip,
      isTooltip: true,
      icon: randomIconKey, 
      process: newNodeProcess, 
      isHandles: newNodeIsHandles,
      isComingSoon: false}, 
      position: { 
        x: centerX - nodeWidthOffset + currentOverlapOffset, 
        y: centerY - nodeHeightOffset + currentOverlapOffset 
      } 
    }
    // add new Node to Node info
    setNodes(prevNodes => [...prevNodes, newNode]);
    // setNodes((nds) => nds.concat(newNode));
  };

// Button Add New Process Card Node
const handleAddNewProcessNode = ( process: string ) => {

  // find new ID
  let maxId = '0';

  nodes.forEach(node => {
    if (parseInt(node.id) > parseInt(maxId)) {
      maxId = node.id;
    }
  });
  const nextAvailableId = String(parseInt(maxId) + 1);
  // console.log("maxId=>", maxId);
  
  // get random background image from image list
  const keys = Object.keys(backgroundImageLink);
  const randomImage = keys[Math.floor(Math.random() * keys.length)];
  // console.log("image=>", randomImage);
  
  // // Calculate position in the center of screen
 
  const NODE_WIDTH = 200;
  const NODE_HEIGHT = 200;
  let currentOverlapOffset = Math.floor(Math.random() * (50 - 20)) + 20;
  //   // Get the basic info about the viewport
  const {
    height,
    width,
    transform: [transformX, transformY, zoomLevel]
  } = store.getState();
  const zoomMultiplier = 1 / zoomLevel;

  // Figure out the center of the current viewport
  const centerX = -transformX * zoomMultiplier + (width * zoomMultiplier) * 2  / 3;
  const centerY = -transformY * zoomMultiplier + (height * zoomMultiplier) / 3;

  // Add offsets for the height/width of the new node 
  
  const nodeWidthOffset = NODE_WIDTH / 2;
  const nodeHeightOffset = NODE_HEIGHT / 2;
  
  // create new Node
  // Node params depends on type
  
  // Custom Outlined Node
  let type = "card";
  let newNodeHeader = headerMapForProcess[process];
  let newNodeText = textMapForProcess[process];
  let newNodeProcess: string | boolean  = false;
  let newNodeTooltip = tooltipMapForProcess[process];
  let newNodeIsHandles = true;
  let newNodeIsComingSoon = false; //(process==="");
  let newNodeIcon = "";

  const newNode = { 
    id: nextAvailableId, 
    type: type, 
    data: { 
      header: newNodeHeader, 
      text: newNodeText,
      tooltip: newNodeTooltip,
      isTooltip: true,
      icon: newNodeIcon,
      process: process, 
      isHandles: newNodeIsHandles,
      isComingSoon: newNodeIsComingSoon,
      image: randomImage
     },
    
    position: { 
      x: centerX - nodeWidthOffset + currentOverlapOffset, 
      y: centerY - nodeHeightOffset + currentOverlapOffset 
    } 
  }
  // add new Node to Node info
  setNodes(prevNodes => [...prevNodes, newNode]);
  // setNodes((nds) => nds.concat(newNode));
};





// set Process Node Header and Text to Default
const setProcessNodeDefaultText = () => {
  
    // modify Node 
    setNodeEdit({
      ...nodeEdit,
      data: { ...nodeEdit.data,
        header: headerMapForProcess[nodeEdit.data.process], 
        text: textMapForProcess[nodeEdit.data.process],
        tooltip: tooltipMapForProcess[nodeEdit.data.process],
        isTooltip: true
       }
    });

    const newNode = nodes.map((item) => {
      if (item.id === nodeEdit.id) {
        return {
          ...item,
          data: { ...item.data, 
            header: headerMapForProcess[nodeEdit.data.process], 
            text: textMapForProcess[nodeEdit.data.process],
            tooltip: tooltipMapForProcess[nodeEdit.data.process],
            isTooltip: true
          }
        };
      }
      return item;
    });
    
    setNodes(newNode);
  };



  // panOnDrag
  const panOnDrag: number[] = [1, 2];


  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      if (isEditMenu ) { 
        setIsFlowChanged(true);
        setIsSaveAlertOpen(false)}
      setNodes((nds) => applyNodeChanges(changes, nds));
      
    },
    [setNodes, isEditMenu]
  );

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => {
      if (isEditMenu) { 
        setIsFlowChanged(true);
        setIsSaveAlertOpen(false);
      }
      setEdges((eds) => applyEdgeChanges(changes, eds));
      
    },
    [setEdges, isEditMenu]
  );

  const onConnect: OnConnect = useCallback(
    (connection) => {
      if (isEditMenu) {
        setIsFlowChanged(true);
        setIsSaveAlertOpen(false);
        setEdges((eds) => addEdge(connection, eds));
        }
      },
    [setEdges, isEditMenu]
   
  );

  // Flow on elements click 
  // Node click
  const onNodeClick  = (event: React.MouseEvent, node: Node) => {
  
    // if (isEditMenu) { 
      setIsEdgeEditMenu(false);
      setNodeEdit(node);
      setEdgeEdit({});
    // }
  
    if (!isEditMenu) {
      // check if process name set to Node
      if (node.data.process) {
        // test
        if (node.data.process==="test") {
          alert('Test process starts => ' + processMapForFunctions[node.data.process])
          return
        }
        // function to start process form main Dashboard component
        startProcessFromFlow(node.data.process);
      }

    }
    
  };

  // Double click - start functions
  const onNodeDoubleClick  = (event: React.MouseEvent, node: Node)=> {
    // check if not in Edit Flow mode
    if (!isEditMenu) {
      // check if process name set to Node
      if (node.data.process) {
        // test
        if (node.data.process==="test") {
          alert('Test process starts => ' + processMapForFunctions[node.data.process])
          return
        }
      
        // function to start process form main Dashboard component
        startProcessFromFlow(node.data.process);
      }

    }
  };
  // Edge click
  const onEdgeClick  = (event: React.MouseEvent, edge: Edge) => {
    // if (isEditMenu) {  
  
      setEdgeEdit(edge);
      // setIsEdgeEditMenu(false);
      // setIsNodeEditMenu(false);
      setNodeEdit({});
      
    // }
  };  

  // Canvas = click Pane
  const onPaneClick = () => {
    // if (isEditMenu) {
      // setIsNodeEditMenu(false);
      setIsEdgeEditMenu(false);
      setNodeEdit({});
      setEdgeEdit({});

    // }
  };

  return (
 
    <Box height="100vh">
      
      {/* Flow name and edit button */}
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
         
        }}
      >
       
        {/* Flow name  */}
        { !isEditMenu && (
        <Typography
          fontSize={{ xs: "1rem", md: "1.5rem" }}
          color="primary"
          marginBottom={0}
          sx={{
              textTransform: "none",
              textAlign: "left",
          }}
        >
          { flowName } 
        </Typography>
        )}
        { isEditMenu && (
        <TextField 
          id="outlined-basic" 
          // label="Name" 
          variant="standard" 
          size="small"
          color="secondary"  
          inputProps={{
            style: {
              fontSize: isNonMobile?"1.5em":"1em", 
              color: "grey" }
            }} 
          value={flowName}
          onChange ={handleChangeFlowName} 
        />
        )}
        <Box>
          {/* Save (update) spinner */}
          { showSaveSpinner && (
            <IconButton size="small">
              <CircularProgress
              color="primary"
              thickness={5}
              size="1em"
            />
          </IconButton>
          )}
          {/* Save button */}
          { ( isFlowChanged ) && (
          <IconButton  color={"primary"} aria-label="edit" size="small"  onClick={handleClickSave}>
            <SaveOutlined />
          </IconButton >
          )}    
          {/* Edit button */}
          <IconButton  color={"primary"} aria-label="edit" size="small"  onClick={handleClickEdit}>
            {  !showSaveSpinner && (isEditMenu ?  <CloseOutlined /> : <EditOutlined />) }
          </IconButton >
        </Box> 
      </Box>
      {/* Save on Exit Alert Dialog box */}
      <Collapse in={isSaveAlertOpen}>
        <Alert 
          severity="info"
          action={
            <IconButton
              // aria-lafontSizebel="close"
              color="inherit"
              size="small"
              onClick={() => {
                setIsSaveAlertOpen(false);
              }}
            >
              <CloseOutlined fontSize="inherit" />
            </IconButton>
          }
          
          sx={{ mb: 2 }}
        >
          <Box sx={{ display: 'flex', flexDirection : 'row', justifyContent: 'center', gap: 1 }}>
            <Typography
            fontSize={{ xs: "0.9rem", md: "1rem" }}>
              Do you want to save changes?
            </Typography>
            <Box sx={{ mt: 0, display: 'flex', justifyContent: 'flex-end', alignItems:'center', gap: 1 }}>
              <Button 
               variant="contained" 
               size='small' 
               onClick={()=>{ handleClickSave(); }}
              >
                Save
              </Button>
              <Button 
                variant="outlined" 
                size='small' 
                onClick={()=>{ 
                  exitEditMenu(); 
                  setShowAlert(false); 
                  getFlowData();
                }}
              > 
                No
              </Button>
            </Box>
          </Box>
        </Alert>
      </Collapse>
      {/* Save on exit End */}
      
      {/* Edit menu */}
      { isEditMenu && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        {/* Background */}
        {/* <FormControl>
          <FormLabel  id="demo-controlled-radio-buttons-group">Background</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={variant}
            onChange={handleChange}
          >
            <FormControlLabel value="cross" control={<Radio />} label="cross" />
            <FormControlLabel value="dots" control={<Radio />} label="dots" />
            <FormControlLabel value="lines" control={<Radio />} label="lines" />
            <FormControlLabel value="" control={<Radio />} label="blank" />
          </RadioGroup>
        </FormControl> */}

        {/* Add new Node button */}
    
        {/* <Button variant="contained" onClick={()=>{handleAddNode();}}>
          New Node
        </Button> */}
        {/* Edit element header */}
        { ( Object.keys(nodeEdit).length === 0 && Object.keys(edgeEdit).length === 0) && (
        <Typography
          component={'span'}
          variant= {isNonMobile?'h6':'body2'}
          // fontSize="1em"
          // color="primary"
          // marginBottom={1}
        
          sx={{
              textTransform: "none",
              textAlign: "left",
              mt: 1 
          }}
        >
          Click on element to edit
        </Typography>
        )}

        {/* Node Delete and modify menu */}
        { Object.keys(nodeEdit).length !== 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'center'
          
          }}
        >
          <Typography
            component={'span'}
            variant='h6'
            // fontSize="1em"
            // color="primary"
            // marginBottom={1}
          
            sx={{
                textTransform: "none",
                textAlign: "left",
                mt: 1 
            }}
          >
            Node selected
          </Typography>
          <Box  sx={{
                mt: 1 
            }}>
            <IconButton color='warning'  size="small" onClick={()=>{handleClickNodeDelete();}}>
              <DeleteForeverOutlined/>
            </IconButton>
            {/* Open - close edit menu */}
            {/* <IconButton color='primary'  size="small" onClick={()=>{handleClickNodeEdit();}}>
            { isNodeEditMenu ?  <CloseOutlined /> : <EditOutlined /> }
            </IconButton> */}
          </Box>
        </Box>
        )}

      {/* NodeEditMenu menu */}
      {/* { isNodeEditMenu && ( */}
      { Object.keys(nodeEdit).length !== 0 && (
        <Box>
          {/* <Typography
            
            fontSize="1.3em"
            // color="primary"
            // marginBottom={1}
            sx={{
                textTransform: "none",
                textAlign: "left",
            }}
          >
            Edit menu:
          </Typography> */}
          {/* input form */}
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              
              {/* Process handle */}
              { nodeEdit.type !== "paper"  && (
              <Grid item xs={6}>
                {/* <FormControl sx={{ minWidth: 250 }} > */}
                <FormControl fullWidth sx={{ minWidth: 150 }} >
                  <InputLabel id="select-process">Process</InputLabel>
                  <Select
                    size="small"
                    
                    labelId="select-process"
                    value={Object.keys(nodeEdit).length === 0? "" : nodeEdit.data.process}
                    label="Process"
                    onChange={handleChangeNodeProcessSelect}
                  >
                    <MenuItem value=""><em>None</em></MenuItem>
                    { Object.keys(processMapForFunctions).length > 0 && (Object.entries(processMapForFunctions).map(([key, value]) => {
                      return (
                        <MenuItem value={key} key={key}>{value}</MenuItem>
                      );
                      })
                    )}
                  </Select>
                  {/* <FormHelperText>Select icon</FormHelperText>               */}
                </FormControl>

                {/* Button add process */}
                {/* Is shown if process */}
                {/* { (nodeEdit.data.process !== "" && nodeEdit.data.process !== "test" && nodeEdit.data.process in processMapForFunctions) && (
                <Button variant="outlined" sx={{ ml: 2}} onClick={setProcessNodeDefaultText}>
                  Set default description
                </Button>
                )} */}

              </Grid>
              )}

              {/* is Coming Soon switch on off => don't use*/}
              {true && (
              <Grid item xs={6}>
                <FormControlLabel 
                  control={
                  <Switch checked={Object.keys(nodeEdit).length === 0? false :nodeEdit.data.isComingSoon} 
                  onChange={handleNodeComingSoonChange}
                  />} 
                  label="Coming Soon"
                  labelPlacement="start" 
                />
              </Grid>
              )}

              {/* Node Header */}
              <Grid item xs={6}>
                <TextField
                  disabled= {Boolean(nodeEdit.data.process)} // {nodeEdit.data.process !== false || nodeEdit.data.process !== ""} //editable only if no process
                  size="small"
                  fullWidth
                  id="node-header"
                  label="Header"
                  name="nodeheader"
                  autoComplete="header"
                  multiline
                  maxRows={3}
                  value={Object.keys(nodeEdit).length === 0? "" : nodeEdit.data.header}
                  onChange ={handleChangeNodeHeader} // 
                  // onBlur={()=>{}}
                />
              </Grid>
              
              {/* Node comment - text */}
              <Grid item xs={6}>
                <TextField
                  disabled= {Boolean(nodeEdit.data.process)} //{nodeEdit.data.process} //editable only if no process
                  size="small"
                  fullWidth
                  id="node-text"
                  label="Text"
                  name="nodetext"
                  autoComplete="text"
                  multiline
                  maxRows={3}
                  value={Object.keys(nodeEdit).length === 0? "" : nodeEdit.data.text}
                  onChange ={handleChangeNodeText} // 
                  // onBlur={()=>{}}
                />
              </Grid>

              {/* Node comment - tooltip input, hide for Card type */}
              { nodeEdit.type !== "card" && (
              <Grid item xs={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="node-tooltip"
                  label="Tooltip"
                  name="nodetooltip"
                  autoComplete="tooltip"
                  multiline
                  maxRows={3}
                  value={Object.keys(nodeEdit).length === 0? "" : nodeEdit.data.tooltip}
                  onChange ={handleChangeNodeTooltip} // 
                  // onBlur={()=>{}}
                />
              </Grid>
              )}
                
              {/* Tooltip switch on off */}
              {/* <Grid item xs={6}>
                <FormControlLabel control={<Switch checked={Object.keys(nodeEdit).length === 0? false :nodeEdit.data.isTooltip} onChange={handleNodeTooltipChange} />} 
                  label="Show Tooltip"
                  labelPlacement="start" 
                />
              </Grid> */}
            { nodeEdit.type ==="custom"  && (
              <Grid item xs={12}>
              {/* Icon */}
              
                <FormControl sx={{ minWidth: 80 }} >
                  <InputLabel id="select-icon">Icon</InputLabel>
                  <Select
                    size="small"
                    labelId="select-icon"
                    value={Object.keys(nodeEdit).length === 0? "" : nodeEdit.data.icon}
                    label="Icon"
                    onChange={handleChangeNodeIconSelect}
                  >
                    <MenuItem value="0"><em>None</em></MenuItem>
                    { Object.keys(iconType).length > 0 && (Object.entries(iconType).map(([key, value]) => {
                      return (
                        <MenuItem value={key} key={key}>{value}</MenuItem>
                      );
                      })
                    )}
                  </Select>
                  {/* <FormHelperText>Select icon</FormHelperText>               */}
                </FormControl>
                

                {/* Horizontal (X) Handle */}
               
                <FormControlLabel control={<Switch checked={Object.keys(nodeEdit).length === 0? false :nodeEdit.data.isHandles} onChange={handleNodeXHandleChange} />} 
                  label="Connection points"
                  labelPlacement="start" 
                />
                
            </Grid>
            )}

             
           
           
             
               {/* Background Image */}
               { (nodeEdit.type ==="card" && !nodeEdit.data.process) && (
              <Grid item xs={6}>
                <FormControl sx={{ minWidth: 250,  mb: 1 }} >
                  <InputLabel id="select-image">Image</InputLabel>
                  <Select
                    size="small"
                    labelId="select-image"
                    value={Object.keys(nodeEdit).length === 0? "" : nodeEdit.data.image}
                    label="Image"
                    onChange={handleChangeNodeImageSelect}
                  >
                    <MenuItem value="0"><em>None</em></MenuItem>
                    { Object.keys(backgroundImageLink).length > 0 && (Object.entries(backgroundImageLink).map(([key, value]) => {
                      return (
                        <MenuItem value={key} key={key}>{key}</MenuItem>
                      );
                      })
                    )}
                  </Select>
                  {/* <FormHelperText>Select icon</FormHelperText>              */}
                </FormControl>
              </Grid>
              )}


            </Grid>
          </Box>
          
        </Box>
      //  End Node Edit Menu
        )}

       {/* Edge Delete and modify menu */}
       { Object.keys(edgeEdit).length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          
          }}
        >
          <Typography
            component={'span'}
            variant='h6'
            // fontSize="1em"
            // color="primary"
            // marginBottom={1}
          
            sx={{
                textTransform: "none",
                textAlign: "left",
                mt: 1 
            }}
          >
            Edge selected 
          </Typography>
          <Box
            sx={{
              mt: 1 
            }}
          >
            <IconButton color='warning'  size="small" onClick={()=>{handleClickEdgeDelete();}}>
              <DeleteForeverOutlined/>
            </IconButton>
            {/* <IconButton color='primary'  size="small" onClick={()=>{handleClickEdgeEdit();}}>
            { isEdgeEditMenu ?  <CloseOutlined /> : <EditOutlined /> }
            </IconButton> */}
          </Box>
        </Box>
        )}


      {/* EdgeEditMenu menu */}
      {/* { isEdgeEditMenu && ( */}
      { Object.keys(edgeEdit).length !== 0 && (
        <Box>
          {/* <Typography
            
            fontSize="1.3em"
            // color="primary"
            // marginBottom={1}
            sx={{
                textTransform: "none",
                textAlign: "left",
            }}
          >
            Edit menu:
          </Typography> */}
          {/* Edge label form */}
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              {/* Edge Lable */}
              <Grid item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  id="edge-label"
                  label="Edge label"
                  name="edgelabel"
                  autoComplete="label"
                  multiline
                  maxRows={3}
                  value={edgeEdit.label}
                  onChange ={handleChangeEdgeLabel} // 
                  // onBlur={()=>{}}
                />
              </Grid>
              
               {/* Edge type */}
               <Grid item xs={12}>
                <FormControl>
                  <FormLabel  id="edge-controlled-radio-buttons-group">Line type</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="edge-controlled-radio-buttons-group"
                    name="edge-radio-buttons-group"
                    value={edgeEdit.type}
                    onChange={handleChangeEdgeType}
                  >
                    <FormControlLabel value="default" control={<Radio />} label="default" />
                    <FormControlLabel value="straight" control={<Radio />} label="straight" />
                    <FormControlLabel value="step" control={<Radio />} label="step" />
                    <FormControlLabel value="smoothstep" control={<Radio />} label="smoothstep" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* Edge Animated */}
              <Grid item xs={12}>
              <FormControlLabel control={<Switch checked={edgeEdit.animated} onChange={handleEdgeAnimatedChange} />} 
                label="animated"
                labelPlacement="end" 
              />
              
              {/* Switch arrow  variant */}
              {/* Edge Arrow End */}
              {/* <FormControlLabel control={<Switch checked={edgeEdit.hasOwnProperty('markerEnd')} onChange={handleEdgeMarkerEndChange} />} 
                label="arrow end"
                labelPlacement="end" 
              /> */}

               {/* Edge Arrow Start */}
               {/* <FormControlLabel control={<Switch checked={edgeEdit.hasOwnProperty('markerStart')} onChange={handleEdgeMarkerStartChange} />} 
                label="arrow start"
                labelPlacement="end" 
              /> */}

              {/* Buttons arrow  variant */}
                <IconButton size="small" onClick={()=>{handleEdgeMarkerChange("markerStart");}}>
                  <WestOutlined color={edgeEdit.hasOwnProperty('markerStart') ?'primary': 'inherit'} />
                </IconButton>
                <IconButton size="small" onClick={()=>{handleEdgeMarkerChange("markerEnd");}}>
                  <EastOutlined color={edgeEdit.hasOwnProperty('markerEnd') ?'primary': 'inherit'} />
                </IconButton> 
              
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      </Box>
      )}
      <Paper elevation={1}>
        <Box 
          height={
            isEditMenu
            ?(
              (Object.keys(nodeEdit).length !== 0 || Object.keys(edgeEdit).length !== 0)
              ? "75vh"
              :"95vh"
            )
            : "100vh"
          }
          // sx={{
          //   border:"1px solid lightgrey";
          //   bo
          // }}
        >
        
        {/* Flow chart */}
          <ReactFlow 
          // context menu
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            // fix nodes and edges if menu closed
            nodesDraggable = {isEditMenu}
            nodesConnectable = {isEditMenu}
            fitView
            fitViewOptions={fitViewOptions}
            defaultEdgeOptions={defaultEdgeOptions}
            nodeTypes={nodeTypes}
            panOnScroll={false}
            // click on element
            onNodeClick ={onNodeClick} // set Node to modify
            onNodeDrag={onNodeClick} // set Node to modify
            // onNodeDoubleClick={onNodeDoubleClick}
            // on Edge click
            onEdgeClick={onEdgeClick}  // set Node to modify
          
            onPaneClick={onPaneClick}
            // Zoom on mouse scroll
            zoomOnScroll={isEditMenu} //{true}
            zoomOnDoubleClick={false}
            // move canvas on drag
            panOnDrag={true} //{isEditMenu}
            selectionOnDrag={false}
          
            selectionMode={SelectionMode.Partial}
            deleteKeyCode={isEditMenu? 'Backspace' : null}
            onInit={setRfInstance}
            // style={flowStyles}
            
          >
            <Background variant={variant as BackgroundVariant} />
            {/* Controls on flow canvas in Edit Menu */}
            { isEditMenu && (
            <Controls
            position = {'top-left'}
            />
            )}
            {/* Controls with limited buttons */}
            { !isEditMenu && (
            <Controls
              showZoom = {true}
              showInteractive = {false}
              position = {'top-left'}
            />
            )}
            { isEditMenu && (
              // Add new node component
            <Panel position="top-right">
              {/* <AddNewNode handleAddNode={ handleAddNode } /> */}
              <AddNewProcessCard handleAddNewProcessNode={handleAddNewProcessNode} />
              {/* Add button old */}
              {/* <Tooltip title="Add Process" placement="left-start" enterDelay={500}>
                <Button variant="contained" onClick={()=>{handleAddNode("card");}}>
                  <AddBoxOutlined />
                </Button> 
              </Tooltip> */}
            </Panel>
            )}
          </ReactFlow>  
        
        </Box>
      </Paper>
    </Box>
    
  );
}


// wrapping with ReactFlowProvider is done outside of the component
const FlowEditor: React.FC<Props> = ({setShowAlert, setAlertMessage, flowParams, setFlowParams, startProcessFromFlow, updatedFlowAndSave,showSaveSpinner,setShowSaveSpinner, showAlert, isFlowChanged, setIsFlowChanged, getFlowData }) => {
  
 


  return (

    <ReactFlowProvider>
      <FlowProcess 
        showAlert={showAlert}
        setShowAlert={setShowAlert} 
        setAlertMessage={setAlertMessage} 
        flowParams={flowParams} 
        setFlowParams={setFlowParams} 
        startProcessFromFlow={startProcessFromFlow}
        updatedFlowAndSave={updatedFlowAndSave}
        showSaveSpinner={showSaveSpinner} 
        setShowSaveSpinner={setShowSaveSpinner}
        isFlowChanged={isFlowChanged}
        setIsFlowChanged={setIsFlowChanged}
        getFlowData={getFlowData}
      />
    </ReactFlowProvider> 

  );
}

export default FlowEditor;
