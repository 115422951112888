import { useContext, useState, Dispatch, SetStateAction } from "react";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Person as PersonIcon, Email as EmailIcon } from "@mui/icons-material";

import axios from "../../utils/AxiosInstance";
import { AxiosError } from "axios";

import { AuthContext } from "../../contexts/AuthContext";
import { IAuthContext } from "../../models/IAuthContext";

import { IUserProfile } from "../../models/IUserProfile";

import { logUserIn } from "../../utils/logUserIn";
import { MixpanelContext } from "../../contexts/MixpanelContext";

const FieldSection = ({
    field,
    existingValue,
    setUserProfileData,
}: {
    field: string;
    existingValue: string;
    setUserProfileData?: Dispatch<SetStateAction<IUserProfile | null>>;
}) => {
    const [editableValue, setEditableValue] = useState<string>(existingValue);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>("");

    const authContext = useContext(AuthContext);
    const { setActiveUser } = authContext as IAuthContext;

    const {sendAnalyticsEvent} = useContext(MixpanelContext);

    const saveUpdatedValue = async () => {
        setShowAlert(false);
        setAlertMessage("");
        setIsSaving(true);
        sendAnalyticsEvent('SaveProfileChanges', {
          eventCategory: 'User Interaction',
          action: 'Save Profile Changes',
        })
        try {
            const res = await axios.patch(
                "/store/update-profile",
                { field: field, value: editableValue.trim() },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                }
            );
            const { renewedToken, updatedValue, updatedUser } = await res.data;
            // Reissue auth token and reset Auth Context state if user changes their name
            logUserIn(
                renewedToken,
                updatedUser.name.split(" ")[0],
                updatedUser.is_admin,
                setActiveUser
            );
            setEditableValue(updatedValue);
            // If user edited name, update higher state so the header updates with the new name
            if (field === "name" && setUserProfileData)
                setUserProfileData(prevState => ({
                    ...prevState!,
                    [field]: updatedUser.name,
                    is_admin: updatedUser.is_admin,
                }));
            setIsEditing(false);
        } catch (err) {
            console.error(err);
            if (err instanceof AxiosError && err.response) {
                setAlertMessage(err.response.data);
            } else {
                setAlertMessage(
                    "Sorry, there was a problem updating your profile. Are you offline?"
                );
            }
            setShowAlert(true);
        } finally {
            setIsSaving(false);
        }
    };

      // Determine which icon to render based on the field name
    let iconToRender = null;
    if (field === "name") {
        iconToRender = <PersonIcon fontSize="small" style={{color: "rgb(117, 117, 117)"}}/>;
    } else if (field === "email") {
        iconToRender = <EmailIcon fontSize="small" style={{color: "rgb(117, 117, 117)"}}/>;
    }

    return (
      <Stack paddingY={2} maxWidth={{ md: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius: '50%',
              border: '2px solid rgb(117, 117, 117)',
              width: '32px',
              height: '32px',
              margin: '0.5rem',
            }}
          >
            {iconToRender}
          </Box>

          <Stack direction="row" alignItems="center">
            <Stack direction="row" alignItems="center">
              <Typography variant="h6" marginRight={1}>
                {field[0].toUpperCase() + field.substring(1) + ':'}
              </Typography>
            </Stack>
            {isEditing ? (
              <Stack>
                <Stack direction="row" alignItems="center">
                  <TextField
                    id={field}
                    type={field === 'email' ? 'email' : 'text'}
                    value={editableValue}
                    onChange={(e) => {
                      setShowAlert(false)
                      setEditableValue(e.target.value)
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && editableValue.trim().length)
                        saveUpdatedValue()
                    }}
                    disabled={isSaving}
                    size="small"
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    onClick={saveUpdatedValue}
                    disabled={isSaving || !editableValue.trim().length}
                    sx={{
                      height: '100%',
                      width: '30px',
                      marginLeft: 1,
                    }}
                  >
                    {isSaving ? <CircularProgress size={25} /> : 'Save'}
                  </Button>
                </Stack>
                {showAlert && (
                  <Alert severity="error" sx={{ marginTop: 1 }}>
                    {alertMessage}
                  </Alert>
                )}
              </Stack>
            ) : (
              <Typography color="primary" variant="body1">
                {editableValue}
              </Typography>
            )}
            {!isEditing && (
              <IconButton
                onClick={() => {
                  setIsEditing(true)
                  sendAnalyticsEvent('EditProfile', {
                    eventCategory: 'User Interaction',
                    action: 'Edit Profile',
                  })
                }}
              >
                <Edit />
              </IconButton>
            )}
          </Stack>
        </Box>
      </Stack>
    )
};

export default FieldSection;
