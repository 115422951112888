import { Dispatch, SetStateAction } from "react";
import { Stack, Typography } from "@mui/material";

import PhotoNameHeader from "./PhotoNameHeader";
import FieldSection from "./FieldSection";
import PasswordChange from "./PasswordChange";

import { IUserProfile } from "../../models/IUserProfile";

const ProfileDisplay = ({
    userProfileData,
    setUserProfileData,
}: {
    userProfileData: IUserProfile;
    setUserProfileData: Dispatch<SetStateAction<IUserProfile | null>>;
}) => {
    return (
        <Stack width={{ xs: "100%", md: "100%" }}>
            <PhotoNameHeader
                name={userProfileData.name}
                isAdmin={userProfileData.is_admin}
            />
            <Stack
                width={{ xs: "95%", md: "50%" }}
                marginX="auto"
                marginTop={3}
                marginBottom={2}
                >
                <Typography variant="h5" color="primary">
                    Overview
                </Typography>
                <FieldSection
                    field="name"
                    existingValue={userProfileData.name}
                    setUserProfileData={setUserProfileData}
                />
                <FieldSection field="email" existingValue={userProfileData.email} />
                <PasswordChange />
            </Stack>
        </Stack>
    );
};

export default ProfileDisplay;
