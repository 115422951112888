import { Box, Stack, Typography, Avatar } from "@mui/material";
import GetInitials from "./GetInitials";

const PhotoNameHeader = ({
  name,
  isAdmin,
}: {
  name: string;
  isAdmin: boolean;
}) => {
  return (
    <Stack padding={5} alignItems="center" bgcolor="#03a7ff">
      <Avatar sx={{ bgcolor: "rgb(56, 116, 203)", width: 60, height: 60, marginBottom: "1rem", fontSize: "2rem"}}>
        <GetInitials name={name} />
      </Avatar>
      <Typography variant="h4" color="white"><strong>{name}</strong></Typography>
      {isAdmin && (
        <Box marginTop={2} padding={1} sx={{ backgroundColor: "#03a7ff" }}>
          <Typography color="white">Admin</Typography>
        </Box>
      )}
    </Stack>
  );
};

export default PhotoNameHeader;