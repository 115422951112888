import { useParams, Link, useNavigate } from 'react-router-dom'
import GetInitials from "./GetInitials";
import {
    Avatar,
    Box,
    TextField,
    Button,
    Stack,
    Typography,
    Tooltip,
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton
} from '@mui/material'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useState, useEffect, useContext } from 'react'
import axios from "../../utils/AxiosInstance";
import { AuthContext } from "../../contexts/AuthContext";
import { AxiosError } from 'axios'
import Header from '../global/Header';
import { MixpanelContext } from '../../contexts/MixpanelContext';

type Params = {
    workspaceId: string;
    workspaceName: string;
}

interface User {
    id: string;
    name: string;
    email: string;
}

const WorkspacePageNew = () => {
    const { workspaceId, workspaceName } = useParams<Params>()

    const [userToAdd, setUserToAdd] = useState('')
    const [addingUser, setAddingUser] = useState<boolean>(false)
    const [nameToChange, setNameToChange] = useState(workspaceName)
    const [renaming, setRenaming] = useState<boolean>(false)
    const [resultMessage, setResultMessage] = useState('')
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    // const [showSpinnerSmall, setShowSpinnerSmall] = useState<boolean>(false);
    const [userList, setUserList] = useState<User[]>([])
    const authContext = useContext(AuthContext);
    const { isLoggedIn } = authContext!;
    const { sendAnalyticsEvent } = useContext(MixpanelContext);
    const navigate = useNavigate()

    useEffect(() => {
        if (isLoggedIn) {
            document.title = `${workspaceName} | Editing`
            const fetchUsers = async () => {
                try {
                    setShowSpinner(true)
                    setAddingUser(false)
                    setRenaming(false)
                    const response = await axios.post("/workspace/get-users",
                        {
                            workspace_id: workspaceId,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                            },
                        });
                    setUserList(response.data as User[]);
                    console.log(response.data);
                } catch (err) {
                    console.error(err);
                    if (err instanceof AxiosError && err.response) {
                        console.log(err.response.data);
                    }
                } finally {
                    setShowSpinner(false)
                }
            };
            fetchUsers();
        }
    }, [resultMessage]);

    const handleChangeAdd = (e: any) => {
        if (e.target.value.trim() == '' && addingUser) {
            setAddingUser(false)
        }
        else if (!addingUser) {
            setAddingUser(true)
        }
        setUserToAdd(e.target.value)
    }

    const handleChangeName = (e: any) => {
        if (e.target.value.trim() == '' && renaming) {
            setRenaming(false)
        }
        else if (!renaming) {
            setRenaming(true)
        }
        setNameToChange(e.target.value)
    }

    const deleteWorkspace = async (id: any) => {
        try {
            const res = await axios.post(
                '/workspace-delete',
                {
                    workspace_id: id,
                }
            )
            navigate("/dashboard")
        } catch (err) {
            console.error(err);
            if (err instanceof AxiosError && err.response) {
                console.log(err.response.data);
            }
        }
    }

    const handleSubmitUser = async () => {
        try {
            setShowSpinner(true)
            const res = await axios.post(
                "/workspace/add-user-to-workspace",
                {
                    email: userToAdd,
                    workspace_id: workspaceId
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                }
            );
            setResultMessage(res.data)
        }
        catch (err) {
            if (err instanceof AxiosError && err.response) {
                console.log(err.response.data);
            }
        }
        finally {
            setShowSpinner(false)
        }
        sendAnalyticsEvent('Workspace', {
            eventCategory: 'User Interaction',
            action: 'Add User to Workspace',
        })
    }

    const handleSubmitUserRemove = async (email: string) => {
        try {
            setShowSpinner(true)
            const res = await axios.post(
                "/workspace/remove-user",
                {
                    email: email,
                    workspace_id: workspaceId
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                }
            );
            console.log(res.data)
            setResultMessage(res.data)
        }
        catch (err) {
            if (err instanceof AxiosError && err.response) {
                console.log(err.response.data);
            }
        }
        finally {
            setShowSpinner(false)
        }
    }

    const handleSubmitName = async () => {
        try {
            setShowSpinner(true)
            const res = await axios.post("/workspace-rename",
                {
                    workspace_id: workspaceId,
                    new_name: nameToChange,
                })
            setResultMessage(res.data)
        }
        catch (err) {
            if (err instanceof AxiosError && err.response) {
                console.log(err.response.data);
            }
        }
        finally {
            setShowSpinner(false)
        }
        sendAnalyticsEvent('Workspace', {
            eventCategory: 'User Interaction',
            action: 'Rename Workspace',
        })
    }

    return (
        <div className="page">
            <Header />
            <Stack
                width={{ xs: "100%", md: "100%" }}
                marginX="auto"
            >
                <Stack
                    height="100%"
                    justifyContent={showSpinner ? "center" : "flex-start"}
                    alignItems={showSpinner ? "center" : "flex-start"}
                >
                    {showSpinner ? <CircularProgress color="primary" size={100} /> : (
                        <>
                            <Stack padding={5} alignItems="center" bgcolor="#03a7ff" width={{ xs: "100%", md: "100%" }}>
                                <Avatar sx={{ bgcolor: "rgb(56, 116, 203)", width: 60, height: 60, marginBottom: "1rem", fontSize: "2rem" }}>
                                    <GetInitials name={workspaceName!} />
                                </Avatar>
                                <Typography variant="h4" color="white"><strong>{workspaceName}</strong></Typography>
                            </Stack>
                            <Stack sx={{ direction: { xs: 'column', sm: 'row', md: 'row', lg: 'row' } }} alignItems="center" width="100%" maxWidth="100%">
                                <Stack direction="column" alignItems="center" paddingY={2} paddingX={1} flexWrap={"wrap"} width={{ xs: "100%", sm: '66%', md: "50%", lg: "33%" }} maxWidth={{ md: '100%' }}>
                                    <Box marginY={1.5} width="100%">
                                        <TextField
                                            value={nameToChange}
                                            type='text'
                                            onChange={handleChangeName}
                                            placeholder="Change workspace name"
                                            variant="filled"
                                            label="Workspace name"
                                            size="small"
                                            fullWidth>
                                        </TextField>
                                    </Box>
                                    {renaming && (
                                        <Box marginY={1} width="100%">
                                            <Button
                                                variant="contained"
                                                onClick={handleSubmitName}
                                                fullWidth
                                            >Rename
                                            </Button>
                                        </Box>
                                    )}
                                    <Box marginY={1.5} width="100%">
                                        <Tooltip title="Enter the email of user you want to add to this workspace">
                                            <TextField
                                                value={userToAdd}
                                                type='email'
                                                onChange={handleChangeAdd}
                                                placeholder="Enter user's email"
                                                variant="filled"
                                                label="Add user"
                                                size="small"
                                                fullWidth>
                                            </TextField>
                                        </Tooltip>
                                    </Box>
                                    {addingUser && (
                                        <Box marginY={1} width="100%">
                                            <Button
                                                variant="contained"
                                                onClick={handleSubmitUser}
                                                fullWidth
                                            >Add
                                            </Button>
                                        </Box>
                                    )}
                                </Stack>
                                <Stack paddingY={2} paddingX={1} width={{ xs: "100%", sm: '66%', md: "50%", lg: "33%" }} maxWidth={{ md: '100%' }}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableCell>#</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell></TableCell>
                                            </TableHead>
                                            <TableBody>
                                                {userList.map((user, index) => (
                                                    <TableRow key={user.id}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{user.name}</TableCell>
                                                        <TableCell>{user.email}</TableCell>
                                                        <TableCell>
                                                            <Tooltip title="Remove user from workspace">
                                                                <IconButton color="error" onClick={() => {
                                                                    sendAnalyticsEvent(
                                                                        'Workspace',
                                                                        {
                                                                            eventCategory:
                                                                                'User Interaction',
                                                                            action:
                                                                                'Remove User from Workspace',
                                                                        }
                                                                    )
                                                                    handleSubmitUserRemove(user.email)
                                                                }}>
                                                                    <RemoveCircleIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box marginY={1.5} width='100%'>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            fullWidth
                                            onClick={() => {
                                                sendAnalyticsEvent('Workspace', {
                                                    eventCategory: 'User Interaction',
                                                    action: 'Delete Workspace',
                                                })
                                                deleteWorkspace(workspaceId)
                                            }}>Delete workspace</Button>
                                    </Box>
                                    <Box marginY={1.5} width='100%'>
                                        <Typography>{resultMessage}</Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Stack>
        </div>
    )
}

export default WorkspacePageNew