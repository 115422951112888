export const isDescendant = (tree, ancestorId, descendantId) => {
    let isFound = false;

    function dfs(node) {
        // If the node is not defined or we already found it, stop searching.
        if (!node || isFound) return;

        // If the current node's stepId matches the ancestorId, 
        // check its children for the descendantId.
        if (node.stepId === ancestorId) {
            isFound = searchChildren(node, descendantId);
            return;
        }

        if (node.children) {
            for (let child of node.children) {
                dfs(child);
            }
        }
    }

    function searchChildren(node, id) {
        if (node.stepId === id) return true;

        if (node.children) {
            for (let child of node.children) {
                if (searchChildren(child, id)) return true;
            }
        }

        return false;
    }

    dfs(tree[0]);  // The input seems to always be a single root node inside an array.
    return isFound;
}
