import { Stack } from '@mui/material';

import PostSignupForm from './PostSignupForm';
import PostSignupHero from './PostSignupHero';


const PostSignupContent = () => {
    return (
        <Stack direction="row">
            <PostSignupForm />
            <PostSignupHero />
        </Stack>
    );
};

export default PostSignupContent;