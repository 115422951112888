import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { TreeItem } from "@mui/x-tree-view";

const TreeNode = ({
    nodes,
    depth = 0,
    setStep,
    currentStep,
    treeData,
    itemPosition,
}) => {
    const authContext = useContext(AuthContext);
    const { activeUser } = authContext;

    const [setNode, setSetNode] = useState(false);

    useEffect(() => {
        setSetNode(currentStep?.name === nodes?.name);
    }, [currentStep]);

    return (
        <TreeItem
            label={nodes.name}
            nodeId={nodes.stepId}
            classes={{
                content: `unique_node_${nodes.stepId}`,
            }}
            onClick={() => {
                if (activeUser.isAdmin) setStep(nodes); // Admins can skip steps by clicking on list items
            }}
            sx={{

                paddingLeft:1,
                "&>.MuiTreeItem-content": {
                    backgroundColor: setNode
                        ? "#dedede !important"
                        : "transparent  !important ",
                    // marginTop: `${itemPosition.yDistance}px`,
                    // marginBottom: `${itemPosition.yDistance}px`,
                    paddingTop: `${itemPosition.yPadding}px`,
                    paddingBottom: `${itemPosition.yPadding}px`,
                    cursor: nodes?.children?.length > 0 ? 'pointer': 'default'   ,
                },
                [`& .unique_node_${nodes.stepId}`]: {
                    paddingLeft: `${depth * itemPosition.offset}px`,
                },
                // This setting is necessary to prevent collapsing of the margins of adjacent menu items from
                "& .MuiTreeItem-root": {
                    paddingBottom: "1px",
                    
                },
            }}
            className={
                (setNode ? "SELECT_TestClassTest " : "No-Select ") +
                "unidu_node_" +
                nodes.stepId
            }
            data-testid={"for-test-" + nodes.stepId}
        >
            {Array.isArray(nodes.children) ? (
                <div>
                    {nodes.children.map(node => (
                        <TreeNode
                            currentStep={currentStep}
                            setStep={setStep}
                            treeData={treeData}
                            key={node.name}
                            nodes={node}
                            depth={depth + 1}
                            itemPosition={itemPosition}
                        />
                    ))}
                </div>
            ) : null}
        </TreeItem>
    );
};
export default TreeNode;
