import { useState, useContext } from "react";
import axios from "../../utils/AxiosInstance";
import { AxiosError } from "axios";
import {
    Alert,
    AlertColor,
    Button,
    CircularProgress,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import PasswordStrengthBar from "react-password-strength-bar";
import { MixpanelContext } from "../../contexts/MixpanelContext";

const PasswordChange = () => {
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [passwordMatchError, setPasswordMatchError] =
        useState<boolean>(false);
    const [passwordMatchErrorMessage, setPasswordMatchErrorMessage] =
        useState<string>("");
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertSeverity, setAlertSeverity] = useState<AlertColor>();
    const [alertMessage, setAlertMessage] = useState<string>("");
    const {sendAnalyticsEvent} = useContext(MixpanelContext);

    const saveUpdatedPassword = async () => {
        setShowAlert(false);
        setAlertSeverity(undefined);
        setAlertMessage("");
        setIsSaving(true);
        try {
            await axios.patch(
                "/auth/update-password",
                { currentPassword, newPassword },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                }
            );
            setAlertSeverity("success");
            setAlertMessage("Password changed successfully");
            setShowAlert(true);
            setCurrentPassword("");
            setNewPassword("");
            setConfirmNewPassword("");
            sendAnalyticsEvent('PasswordChangeSuccess', {
              eventCategory: 'User Interaction',
              action: 'Password Change Success',
            })
        } catch (err) {
            sendAnalyticsEvent('PasswordChangeFailed', {
              eventCategory: 'User Interaction',
              action: 'Password Change Failed',
            })
            console.error(err);
            setAlertSeverity("error");
            if (err instanceof AxiosError && err.response) {
                setAlertMessage(err.response.data);
            } else {
                setAlertMessage(
                    "Sorry, there was a problem changing your password. Are you offline?"
                );
            }
            setShowAlert(true);
        } finally {
            setIsSaving(false);
        }
    };

    const checkPasswordsMatch = () => {
        if (newPassword !== confirmNewPassword) {
            setPasswordMatchError(true);
            setPasswordMatchErrorMessage("Passwords do not match");
        } else {
            saveUpdatedPassword();
        }
        sendAnalyticsEvent('PasswordChangeAttempt', {
          eventCategory: 'User Interaction',
          action: 'Password Change Attempt',
        })
    };

    return (
        <Stack paddingY={2} maxWidth={{ md: "300px" }}>
            <Typography variant="h5" color="primary">
                Change Password
            </Typography>
            <TextField
                type="password"
                value={currentPassword}
                placeholder="Current Password"
                onChange={e => {
                    setShowAlert(false);
                    setCurrentPassword(e.target.value);
                }}
                onKeyDown={e => {
                    if (e.key === "Enter") checkPasswordsMatch();
                }}
                size="small"
                margin="dense"
            />
            <TextField
                type="password"
                value={newPassword}
                placeholder="New Password"
                onChange={e => {
                    setPasswordMatchError(false);
                    setPasswordMatchErrorMessage("");
                    setShowAlert(false);
                    setNewPassword(e.target.value);
                }}
                onKeyDown={e => {
                    if (e.key === "Enter") checkPasswordsMatch();
                }}
                disabled={!currentPassword}
                error={passwordMatchError}
                size="small"
                margin="dense"
            />
            {currentPassword && newPassword && (
                <PasswordStrengthBar
                    password={newPassword}
                    minLength={8}
                    scoreWords={["Weak", "Weak", "Okay", "Good", "Strong"]}
                    shortScoreWord="Too Short"
                    style={{
                        fontFamily: "'Roboto', Arial, Helvetica, sans-serif",
                    }}
                />
            )}
            <TextField
                type="password"
                value={confirmNewPassword}
                placeholder="Confirm New Password"
                onChange={e => {
                    setPasswordMatchError(false);
                    setPasswordMatchErrorMessage("");
                    setShowAlert(false);
                    setConfirmNewPassword(e.target.value);
                }}
                onKeyDown={e => {
                    if (e.key === "Enter") checkPasswordsMatch();
                }}
                disabled={newPassword.length < 8}
                error={passwordMatchError}
                helperText={passwordMatchErrorMessage}
                size="small"
                margin="dense"
            />
            {showAlert && (
                <Alert
                    severity={alertSeverity}
                    sx={{ marginY: 1, maxWidth: "100%" }}
                >
                    {alertMessage}
                </Alert>
            )}
            {currentPassword && newPassword && confirmNewPassword && (
                <Button
                    variant="contained"
                    onClick={checkPasswordsMatch}
                    disabled={isSaving}
                    sx={{ marginTop: 1 }}
                >
                    {isSaving ? <CircularProgress size={25} /> : "Save"}
                </Button>
            )}
        </Stack>
    );
};

export default PasswordChange;
