import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ProcessContext } from "../contexts/ProcessContext";
import axios from "../utils/AxiosInstance";

import SideDrawer from "../components/Process/SideDrawer";
import TreeChat from "../components/Process/TreeChat";

import { Alert, Box, Skeleton, Stack } from "@mui/material";
import { useWindowWidthGreaterThan } from "../hooks/useWindowWidthGreaterThan";
// import { findNextStep } from "../utils/findNextStep";

import Header from "../components/global/Header";

const Process = () => {
  const location = useLocation();
  let processData;

  if (location?.state?.processType) {
    processData = location.state.processType;
  }

  const processContext = useContext(ProcessContext);
  const {
    setMobileOpen,
    step,
    setStep,
    processId,
    setProcessId,
    treeData,
    setTreeData,
    setMessages,
    setShowNextButton,
  } = processContext;

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setMobileOpen(false);
    setStep();
    setProcessId("");
    setTreeData();
    setMessages([]);
    setShowNextButton(false);
    return () => {
      // Force context state reset on unmount to prevent random repopulation bug
      setMobileOpen(false);
      setStep();
      setProcessId("");
      setTreeData();
      setMessages([]);
      setShowNextButton(false);
    };
  }, [
    location,
    setMessages,
    setMobileOpen,
    setProcessId,
    setShowNextButton,
    setStep,
    setTreeData,
  ]);

  const startProcess = useCallback(async () => {
    const url = new URL(document.location);
    const params = new URLSearchParams(document.location.search);
    let parentProcessIds = params.get("parentProcessIds");
    if (parentProcessIds) parentProcessIds = parentProcessIds.split(" ");
    const splitPathname = url.pathname.split("/");
    const rawProcessType = splitPathname[splitPathname.length - 1];
    let formattedProcessType = rawProcessType;
    switch (rawProcessType) {
      case "plan-a-user-story":
        formattedProcessType = "Plan a User Story";
        break;
      case "break-user-story-into-tasks":
        formattedProcessType = "Break a User Story Into Tasks";
        break;
      case "plan-a-task":
        formattedProcessType = "Plan a Task";
        break;
      case "daily-progress-report":
        formattedProcessType = "Create a Daily Progress Report";
        break;
      case "general-chat":
        formattedProcessType = "General Chat";
        break;
      case "new-process":
        formattedProcessType = "Create a New Process";
        break;
      default:
        formattedProcessType = processData?.label
          ? processData.label
          : "Plan a User Story";
    }

    document.title = `${formattedProcessType} | Consultant.AI`;

    try {
      let result;
      if (parentProcessIds) {
        result = await (
          await axios.post(
            `/start-process-with-context/${formattedProcessType}`,
            parentProcessIds
          )
        ).data;
      } else if (processData) {
        result = await (
          await axios.post(
            `/start-process/${formattedProcessType}`,
            processData
          )
        ).data;
      } else {
        result = await (
          await axios.get(`/start-process/${formattedProcessType}`)
        ).data;
      }
      if (result && result.process_id) {
        setProcessId(result.process_id);
      }
      if (result && result.outline) {
        setTreeData([result.outline]);
        setStep(result.outline);
      }
    } catch (err) {
      console.error(err);
      setShowAlert(true);
    }
  }, [setProcessId, setStep, setTreeData]);

  const preprocessStep = useCallback(
    async (step) => {
      try {
        await axios.get("/preprocess-step/" + processId + "/" + step.stepId);
      } catch (err) {
        console.error(err);
      }
    },
    [processId]
  );

  const dispatchUserInput = async (newMessage) => {
    const result = await (
      await axios.post("/user-input/" + processId + "/" + step.stepId, {
        user_input: newMessage,
      })
    ).data;
    if (result) {
      setTreeData([result]);
      await preprocessStep(step);
    }
  };

  const generateStep = useCallback(
    async (step) => {
      try {
        const result = (
          await axios.get("/generate-step/" + processId + "/" + step.stepId)
        ).data;
        if (result) {
          setTreeData([result]);
          await preprocessStep(step);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [processId, preprocessStep, setTreeData]
  );

  useEffect(() => {
    if (!step?.isFirst && step) {
      setShowNextButton(false);
      setMessages([]);
      if (!(step?.messages?.length > 0)) {
        generateStep(step);
      }
    }
    // eslint-disable-next-line
  }, [step, generateStep, preprocessStep, setMessages, setShowNextButton]);

  const handleSendMessage = async (newMessage) => {
    dispatchUserInput(newMessage);
  };

  useEffect(() => {
    startProcess();
  }, [location]);

  return (
    <Box sx={{ display: "flex" }}>
      <Header />
      <SideDrawer />
      <Box
        width="100%"
        marginLeft={{
          md: useWindowWidthGreaterThan(600) ? "240px" : 0,
        }}
        display="flex"
        justifyContent="center"
        sx={{ backgroundColor: "#f7f7f7" }}
      >
        <Box
          component="main"
          sx={{
            width: { xs: "100%", md: "70vw" },
            maxWidth: "900px",
            marginTop: "50px",
          }}
        >
          {treeData ? (
            treeData.map((item) => (
              <TreeChat
                currentStep={step}
                setStep={setStep}
                key={item.name}
                treeData={treeData}
                nodes={item}
                // nextStep={nextStep}
                handleSendMessage={handleSendMessage}
              />
            ))
          ) : showAlert ? (
            <Alert severity="error">
              Sorry, there was a problem launching the chat. Are you offline?
            </Alert>
          ) : (
            <Stack
              justifyContent="space-between"
              height="calc(100vh - 50px)"
              paddingX={3}
              backgroundColor="white"
            >
              <Stack
                direction="row"
                alignItems="flex-start"
                width="100%"
                marginTop={2}
              >
                <Skeleton
                  variant="circular"
                  animation="wave"
                  width="25px"
                  height="25px"
                  sx={{ marginRight: 1 }}
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="calc(100% - 25px)"
                  height="100px"
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="fles-start"
                width="100%"
                marginY={2}
              >
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="calc(100% - 75px)"
                  height="50px"
                  sx={{ marginRight: 1 }}
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="75px"
                  height="50px"
                  sx={{ marginRight: 1 }}
                />
              </Stack>
            </Stack>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Process;
