import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProcessContext } from '../../contexts/ProcessContext'
import { AuthContext } from '../../contexts/AuthContext'
import axios from '../../utils/AxiosInstance'
import { AxiosError } from 'axios'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  List,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  AccountCircle,
  ArrowDownward,
  ArrowForward,
  Redo,
  Save,
  Send,
  SmartToy as SmartToyIcon,
} from '@mui/icons-material'
import { findNextStep } from '../../utils/findNextStep'
import { isDescendant } from '../../utils/isDescendant'
import ReactLoading from 'react-loading'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { MixpanelContext } from '../../contexts/MixpanelContext'
import { preProcessFile } from 'typescript'

function isMobile() {
  return 'ontouchstart' in window
}

function findLastObjectWithMessages(arr) {
  let result = null;
  
  for (const obj of arr) {
    if (obj.messages) {
      result = obj;
    }
    
    if (obj.children && Array.isArray(obj.children)) {
      const lastChildWithMessages = findLastObjectWithMessages(obj.children);
      if (lastChildWithMessages) {
        result = lastChildWithMessages;
      }
    }
  }

  return result;
}

const TreeChat = ({
  nodes,
  depth = 0,
  setStep,
  currentStep,
  treeData,
  handleSendMessage,
}) => {
  const processContext = useContext(ProcessContext)
  const { processId } = processContext

  const authContext = useContext(AuthContext)
  const { activeUser } = authContext

  const { sendAnalyticsEvent } = useContext(MixpanelContext)

  const [nextStep, setNextStep] = useState({})
  const [secondNextStep, setSecondNextStep] = useState({})
  const [isExpanded, setIsExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [newMessage, setNewMessage] = useState('')
  const [lastMessage, setLastMessage] = useState('')
  const [isSavingOutput, setIsSavingOutput] = useState({
    exit: false,
    'break into tasks': false,
  })
  const [showSaveAlert, setShowSaveAlert] = useState(false)
  const [saveAlertMessage, setSaveAlertMessage] = useState('')
  const [shiftKeyIsPressed, setShiftKeyIsPressed] = useState(false)
  const [textCursorPosition, setTextCursorPosition] = useState()
  const accordionRef = useRef(null) // Create a ref for the accordion container
  const userTextInputRef = useRef(null) // Create a ref for the accordion container
  const chatFieldRef = useRef(null)
  const textFieldRef = useRef(null)

  const saveAndContinue = treeData[0]?.name === "Break a User Story Into Tasks" ? true : false
  const outputName = treeData[0]?.name === "Create a New Process" ? "Process" : "Ticket"

  const scrollTo = async () => {
    setTimeout(() => {
      accordionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }, 400)
  }
  useEffect(() => {
    if (
      isExpanded &&
      accordionRef.current &&
      currentStep?.stepId === nodes.stepId
    ) {
      scrollTo()
    }
  }, [isExpanded, currentStep, nodes, treeData])

  useEffect(() => {
    if (
      currentStep?.stepId === nodes.stepId ||
      nodes.isFirst ||
      isDescendant(treeData, nodes.stepId, currentStep?.stepId)
    ) {
      setIsExpanded(true)
    }
  }, [nodes, treeData, currentStep])

    useEffect(() => {
        setIsLoading(true);
    }, [treeData]);

  useEffect(() => {
    setNextStep(findNextStep(treeData, nodes?.name))
    setSecondNextStep(findNextStep(treeData, nodes?.name, true))
    if (
      nodes?.messages?.length > 0 &&
      nodes?.messages[nodes?.messages?.length - 1]?.role === 'bot'
    ) {
      setIsLoading(false)
    }
  }, [treeData, nodes, setNextStep, setSecondNextStep])

  useEffect(() => {
    if (newMessage[textCursorPosition] === '\n') {
      userTextInputRef.current.setSelectionRange(
        textCursorPosition + 1,
        textCursorPosition + 1
      )
    }
    // eslint-disable-next-line
  }, [newMessage])

  useEffect(() => {
    const updateChatFieldPosition = () => {
      const textFieldHeight = textFieldRef.current
        ? textFieldRef.current.offsetHeight
        : 0
      const chatField = chatFieldRef.current
      if (chatField) {
        chatField.style.paddingBottom = `${textFieldHeight}px`
      }
    }

    const resizeObserver = new ResizeObserver(updateChatFieldPosition)
    if (textFieldRef.current) {
      resizeObserver.observe(textFieldRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const handleKeyDown = (e) => {
    setTextCursorPosition(e.target.selectionEnd)
    if (['Shift', 'Enter'].includes(e.key)) {
      e.preventDefault()
      if (e.key === 'Shift') setShiftKeyIsPressed(true)
      if (e.key === 'Enter') {
        if (shiftKeyIsPressed) {
          setNewMessage((prevState) => {
            return (
              prevState.substring(0, e.target.selectionStart) +
              '\n' +
              prevState.substring(e.target.selectionStart)
            )
          })
        } else if (newMessage && newMessage.trim().length !== 0) {
          handleSendMessage(newMessage)
          displaySentMessage()
        }
      }
    }
  }

  const displaySentMessage = () => {
    setLastMessage(newMessage)
    setIsLoading(true)
    setNewMessage('')
  }

  const navigate = useNavigate()

  const handleSaveProcessOutput = async (actionAfterSave) => {
    const processType = treeData[0].name
    const tree = treeData[0]
    let ticket
    let lastTicket = findLastObjectWithMessages(tree.children);

    if (actionAfterSave === 'admin') {
      ticket = 'Admin Mode'
    } else {
      ticket = saveAndContinue ? 
      lastTicket?.messages[lastTicket?.messages?.length - 1]?.text : 
      treeData[0].children[treeData[0].children.length - 1].messages[0].text

      ticket.ticket_body = ticket.ticket_body.split('END OF TICKET')[0]
    }
    setShowSaveAlert(false)
    Object.keys(isSavingOutput).forEach((v) => (isSavingOutput[v] = false))
    setIsSavingOutput((prevState) => {
      return { ...prevState, [actionAfterSave]: true }
    })
    try {
      const res = await axios.post(
        '/store/save-process-output',
        {
          processId,
          processType,
          tree,
          ticket,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      )
      if (localStorage.getItem('workspaceChatHistory')) {
        const parsed = JSON.parse(localStorage.getItem('workspaceChatHistory'))
        parsed.push(res.data)
        localStorage.setItem('workspaceChatHistory', JSON.stringify(parsed))
      }
      if (localStorage.getItem('chatHistory')) {
        const parsed = JSON.parse(localStorage.getItem('chatHistory'))
        parsed.push(res.data)
        localStorage.setItem('chatHistory', JSON.stringify(parsed))
      }
      let redirectTo = null
      switch (actionAfterSave) {
        case 'exit':
          redirectTo = '/dashboard'
          break
        case 'break into tasks':
          redirectTo = `/chat/break-user-story-into-tasks?parentProcessIds=${res.data.id}`
          break
        case 'continue':
          //pass
          break
        default:
          alert('Admin save successful')
      }
      if (redirectTo) {
        navigate(redirectTo)
      }
    } catch (err) {
      console.error(err)
      if (err instanceof AxiosError && err.response) {
        setSaveAlertMessage(err.response.data)
      } else {
        setSaveAlertMessage(
          'Sorry, there was a problem saving your ticket. Are you offline?'
        )
      }
      setShowSaveAlert(true)
    } finally {
      setIsSavingOutput((prevState) => {
        return { ...prevState, [actionAfterSave]: false }
      })
    }
  }

  const handleSaveNewProcess = async (actionAfterSave) => {
    let newProcess

    if (actionAfterSave === 'admin') {
      newProcess = 'Admin Mode'
    } else {
      newProcess = treeData[0].messages[treeData[0].messages.length - 1].text
    }
    setShowSaveAlert(false)
    Object.keys(isSavingOutput).forEach((v) => (isSavingOutput[v] = false))
    setIsSavingOutput((prevState) => {
      return { ...prevState, [actionAfterSave]: true }
    })
    try {
      const res = await axios.post(
        '/store/save-new-process',
        {
          newProcess,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      )
      // if (localStorage.getItem('workspaceChatHistory')) {
      //   const parsed = JSON.parse(localStorage.getItem('workspaceChatHistory'))
      //   parsed.push(res.data)
      //   localStorage.setItem('workspaceChatHistory', JSON.stringify(parsed))
      // }
      // if (localStorage.getItem('chatHistory')) {
      //   const parsed = JSON.parse(localStorage.getItem('chatHistory'))
      //   parsed.push(res.data)
      //   localStorage.setItem('chatHistory', JSON.stringify(parsed))
      // }

      navigate('/dashboard')
    } catch (err) {
      console.error(err)
      if (err instanceof AxiosError && err.response) {
        setSaveAlertMessage(err.response.data)
      } else {
        setSaveAlertMessage(
          'Sorry, there was a problem saving your process. Are you offline?'
        )
      }
      setShowSaveAlert(true)
    } finally {
      setIsSavingOutput((prevState) => {
        return { ...prevState, [actionAfterSave]: false }
      })
    }
  }

 useEffect(() => {
   if (
     nodes?.messages?.length > 0 &&
     nodes?.messages[nodes?.messages.length - 1]?.action === 'next' &&
     (nextStep ||
     secondNextStep) &&
     currentStep?.stepId === nodes.stepId
   ) {
     const handleStep = () => {
       setStep(nextStep)
       setIsExpanded(true)
     }

     handleStep()
   }
 }, [secondNextStep, nextStep, isExpanded, newMessage])

  return (
    <div
      style={{
        paddingLeft: '4px',
        paddingRight: '8px',
      }}
    >
      <Accordion
        onChange={(event, isExpanded) => {
          if (activeUser.isAdmin) {
            // Admins can skip steps by clicking on accordions
            setIsExpanded(isExpanded)
            if (isExpanded) {
              setStep(nodes)
            }
          }
          if (!event) {
            setIsExpanded(isExpanded)
            if (isExpanded) {
              setStep(nodes)
            }
          }
        }}
        ref={accordionRef}
        expanded={isExpanded}
        elevation={nodes?.isFirst ? 0 : 4}
        sx={{
          marginY: 1,
          paddingBottom: isExpanded ? 1 : 0,
          borderLeftColor: (theme) =>
            currentStep?.stepId === nodes.stepId && !nodes.isFirst
              ? theme.palette.primary.main
              : '#fff',
          borderLeftWidth: '4px',
          borderLeftStyle: 'solid',
          minHeight:
            ((Array.isArray(nodes.children) && nodes.children.length > 0) ||
              !isExpanded) &&
            !nodes.isFirst
              ? 0
              : 'calc(100vh - 50px)',
          '& .MuiAccordionSummary-root:hover': {
            cursor: 'default',
          },
        }}
      >
        {nodes?.isFirst ? (
          <></>
        ) : (
          <AccordionSummary
            // expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            title={nodes.name}
          >
            <Typography
              sx={{
                p: 0,
                m: 0,
                fontWeight: 500,
              }}
            >
              {nodes.name}
            </Typography>
          </AccordionSummary>
        )}
        <AccordionDetails sx={{ p: 0 }}>
          <div
            ref={chatFieldRef}
            style={{
              paddingTop: '10px',
            }}
          >
            <Box paddingBottom={5}>
              {nodes?.messages?.map((message, index) => (
                <Grid
                  key={index}
                  container
                  direction="row"
                  justifyContent={
                    message.role !== 'user' ? 'flex-start' : 'flex-end'
                  }
                  alignItems="flex-start"
                  wrap="nowrap"
                  sx={{
                    mb: 2,
                    px: '5px',
                  }}
                >
                  <span>{message.role !== 'user' && <SmartToyIcon />}</span>
                  <Box
                    sx={{
                      display: 'inline',
                      maxWidth: { xs: '100%', md: '80%' },
                      padding: '8px',
                      borderRadius: '8px',
                      margin: '8px',
                      marginRight: '8px',
                      borderTopLeftRadius:
                        message.role === 'user' ? '8px' : '0px',
                      borderTopRightRadius:
                        message.role !== 'user' ? '8px' : '0px',
                      fontFamily: "'Outfit', Arial, Helvetica, sans-serif",
                      backgroundColor:
                        message.role === 'user' ? '#1976d2' : '#ddd',
                      color: message.role === 'user' ? 'white' : 'initial',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {message.role === 'bot' ? (
                      <ReactMarkdown className="markdown-to-html-wrapper">
                        {typeof message.text === 'object'
                          ? message.text.ticket_body
                          : message.text}
                      </ReactMarkdown>
                    ) : (
                      message.text
                    )}
                    <Box
                      display="flex"
                      justifyContent="center"
                      marginY={message.action === 'next' ? 1 : 0}
                    >
                      {treeData[0].name === 'Plan a Task' &&
                        message.action === 'next' &&
                        nextStep ? (
                          <>Please wait for generating subststeps...</>
                        ) : <></>}
                      {/* {message.action === 'next' && nextStep && (
                        <Stack direction="row" alignItems="center">
                          {saveAndContinue && message.allowSaveTicket ? 
                            <Button
                            variant="contained"
                            disabled={Object.values(isSavingOutput).some(
                              (value) => value === true
                            )}
                            onClick={() => {
                              setStep(nextStep)
                              setIsExpanded(false)
                              handleSaveProcessOutput("continue")
                              sendAnalyticsEvent('ChatButtonClicked', {
                                eventCategory: 'User Interaction',
                                action: 'Save Ticket and Continue',
                              })
                            }}
                            sx={{
                              marginRight: 1,
                              textTransform: 'none',
                            }}
                          >
                            <Stack direction="row" alignItems="center">
                              <ArrowDownward
                                sx={{
                                  marginRight: 1,
                                }}
                              />
                              {'Save & Proceed to Next Step'}
                            </Stack>
                          </Button>
                           : 
                          <Button
                            variant="contained"
                            onClick={() => {
                              setStep(nextStep)
                              setIsExpanded(false)
                              sendAnalyticsEvent('ChatButtonClicked', {
                                eventCategory: 'User Interaction',
                                action: 'Go to the next step',
                              })
                            }}
                            sx={{
                              marginRight: 1,
                              textTransform: 'none',
                            }}
                          >
                            <Stack direction="row" alignItems="center">
                              <ArrowDownward
                                sx={{
                                  marginRight: 1,
                                }}
                              />
                              {nextStep?.name || 'Next Step'}
                            </Stack>
                          </Button>
                          }
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setStep(secondNextStep)
                              setIsExpanded(false)
                              sendAnalyticsEvent('ChatButtonClicked', {
                                eventCategory: 'User Interaction',
                                action: 'Skip to the next step',
                              })
                            }}
                            sx={{
                              marginLeft: 1,
                              textTransform: 'none',
                            }}
                          >
                            <Stack direction="row" alignItems="center">
                              <Redo
                                sx={{
                                  marginRight: 1,
                                }}
                              />
                              Skip Next Step
                            </Stack>
                          </Button>
                        </Stack>
                      )} */}
                      {((saveAndContinue &&
                        !nextStep &&
                        message.allowSaveTicket &&
                        message.role === 'bot' &&
                        nodes?.messages[nodes?.messages.length - 1] ==
                          message) ||
                        (!saveAndContinue &&
                          message.allowSaveTicket &&
                          message.role === 'bot' &&
                          nodes?.messages[nodes?.messages.length - 1] ==
                          message)) && (
                        <Stack alignItems="center">
                          <Stack
                            direction={{
                              md: 'row',
                            }}
                            justifyContent="space-evenly"
                            width="100%"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={Object.values(isSavingOutput).some(
                                (value) => value === true
                              )}
                              onClick={() => {
                                (outputName === "Ticket") ? handleSaveProcessOutput('exit') :
                                handleSaveNewProcess('exit')
                                sendAnalyticsEvent('ChatButtonClicked', {
                                  eventCategory: 'User Interaction',
                                  action: `Save ${outputName} and Exit`,
                                })
                              }}
                              sx={{
                                minWidth: {
                                  md: '200px',
                                },
                                textTransform: 'none',
                                marginRight: {
                                  md: 1,
                                },
                                marginBottom: {
                                  xs: 1,
                                  md: 0,
                                },
                              }}
                            >
                              {isSavingOutput.exit ? (
                                <CircularProgress
                                  color="primary"
                                  thickness={5}
                                  size="1.75em"
                                />
                              ) : (
                                <Stack direction="row" alignItems="center">
                                  <Save
                                    sx={{
                                      marginRight: 1,
                                    }}
                                  />
                                  Save {outputName} and Exit
                                </Stack>
                              )}
                            </Button>
                            {treeData[0].name === 'Plan a User Story' && (
                              <Button
                                variant="contained"
                                color="secondary"
                                disabled={Object.values(isSavingOutput).some(
                                  (value) => value === true
                                )}
                                onClick={() => {
                                  handleSaveProcessOutput('break into tasks')
                                  sendAnalyticsEvent('ChatButtonClicked', {
                                    eventCategory: 'User Interaction',
                                    action: 'Break into tasks',
                                  })
                                }}
                                marginLeft={1}
                                sx={{
                                  minWidth: {
                                    md: '200px',
                                  },
                                  textTransform: 'none',
                                  marginLeft: {
                                    md: 1,
                                  },
                                }}
                              >
                                {isSavingOutput['break into tasks'] ? (
                                  <CircularProgress
                                    color="secondary"
                                    thickness={5}
                                    size="1.75em"
                                  />
                                ) : (
                                  <Stack direction="row" alignItems="center">
                                    Save Ticket and Break into Tasks
                                    <ArrowForward
                                      sx={{
                                        marginLeft: 1,
                                      }}
                                    />
                                  </Stack>
                                )}
                              </Button>
                            )}
                          </Stack>
                          {showSaveAlert && (
                            <Alert
                              severity="error"
                              sx={{
                                marginTop: 2,
                              }}
                            >
                              {saveAlertMessage}
                            </Alert>
                          )}
                        </Stack>
                      )}
                    </Box>
                  </Box>

                  <span>
                    {message.role === 'user' && <AccountCircle align="right" />}
                  </span>
                </Grid>
              ))}
              {isLoading && lastMessage && (
                <Grid
                  container
                  direction="row"
                  justifyContent={'flex-end'}
                  alignItems="flex-start"
                  wrap="nowrap"
                  sx={{
                    mb: 2,
                    px: '5px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'inline',
                      maxWidth: { xs: '100%', md: '80%' },
                      padding: '8px',
                      borderRadius: '8px',
                      marginLeft: '8px',
                      marginRight: '8px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '0px',
                      fontFamily: "'Roboto', Arial, Helvetica, sans-serif",
                      backgroundColor: '#1976d2',
                      color: 'white',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {lastMessage}
                  </Box>
                  <span>
                    <AccountCircle align="right" />
                  </span>
                </Grid>
              )}
              {(!(nodes?.messages?.length > 0) || isLoading) && (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  wrap="nowrap"
                  sx={{
                    mb: 2,
                    px: '5px',
                  }}
                >
                  <span>
                    <SmartToyIcon />
                  </span>
                  <span
                    style={{
                      backgroundColor: '#dedede',
                      padding: '8px',
                      paddingBottom: 0,
                      paddingTop: 0,
                      borderRadius: '8px',
                      margin: '0 8px 16px',
                      borderTopLeftRadius: '0px',
                      borderTopRightRadius: '8px',
                    }}
                  >
                    <ReactLoading type="bubbles" color="#fff" />
                  </span>
                </Grid>
              )}
            </Box>
          </div>
          {Array.isArray(nodes.children) && nodes.children.length > 0 ? (
            <Collapse in={true} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {nodes?.children.map((node) => (
                  <TreeChat
                    currentStep={currentStep}
                    setStep={setStep}
                    key={node.stepId}
                    nodes={node}
                    depth={depth + 1}
                    treeData={treeData}
                    handleSendMessage={handleSendMessage}
                  />
                ))}
              </List>
            </Collapse>
          ) : (
            <div
              style={{
                display: isExpanded || nodes.isFirst ? 'block' : 'none', // Hide the container when the accordion is collapsed
                position: 'absolute',
                bottom: '0px',
                padding: 10,
                width: isExpanded || nodes.isFirst ? '100%' : '0px', // Hide the container when the accordion is collapsed
                animation: 'fadeIn 0s ease 0.3s forwards', // Apply the animation with 1-second delay
              }}
            >
              <Stack
                width="100%"
                height="55px"
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                wrap="nowrap"
              >
                <TextField
                  ref={textFieldRef}
                  multiline
                  value={newMessage}
                  inputRef={userTextInputRef}
                  disabled={isLoading ? true : false}
                  onChange={(e) => {
                    setNewMessage(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    // Shift+Enter for newline and Enter to send only for desktop, mobile can use Enter for multiline must press send icon to send. ChatGPT and Bard follow same norm.
                    if (!isMobile()) handleKeyDown(e)
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Shift') setShiftKeyIsPressed(false)
                  }}
                  placeholder="Write a message..."
                  fullWidth
                  variant="outlined"
                  sx={{
                    backgroundColor: 'white',
                    lineHeight: '20px',
                    maxHeight: '150px',
                    overflowY: 'auto',
                  }}
                />
                <Tooltip title="Send" placement="top" disableHoverListener>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSendMessage(newMessage)
                      displaySentMessage()
                      sendAnalyticsEvent('ChatButtonClicked', {
                        eventCategory: 'User Interaction',
                        action: 'Send Message',
                      })
                    }}
                    disabled={!newMessage || newMessage.trim().length === 0}
                    sx={{
                      height: '100%',
                      marginLeft: 1,
                      textTransform: 'none',
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Send
                        sx={{
                          marginRight: { md: 1 },
                          color: (theme) =>
                            !newMessage || newMessage.trim().length === 0
                              ? 'rgba(0, 0, 0, 0.26)'
                              : 'white',
                        }}
                      />
                      <Box
                        display={{
                          xs: 'none',
                          md: 'block',
                        }}
                        sx={{
                          color: (theme) =>
                            !newMessage || newMessage.trim().length === 0
                              ? 'rgba(0, 0, 0, 0.26)'
                              : 'white',
                        }}
                      >
                        Send
                      </Box>
                    </Stack>
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Skip"
                  placement="top"
                  sx={{
                    display: { xs: 'none', md: 'block' },
                  }}
                  disableHoverListener
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setStep(nextStep)
                      sendAnalyticsEvent('ChatButtonClicked', {
                        eventCategory: 'User Interaction',
                        action: 'Skip to the next step',
                      })
                    }}
                    disabled={!nextStep || !treeData[0].children}
                    sx={{
                      height: '100%',
                      marginLeft: 1,
                      textTransform: 'none',
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Redo sx={{ marginRight: { md: 1 } }} />
                      <Box
                        display={{
                          xs: 'none',
                          md: 'block',
                        }}
                      >
                        Skip
                      </Box>
                    </Stack>
                  </Button>
                </Tooltip>
                {/* BEGIN ADMIN ONLY FOR TESTING PURPOSES */}
                {activeUser.isAdmin && (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      handleSaveProcessOutput('admin')
                      sendAnalyticsEvent('ChatButtonClicked', {
                        eventCategory: 'Admin Interaction',
                        action: 'Save (for Admin)',
                      })
                    }}
                    sx={{
                      height: '100%',
                      marginLeft: 1,
                      textTransform: 'none',
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Save sx={{ marginRight: { md: 1 } }} />
                      <Box
                        display={{
                          xs: 'none',
                          md: 'block',
                        }}
                        fontSize="0.8em"
                      >
                        Save Now (Admin)
                      </Box>
                    </Stack>
                  </Button>
                )}
                {/* END ADMIN ONLY FOR TESTING PURPOSES */}
              </Stack>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default TreeChat
