import React from "react";
import { Box } from "@mui/material";

const BrowserMockup = () => {
    return (
        <Box
            sx={{
                position: "relative",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    transform: "scale(.91)rotate(-4deg)",
                    position: "relative",
                }}
            >
                <img
                    width={"100%"}
                    style={{ borderRadius: "25px" }}
                    src={"/testimages/bgBlueMockup.png"}
                    alt=""
                />
            </Box>
            <Box
                sx={{
                    position: "absolute",
                    top: "3.5rem",
                    left: "3.5rem",
                }}
            >
                <Box
                    p={2}
                    sx={{
                        width: "83%",
                    }}
                >
                    <Box>
                        <img
                            src="/testimages/browserMockupImage.png"
                            style={{ width: "100%", borderRadius: "15px" }}
                            alt=""
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default BrowserMockup;
