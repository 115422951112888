import React from 'react';
import {Box, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";

interface Post {
    photo?: string ;
    name?: string ;
    name_link?: string ;
    nick?: string ;
    nick_link?: string ;
    date: string ;
    text?: string ;
}
interface UserPostProps {
    post: Post;
}
const UserPost: React.FC<UserPostProps> = ({post}) => {
    function formatDate(input:string) {
        const [year, month, day] = input.split('/');
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        return `${monthNames[parseInt(month) - 1]}, ${day}`;
    }

    console.log('********', post)
    return (
        <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box p={2} >
                <Box sx={{ display: "flex" }}>
                    <img width={48} height={48} src={post.photo} style={{ borderRadius: "999px" }} />
                    <Box pl={2}>
                        <a href={post.name_link} target={"_blank"}>
                            <Typography variant={"body2"}>
                                <strong>{post.name}</strong>
                            </Typography>
                        </a>
                        <Box sx={{ display: "flex" }}>
                            <a href={post.nick_link} target={"_blank"}>
                                <Typography variant={"body2"}>
                                    {post.nick}
                                </Typography>
                            </a>
                            <Typography pl={1} variant={"body2"}>
                                {formatDate(post.date)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Typography pt={1} variant={"body2"} style={{ whiteSpace: 'pre-line' }}>
                        {post.text}
                    </Typography>
                </Box>
            </Box>
        </Paper>
    );    
};

export default UserPost;