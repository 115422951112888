import axios from "axios";

const instance = axios.create({
    baseURL:
        process.env.NODE_ENV === "development"
            ? "http://localhost:5001"
            : "https://consultant-ai.azurewebsites.net",
    validateStatus: status => {
        return status < 400;
    },
});

export default instance;
