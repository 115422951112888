import React, {useEffect, useRef, useState} from 'react';

interface MyFadeInAnimationProps {
    children:React.ReactNode;
    duration:number;
    rootMargin: number;
}

const MyFadeInAnimation: React.FC<MyFadeInAnimationProps> = ({children, duration, rootMargin}) => {

    const showDiv = {opacity: 1, transition: `opacity ${duration}ms ease-in-out` };
    const hideDiv = {opacity: 0, transition: `opacity ${duration}ms ease-in-out` };

    const divRef = useRef(null);
    const [currentStyle, setCurrentStyle] = useState(hideDiv);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    console.log("Element is in the viewport!");
                    console.log('*******', entries)
                    // entries[0].target.classList.add("show_block");
                    setCurrentStyle(showDiv);
                } else {
                    console.log("Element is not in the viewport.");
                    entries[0].target.classList.remove("show_block");
                    setCurrentStyle(hideDiv);
                }
            },
            {
                root: null,
                rootMargin: `${rootMargin}px`,
                threshold: 0
            }
        );

        if (divRef.current) {
            observer.observe(divRef.current);
        }

        return () => {
            if (divRef.current) {
                observer.unobserve(divRef.current);
            }
        };
    }, []);


    return (
        <div ref={divRef} style={currentStyle}>
            { children }
        </div>
    );
};

export default MyFadeInAnimation;