// Custom hook called useHistoryState which leverages react-router-dom to manage state across
// different routes. This is a useful pattern for preserving state between page navigations.
// It allows to manage state in a way that persists across different routes. 
// It's a powerful tool for managing complex UIs that involve navigation.
// The hook takes two arguments:
// key: A string used to identify the state value in the history object.
// initialValue: The initial value of the state.
// The hook first attempts to retrieve the value from the location state using the 
// provided key. If it's not found, it uses the initialValue.
// It used to store tab number and return 

import { useNavigate, useLocation } from "react-router-dom";
import { useState, useCallback } from "react";

function useNavigateState<T>(key: string, initialValue: T): [T, (t: T) => void] {
  const navigate = useNavigate();
  const location = useLocation();
  const [rawState, rawSetState] = useState<T>(() => {
    const value = (location.state as any)?.[key];
    return value ?? initialValue;
  });

  const setState = useCallback(
    (value: T) => {
      navigate(location.pathname, {
        state: {
          ...location.state,
          [key]: value
        }
      });
      rawSetState(value);
    },
    [key, location, navigate, rawSetState]
  );

  return [rawState, setState];
}

export default useNavigateState;