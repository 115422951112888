import React from 'react';
import {Box} from "@mui/material";
import Link from '@mui/material/Link';
import { NavLink } from "react-router-dom";
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';

const activeLinkStyle = {
    color: 'black', // Change color on hover
  };


const FooterBlock = () => {
    return (
        <Box sx={{paddingBottom: "1.5rem"}}>
            <Box
                sx={{
                    display:"flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box pl={3}>
                    <img src="/testimages/logoBlack.png" alt="" height={"15px"} style={{color:"black"}}/>
                </Box>
                <Box pr={3}
                     sx={{
                        display:"flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                         gap: "10px"
                         // width:"30%",
                     }}
                >
                    <TwitterIcon  sx={{fontSize:"40px"}} />
                    <LinkedInIcon sx={{fontSize:"40px"}} />
                    <YouTubeIcon  sx={{fontSize:"40px"}} />
                    <FacebookIcon sx={{fontSize:"40px"}} />
                </Box>
            </Box>
            <hr/>
            <Box>
                <Link href={"/"} underline="hover" m={2}
                      sx = {{
                          color:"grey",
                          '&:hover': {
                              color: "black",
                          }
                      }}
                >© Consultant.ai</Link>
                
                <Link href={"/contact"} underline="hover" m={2}
                      sx = {{
                          color:"grey",
                          '&:hover': {
                              color: "black",
                          }
                      }}
                >Contact</Link>
                <Link href={"/contact-sales"} underline="hover" m={2}
                      sx = {{
                          color:"grey",
                          '&:hover': {
                              color: "black",
                          }
                      }}
                >Contact Sales</Link>
                <Link href={"/pricing"} underline="hover" m={2}
                      sx = {{
                          color:"grey",
                          '&:hover': {
                              color: "black",
                          }
                      }}
                >Pricing</Link>

                <Link href={"/terms"} underline="hover" m={2}
                      sx = {{
                          color:"grey",
                          '&:hover': {
                              color: "black",
                          }
                      }}
                >Terms & Conditions</Link>

                <Link href={"/privacy"} underline="hover" m={2}
                      sx = {{
                          color:"grey",
                          '&:hover': {
                              color: "black",
                          }
                      }}
                >Privacy</Link>

             </Box>
        </Box>
    );
};

export default FooterBlock;