import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { ProcessContext } from "../../contexts/ProcessContext";
import {
    Alert,
    AppBar,
    Box,
    IconButton,
    Snackbar,
    Stack,
    Toolbar,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import "../../styles/components/Header.css";

import Logo from "./Logo";
import NavMenu from "./NavMenu";
import { IAuthContext } from "../../models/IAuthContext";
import { MixpanelContext } from "../../contexts/MixpanelContext";

const Header = ({ color }: { color?: string }) => {
    const [showLogoutSnackbar, setShowLogoutSnackbar] =
        useState<boolean>(false);

    const authContext = useContext(AuthContext);
    const { setIsLoggedIn, setActiveUser, isLoggedOut, setIsLoggedOut } = authContext as IAuthContext;

    const processContext = useContext(ProcessContext);
    const { mobileOpen, setMobileOpen } = processContext;

    const {sendAnalyticsEvent} = useContext(MixpanelContext)

    useEffect(() => {
        if (isLoggedOut) {
            setShowLogoutSnackbar(true);
            setIsLoggedOut(false);
            if (localStorage.getItem("authToken")) {
                localStorage.removeItem("authToken");
                setActiveUser({
                    firstName: "User",
                    isAdmin: false,
                });
                setIsLoggedIn(false);
            }
        }
    }, [isLoggedOut, setActiveUser, setIsLoggedIn, setIsLoggedOut]);

    const location = useLocation();

    return (
        <Box id="header">
            <AppBar color="inherit" sx={{
                backgroundColor: color
            }}>
                <Toolbar
                    variant="dense"
                    disableGutters
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: {
                            xs: 1,
                            sm: 3,
                        },
                        paddingRight: 1,
                        zIndex: theme => theme.zIndex.drawer + 1,
                    }}
                >
                    <Stack direction="row" alignItems="center">
                        {location.pathname.startsWith("/chat") && (
                            <IconButton>
                                <Menu
                                    onClick={() => {
                                        sendAnalyticsEvent('NavbarMenuOpenMobile', {
                                          eventCategory: 'User Interaction',
                                          action:
                                            'Navbar Menu Opened Mobile',
                                        })
                                        setMobileOpen(!mobileOpen)}}
                                    sx={{ display: { md: "none" } }}
                                />
                            </IconButton>
                        )}
                        <Logo />
                    </Stack>
                    <NavMenu />
                </Toolbar>
            </AppBar>
            {showLogoutSnackbar && (
                <Snackbar
                    open={showLogoutSnackbar}
                    onClose={() => setShowLogoutSnackbar(false)}
                    autoHideDuration={5000}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    sx={{ marginTop: { xs: "50px", sm: "35px" } }}
                >
                    <Alert severity="success" variant="filled">
                        You have successfully logged out
                    </Alert>
                </Snackbar>
            )}
        </Box>
    );
};

export default Header;
