import { useEffect } from "react";
import Header from "../components/global/Header";
import TermsContent from "../components/Terms/TermsContent";

const Terms = () => {
    useEffect(() => {
        document.title = "Terms of Service | Consultant.AI";
    }, []);

    return (
        <div id="terms" className="page">
            <Header />
            <TermsContent />
        </div>
    );
};

export default Terms;
