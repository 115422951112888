import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, TextField, Button, Typography, Switch, CircularProgress } from "@mui/material";
import axios from "../../utils/AxiosInstance";
import { AxiosError } from "axios";
import { MixpanelContext } from "../../contexts/MixpanelContext";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const modalContent = {
    transform: "translate(50%,50%)",
    width: "50%",
    height: "50%",
    border: "1px solid black",

    '@media (max-width: 600px)': {
        width: '100%',
        height: '100%',
        transform: "translate(0,0)",
    },
    '@media (max-height: 600px)': {
        height: '100%',
        width: '100%',
        transform: "translate(0,0)",
    },
};

const modalChildrenStyle = {
    width: "40%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "15px",

    "@media (max-width: 600px)": {
        width: "75%",
    },
    '@media (max-height: 600px)': {
        height: '10%',
    },
}

const buttonStyle = {
    color: "white",
    '&:hover': {
        backgroundColor: "rgb(0, 0, 133)",
    },
};

const CreateWorkspaceModal = ({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}) => {
    const [workspaceName, setWorkspaceName] = useState<string>("");
    const [resultMessage, setResultMessage] = useState<string>("");
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const {sendAnalyticsEvent} = useContext(MixpanelContext);
    const navigate = useNavigate()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWorkspaceName(e.target.value);
    };

    const handleSubmit = async () => {
        setShowSpinner(true)
        sendAnalyticsEvent('CreateNewWorkspaceAttempt', {
          eventCategory: 'User Interaction',
          action: 'User attempted to create new Workspace',
        })
        try {
            const res = await axios.post(
                "/auth/create-workspace",
                {
                    name: workspaceName.trim(),
                    private: false,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "authToken"
                        )}`,
                    },
                }
            );
            setResultMessage(res.data.message);
            sendAnalyticsEvent('CreateNewWorkspaceSuccess', {
              eventCategory: 'User Interaction',
              action: 'New Workspace Creation Success',
            })
            navigate(`/dashboard/${res.data.workspace_id}/${res.data.workspace_name}`)
        } catch (err) {
            sendAnalyticsEvent('CreateNewWorkspaceFailed', {
              eventCategory: 'User Interaction',
              action: 'New Workspace Creation Failed',
            })
            console.error(err);
            if (err instanceof AxiosError && err.response) {
                console.log(err.response.data);
            }
        } finally{
            setShowSpinner(false)
        }
    };

    return (
        <div>
            <Modal open={open} onClose={onClose} sx={modalContent}>
                <div style={{backgroundColor: "white", height: "100%", fontSize: "20px", display:"flex", flexDirection:"column"}}>
                <IconButton 
                        onClick={onClose} 
                        sx={{ 
                            position: 'absolute', 
                            top: 8, 
                            right: 8, 
                            color: 'grey' 
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h4" sx={{marginTop: "110px", marginBottom: "15px", marginLeft:"auto",marginRight:"auto", color: "darkblue"}}>Create a new workspace</Typography>
                        <TextField
                            autoFocus
                            id="workspace-name"
                            value={workspaceName}
                            onChange={handleChange}
                            placeholder="Enter workspace name"
                            variant="filled"
                            label="Workspace"
                            color="primary"
                            margin="dense"
                            sx={modalChildrenStyle}
                        >
                        </TextField>                              
                    <Button variant="contained" onClick={handleSubmit} 
                       sx={{ ...modalChildrenStyle, ...buttonStyle }}>
                        {showSpinner ? (
                            <CircularProgress
                                color="primary"
                                thickness={5}
                                size="1.75em"
                            />
                        ) : (
                            "Create"
                        )}
                    </Button>
                    <Typography sx={{marginLeft:"auto",marginRight:"auto", color: "darkblue"}}>{resultMessage}</Typography>
                </div>
            </Modal>
        </div>
    );
};

export default CreateWorkspaceModal;