import { ThemeOptions } from "@mui/material/styles";

const MuiTheme: ThemeOptions = {
    palette: {
        secondary: {
            main: "#4e9cea",
        },
    },
    typography: {
        fontFamily: "'Roboto', Arial, Helvetica, sans-serif",
        allVariants: {
            color: "#555",
        },
    },
    zIndex: {
        drawer: 2,
    },
};

export default MuiTheme;
