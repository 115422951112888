import DashboardGreeting from "./DashboardGreeting";
import DashboardMenu from "./DashboardMenu";

import { Stack } from "@mui/material";

const DashboardMain = () => {
    return (
        <Stack
            id="dashboard-content"
            width={{ xs: "100%", md: "60%" }}
            paddingX={{ xs: 2, md: 3 }}
            paddingTop={{ xs: 3, md: 4 }}
        >
            <DashboardGreeting />
            <DashboardMenu />
        </Stack>
    );
};

export default DashboardMain;
