import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "../../utils/AxiosInstance";
import { AxiosError } from "axios";
import { ISignupFormData, ISignupErrorStatus } from "../../models/ISignupForm";
import { IAuthContext } from "../../models/IAuthContext";

import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Stack,
    TextField,
    Typography,
    Select,
    SelectChangeEvent,
    FormHelperText,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import PasswordStrengthBar from "react-password-strength-bar";

import {
    validateEmail,
    validateRest,
} from "../../utils/validation/SignupValidation";

import { logUserIn } from "../../utils/logUserIn";
import { MixpanelContext } from "../../contexts/MixpanelContext";

const SignupForm = () => {
    const [orgList, setOrgList] = useState([]);
    useEffect(() => {
        document.title = "Signup | Consultant.AI";
        // const fetchedOrgList = getOrganizationList().then((result) => {
        //     setOrgList(result['organization_list']);
        // })
    }, []);

    const authContext = useContext(AuthContext);
    const { setIsLoggedIn, setActiveUser } = authContext as IAuthContext;

    const {sendAnalyticsEvent} = useContext(MixpanelContext)
    const navigate = useNavigate();

    const [signupFormData, setSignupFormData] = useState<ISignupFormData>({
        signupEmail: "",
        signupName: "",
        signupPassword: "",
        signupConfirmPassword: "",
    });

    const [currentFieldDisplayed, setCurrentFieldDisplayed] =
        useState<string>("email");

    // const getOrganizationList = async () => {
    //     const res = await axios.get("/auth/org-list")
    //     const data = res.data
    //     return data
    // }

    const [helperText, setHelperText] = useState<ISignupFormData>({
        signupEmail: "",
        signupName: "",
        signupPassword: "",
        signupConfirmPassword: "",
    });
    const [hasError, setHasError] = useState<ISignupErrorStatus>({
        signupEmail: false,
        signupName: false,
        signupPassword: false,
        signupConfirmPassword: false,
    });

    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>("");

    const [orgFieldFocused, setOrgFieldFocused] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setHelperText(prevState => {
            return { ...prevState, [e.target.id]: "" };
        });
        setHasError(prevState => {
            return { ...prevState, [e.target.id]: false };
        });
        setSignupFormData(prevState => {
            return { ...prevState, [e.target.id]: e.target.value };
        });
    };      


    const handleValidate = (): void => {
        if (currentFieldDisplayed === "email") {
            validateEmail(
                signupFormData.signupEmail,
                setShowSpinner,
                setHelperText,
                setHasError,
                setCurrentFieldDisplayed
            );
        } else {
            validateRest(
                signupFormData,
                setHelperText,
                setHasError,
                handleSubmitSignup
            );
        }
        sendAnalyticsEvent('SignupAttempt', {
          eventCategory: 'Authentication',
          action: 'Signup Attempt',
        })
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === "Enter") handleValidate();
    };

    const handleSubmitSignup = async () => {
        const { signupName, signupEmail, signupPassword } =
            signupFormData;
        setShowSpinner(true);
        setShowAlert(false);
        try {
            const res = await axios.post("/auth/register", {
                name: signupName.trim(),
                email: signupEmail.trim(),
                password: signupPassword,
            });
            const { authToken, firstName, isAdmin } = res.data;
            logUserIn(
                authToken,
                firstName,
                isAdmin,
                setActiveUser,
                setIsLoggedIn
            );
            sendAnalyticsEvent('SignupSuccess', {
              eventCategory: 'Authentication',
              action: 'Signup Successful',
            })
            navigate("/post-signup");
        } catch (err) {
            sendAnalyticsEvent('SignupFailed', {
              eventCategory: 'Authentication',
              action: 'Signup Failed',
            })
            console.error(err);
            if (err instanceof AxiosError && err.response) {
                setAlertMessage(err.response.data);
            } else {
                setAlertMessage(
                    "Sorry, we could not sign you up. Are you offline?"
                );
            }
            setShowAlert(true);
        } finally {
            setShowSpinner(false);
        }
    };

    return (
        <Box
            id="signup-form"
            width={{ xs: "100%", md: "70%" }}
            padding={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={{ xs: "flex-start", sm: "center" }}
            sx={{
                backgroundImage:
                    "url(https://consultantaiassetstorage.blob.core.windows.net/assets/images/auth-form-background.jpg)",
                backgroundPositionX: {
                    xs: "35%",
                    sm: "80%",
                    md: "70%",
                    lg: "90%",
                    xl: "120%",
                },
                backgroundPositionY: { xs: "60%", sm: "80%" },
            }}
        >
            <Typography variant="h4">Welcome to consultant.ai</Typography>
            <Typography marginTop={1}>Get started - it's free. No credit card needed.</Typography>
            
            <Stack width={{ xs: "100%", md: "400px" }} marginTop={5} marginBottom={5}>
                {currentFieldDisplayed === "email" && (
                    <TextField
                        autoFocus
                        id="signupEmail"
                        type="email"
                        value={signupFormData.signupEmail}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        placeholder="name@company.com"
                        helperText={helperText.signupEmail}
                        error={hasError.signupEmail}
                        variant="filled"
                        label="Email"
                        color="primary"
                        margin="dense"
                    />
                )}
                {currentFieldDisplayed === "rest" && (
                    <>
                        <TextField
                            autoFocus
                            id="signupName"
                            value={signupFormData.signupName}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            helperText={helperText.signupName}
                            error={hasError.signupName}
                            variant="filled"
                            label="Full Name"
                            color="primary"
                            margin="dense"
                        />
                        <TextField
                            id="signupPassword"
                            value={signupFormData.signupPassword}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            helperText={helperText.signupPassword}
                            error={hasError.signupPassword}
                            type="password"
                            variant="filled"
                            label="Password"
                            color="primary"
                            margin="dense"
                        />
                        {signupFormData.signupPassword && (
                            <PasswordStrengthBar
                                password={signupFormData.signupPassword}
                                minLength={8}
                                scoreWords={[
                                    "Weak",
                                    "Weak",
                                    "Okay",
                                    "Good",
                                    "Strong",
                                ]}
                                shortScoreWord="Too Short"
                                style={{
                                    fontFamily:
                                        "'Roboto', Arial, Helvetica, sans-serif",
                                }}
                            />
                        )}
                        <TextField
                            id="signupConfirmPassword"
                            value={signupFormData.signupConfirmPassword}
                            disabled={!signupFormData.signupPassword}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            helperText={helperText.signupConfirmPassword}
                            error={hasError.signupConfirmPassword}
                            type="password"
                            variant="filled"
                            label="Confirm Password"
                            color="primary"
                            margin="dense"
                        />
                    </>
                )}
                {showAlert && (
                    <Alert severity="error" sx={{ marginTop: 1 }}>
                        {alertMessage}
                    </Alert>
                )}
                <Button
                    variant="contained"
                    onClick={handleValidate}
                    disabled={showSpinner ? true : false}
                    sx={{ marginTop: 1 }}
                >
                    {showSpinner ? (
                        <CircularProgress
                            color="primary"
                            thickness={5}
                            size="1.75em"
                        />
                    ) : currentFieldDisplayed === "email" ? (
                        "Continue"
                    ) : (
                        "Sign Up"
                    )}
                </Button>
            </Stack>
            <Typography>By proceeding, you agree to the</Typography>
            <Box display="flex">
                <Link to="/terms">
                    <Typography color="primary" component="span">
                        Terms of Service
                    </Typography>
                </Link>
                <Typography style={{ marginLeft: '4px', marginRight: '4px' }}> and </Typography>
                <Link to="/privacy">
                    <Typography color="primary" component="span">
                        Privacy Policy
                    </Typography>
                </Link>
                <Typography>.</Typography>
            </Box>
            <Box height="150px">
                <Typography marginTop={30}>
                    Already have an account?{" "}
                    <Link to="/login">
                        <Typography color="primary" component="span">
                            Log in
                        </Typography>
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
};

export default SignupForm;
