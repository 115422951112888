import React from 'react';
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";

interface message {
    m_icon: string;
    m_title: string;
    m_text: string
}
interface MessageInfoBlockProps {
    message: message;
    MIcon: React.ElementType<any>;
}
const MessageInfoBlock:React.FC<MessageInfoBlockProps> = ({message, MIcon}) => {
    return (
        <Box mt={3}>
            <Box
                sx={{
                    display:"flex",
                    alignItems:'center',
                }}
            >
                <Box width={30} height={30} style={{backgroundColor: "white"}}
                     sx={{
                         display:"flex",
                         backgroundColor: "white",
                         borderRadius: "999px",
                         justifyContent: "Center",
                         alignItems:"Center",
                         border:"2px solid rgb(56, 116, 203)"
                     }}
                >
                    <MIcon style={{color: "rgb(56, 116, 203)", fontSize:"16px"}}/>
                </Box>
                <Typography pl={1}>
                    <strong>{message.m_title}</strong>
                </Typography>
            </Box>
            <Typography variant="body2">
                {message.m_text}
            </Typography>
        </Box>
    );
};

export default MessageInfoBlock;