import React from 'react';
import { Button, Card, CardContent, CardMedia, Grid, Typography, Link } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const Efficiency: React.FC = () => {
  return (
    <div>
      <Grid container spacing={4} style={{ display: 'flex' }}>
        <Grid item xs={12} md={5} style={{ flex: 1.2 }}>
          <Card style={{ borderRadius: '15px', height: '500px', minWidth: '350px', display: 'flex', flexDirection: 'column' }}>
            <CardContent style={{ flex: '1', padding: '25px' }}>
            <Typography variant="h5">
                <span style={{ fontWeight: 'bold' }}>
                  <img src="../testimages/logo.png" alt="Logo" style={{ verticalAlign: 'middle', marginRight: '8px', width: '30px' }} />
                  consultant
                </span> efficiency
              </Typography>
              <Typography variant="h3" mt={5} mb={7} style={{ fontWeight: 'bold' }}>
                Explore proven ways to work more efficiently
              </Typography>
              <Button variant="contained" style={{backgroundColor:'rgb(0, 0, 237)'}}>
                Browse by use case
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} style={{ flex: 1 }}>
          <Card style={{ borderRadius: '15px', height: '500px', minWidth: '300px', display: 'flex', flexDirection: 'column' }}>
            <CardContent style={{ flex: '1', padding: '25px' }}>
              <Typography gutterBottom>
                Simplify OKR tracking
              </Typography>
              <Typography variant="h3" mt={3} mb={1} style={{color:'rgb(0, 0, 237)'}}>
                <ArrowUpwardIcon style={{ verticalAlign: 'middle', fontSize: '3rem', color:'rgb(0, 0, 237)' }} /> 80%
              </Typography>
              <Typography variant='h6'>
                of goals achieved with OKRs on consultant.ai
              </Typography>
              <Link href="#" variant='overline' style={{textDecoration: 'none', fontSize: '15px', color:'rgb(0, 0, 237)', marginTop: '30px', marginBottom: '15px', display: 'block'}}>
                Learn more
              </Link>
            </CardContent>
            <CardMedia
              component="img"
              image="../testimages/man.png"
              alt="Man with laptop"
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={4} style={{ flex: 1 }}>
          <Card style={{ borderRadius: '15px', height: '500px', minWidth: '300px', display: 'flex', flexDirection: 'column' }}>
            <CardContent style={{ flex: '1',padding: '25px' }}>
              <Typography gutterBottom>
                Onboard clients successfully
              </Typography>
              <Typography variant="h3" mt={3} mb={1} style={{color:'rgb(0, 0, 237)'}}>
                <ArrowUpwardIcon style={{ verticalAlign: 'middle', fontSize: '3rem', color:'rgb(0, 0, 237)' }} /> 74%
              </Typography>
              <Typography variant='h6'>
                improvement in customer retention
              </Typography>
              <Link href="#" variant='overline' style={{textDecoration: 'none', fontSize: '15px', color:'rgb(0, 0, 237)', marginTop: '30px', marginBottom: '15px', display: 'block'}}>
                Learn more
              </Link>
            </CardContent>
            <CardMedia
              component="img"
              image="../testimages/woman.png"
              alt="Woman with laptop"
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Efficiency;