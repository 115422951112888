import React from 'react';
import {Grid, Typography} from "@mui/material";

interface TitleTextProps {
    children: string;
}

const TitleText: React.FC<TitleTextProps> = ({ children }) => {
    return (
        <Grid item xs={12}
              sx={{
                  textAlign: 'center',
              }}
        >
            <Typography
                variant="h1"
                component="h1"
                sx={{
                    fontSize: {xs:'2.5em', sm:'4em', lg:'5em'},
                    fontWeight: '900',
                    letterSpacing: '-0.03em',
                    fontFamily: 'Inter Display,sans-serif',
                    lineHeight: '1',
                    whiteSpace: 'pre-line',

                }}
            >
                <span className="gradient-text"><strong>{children}</strong></span>
                
            </Typography>
        </Grid>
    );
}

export default TitleText;