import React from "react";
import { Box, Grid } from "@mui/material";
import TerminalIcon from "@mui/icons-material/Terminal";
import Typography from "@mui/material/Typography";

const WelcomeSoftwareTitle = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box 
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        border: "2px, solid, black",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            border: "2px, solid, black",
                        }}
                    >
                        <Box
                            width={40}
                            height={40}
                            mt={1}
                            style={{ backgroundColor: "rgb(56, 116, 203)"}}
                            sx={{
                                display: "flex",
                                borderRadius: "999px",
                                justifyContent: "Center",
                                alignItems: "Center",
                                flexShrink: "0",
                            }}
                        >
                            <TerminalIcon
                                style={{ color: "white", fontSize: "30px" }}
                            />
                        </Box>
                        <Box ml={2}>
                            <Typography variant="h3" letterSpacing="-0.02em">
                                <span className="gradient-text"><strong>Introducing Planning Mastery.</strong></span>
                            </Typography>
                        </Box>
                    </Box>

                    <Box ml={7} mt={2}>
                        <Typography variant="h4" letterSpacing="-0.02em">
                            <strong>
                                Revolutionize Your Project Management
                            </strong>
                        </Typography>
                        <Typography variant="h6">
                            While traditional tools help you document, our bot
                            empowers you to strategize. Dive into a guided
                            planning experience, where every step is a leap
                            towards project success. From epics to sprints,
                            transform your ideas into actionable plans, enriched
                            with AI-driven insights. Seamlessly integrate with
                            Jira and watch as your strategies come to life,
                            ensuring your team is always aligned and ahead of
                            the curve.
                        </Typography>
                    </Box>

                </Box>
            </Grid>
        </Grid>
    );
};

export default WelcomeSoftwareTitle;
