//Collecting all nodes with children from the menu tree
export const collectAllNodesWithChildren = (tree) => {
    const collectList = [];
    for (let node of tree) {
        if ('children' in node) {
            collectList.push(node.stepId);
            collectList.push( ...collectAllNodesWithChildren(node.children));
        }
    }
    return collectList;
}

export const collectAllNodes = (tree) => {
    const collectList = [];
    for (let node of tree) {
        collectList.push(node.stepId);
        if ('children' in node) {
            collectList.push( ...collectAllNodes(node.children));
        }
    }
    return collectList;
}

// We collect all new nodes.
// This is necessary so that new nodes are displayed in the menu tree that opens.
export const collectNewNodes = (currentList, newList) => {
    return newList.filter( (value) => !( currentList.includes(value) ) )
}


// We are looking for a branch of nodes where the selected node is located.
// This is to keep this branch open.
export const foundNodesInBranch = (stepId, tree,  iteration=0) => {
    let temp = '';
    let isNodeInTree = false ;
    if ( iteration === 0 ) {
        const allNodes = collectAllNodes( tree );
        isNodeInTree = allNodes.includes(stepId);
    }
    if ( iteration > 0 || isNodeInTree) {
        for (let node of tree) {
            if ('children' in node) {
                temp = foundNodesInBranch(stepId, node.children, iteration++);
                if (Boolean(temp)) {
                    return [...temp, node.stepId];
                }
            }
            if (node.stepId === stepId) {
                return [ ];
            }
        }
    }
    return false;
}

// We need to collect the parent nodes of the newly added nodes.
// This is necessary so that we can expand the menu tree to new nodes.
export const foundNodesInBranchForNewNodes = (tree, listOfNewNodes) => {
    const listToReturn = [];
    let tempList = [];
    for ( let n of listOfNewNodes ) {
        tempList = foundNodesInBranch( n, tree );
        if (tempList) {
            listToReturn.push(...tempList.filter(v =>
                !( listToReturn.includes(v) )
            ))
        }
    }
    return listToReturn;
}