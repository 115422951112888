
import React, {useContext} from 'react';
import { Button, Grid, Typography, Paper, Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { MixpanelContext } from '../../contexts/MixpanelContext';

interface PricingCardProps {
    title: string;
    price: string;
    subtitle?: string;
    description: string;
    featureHeader: string;
    features: string[];
    buttonText: string;
    selectedSeats: number;
    isMostPopular?: boolean;
}

const PricingCard: React.FC<PricingCardProps> = ({
    title,
    price,
    subtitle,
    description,
    features,
    buttonText,
    selectedSeats,
    isMostPopular,
    featureHeader,
}) => {
    const calculateTotalPrice = () => {
        if (!price || !price.includes('$')) return '';
        const priceValue = parseFloat(price.slice(1));
        return `$${(priceValue * selectedSeats).toFixed(2)}`;
    };

    const renderTotalPrice = () => {
        if (title === 'Free') return 'Up to 2 seats';
        if (title === 'Enterprise') return null;
        return `Total ${calculateTotalPrice()} / month billed annually`;
    };

    const determinePriceColor = (priceValue: number) => {
        if (priceValue === 0) return;
        if (priceValue <= 8) return 'rgb(105,220,250)';
        if (priceValue <= 10) return 'rgb(175, 95, 225)';
        if (priceValue <= 19) return 'rgb(120, 225, 9)';
        return 'black';
    };

    const { sendAnalyticsEvent } = useContext(MixpanelContext)

    return (
      <Grid item xs={12} sm={6} md={4} lg={2.4}>
        <Paper
          elevation={3}
          style={{ padding: '1rem', height: '100%', margin: '0 8px' }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="10px"
          >
            <Typography variant="h5" style={{ fontWeight: 600 }}>
              {title}
            </Typography>
            {isMostPopular && (
              <Typography
                style={{
                  color: 'white',
                  backgroundColor: determinePriceColor(
                    parseFloat(price.slice(1))
                  ),
                  padding: '0.25rem',
                  borderRadius: '3px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                Most Popular
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h3"
              style={{
                fontWeight: 600,
                marginBottom: '0.5rem',
                color: determinePriceColor(parseFloat(price.slice(1))),
              }}
            >
              {price}
            </Typography>
            {subtitle && (
              <Box style={{ marginLeft: '0.5rem' }}>
                {subtitle.split(' / ').map((word, idx, arr) => (
                  <Typography variant="subtitle2" key={idx} display="block">
                    {word + (idx === 0 && arr.length > 1 ? ' /' : '')}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 400,
              marginTop: '0.5rem',
              height: '70px',
            }}
          >
            {renderTotalPrice()}
          </Typography>
          {buttonText === 'Contact us' ? (
            <NavLink to="/contact-sales">
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '1rem 0' }}
                onClick={() => {
                  sendAnalyticsEvent('NavigateToContactSales', {
                    eventCategory: 'Navigation',
                    action: 'Navigate to Contact Sales',
                  })
                }}
              >
                {buttonText}
              </Button>
            </NavLink>
          ) : (
            <NavLink to="/signup">
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '1rem 0' }}
                onClick={() => {
                  sendAnalyticsEvent('NavigateToSignup', {
                    eventCategory: 'Navigation',
                    action: 'Navigate to Signup',
                  })
                }}
              >
                {buttonText}
              </Button>
            </NavLink>
          )}
          <Typography
            variant="body1"
            style={{
              fontWeight: 500,
              marginBottom: '1rem',
              height: '70px',
            }}
          >
            {description}
          </Typography>
          <Box
            style={{
              borderBottom: '1px solid #e0e0e0',
              margin: '1rem 0',
            }}
          />
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 800, marginBottom: '1rem' }}
          >
            {featureHeader}
          </Typography>
          <Box style={{ flexGrow: 1, marginBottom: '1rem' }}>
            {features.map((feature, index) => (
              <Typography
                variant="body2"
                key={index}
                style={{ marginBottom: '0.5rem', fontWeight: 400 }}
              >
                {feature}
              </Typography>
            ))}
          </Box>
        </Paper>
      </Grid>
    )
};

export default PricingCard;