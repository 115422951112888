import { useEffect } from "react";
import Header from "../components/global/Header";
import PrivacyContent from "../components/Privacy/PrivacyContent";

const Privacy = () => {
    useEffect(() => {
        document.title = "Privacy Policy | Consultant.AI";
    }, []);

    return (
        <div id="privacy" className="page">
            <Header />
            <PrivacyContent />
        </div>
    );
};

export default Privacy;
