import { Dispatch, SetStateAction, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { NavLink } from "react-router-dom";
import { CircularProgress, Menu, MenuItem, MenuList } from "@mui/material";
import axios from "../../utils/AxiosInstance";
import { logUserOut } from "../../utils/logUserOut";

import { IPopoverMenuItem } from "../../models/IPopoverMenuItem";
import { IAuthContext } from "../../models/IAuthContext";
import { MixpanelContext } from "../../contexts/MixpanelContext";

const PopoverMenu = ({
    anchorEl,
    showPopoverMenu,
    setShowPopoverMenu,
    onClose,
}: {
    anchorEl: HTMLElement | null;
    showPopoverMenu: boolean;
    setShowPopoverMenu: Dispatch<SetStateAction<boolean>>;
    onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
    const authContext = useContext(AuthContext);
    const { isLoggedIn, setIsLoggedIn, setActiveUser, setIsLoggedOut } =
        authContext as IAuthContext;
    const {sendAnalyticsEvent} = useContext(MixpanelContext);
    const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);


    let popoverMenuItems: IPopoverMenuItem[] = [   
        {
            urlSlug: "/contact",
            label: "Contact / Feedback",
        },
        {
            urlSlug: "/terms",
            label: "Terms of Service",
        },
        {
            urlSlug: "/privacy",
            label: "Privacy Policy",
        },
        {
            urlSlug: "/",
            label: "Log Out",
        },
    ];

    const navigate = useNavigate();

    const handleLogout = async () => {
        setIsLoggingOut(true);
        try {
            await axios.get("/auth/logout", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "authToken"
                    )}`,
                },
            });
            if (localStorage.getItem('workspaceChatHistory')){
                localStorage.removeItem('workspaceChatHistory')
            }
            if (localStorage.getItem('chatHistory')){
                localStorage.removeItem('chatHistory')
            }
        } catch (err) {
            console.error(err);
        } finally {
            logUserOut(setIsLoggedIn, setActiveUser);
            setIsLoggedOut(true);
            setIsLoggingOut(false);
            setShowPopoverMenu(false);
            navigate("/");
        }
        sendAnalyticsEvent('Logout', {
          eventCategory: 'Authentication',
          action: 'Logout',
        })
    };

    // Define a state variable to track the screen width
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

    // Update the screenWidth state when the window is resized
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (screenWidth <= 768) {
        popoverMenuItems.unshift({
            urlSlug: "/contact-sales",
            label: "Contact Sales",
        });
    };

    // If the user is logged in, add the /about route
    if (isLoggedIn) {
        popoverMenuItems = [
            {
                urlSlug: "/about",
                label: "About",
            },
            ...popoverMenuItems,
        ];
    }

    // Only add the login item to the menu if the user is not logged in
    if (!isLoggedIn) {
        popoverMenuItems = [
            {
                urlSlug: "/login",
                label: "Login",
            },
            ...popoverMenuItems,
        ];
    };


    return (
        <Menu
            id="popover-menu"
            anchorEl={anchorEl}
            open={showPopoverMenu}
            onClose={onClose}
            sx={{ marginTop: 1 }}
        >
            <MenuList dense disablePadding>
                {popoverMenuItems.map(item => (
                    <NavLink
                        to={item.urlSlug}
                        key={item.urlSlug.replace("/", "")}
                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                            if (item.label === "Log Out") {
                                e.preventDefault();
                                handleLogout();
                            }
                        }}
                        state={item.label === "Contact Sales" ? { title: "Contact Sales" } : undefined}
                    >
                        <MenuItem
                            disabled={
                                item.label === "Log Out"
                                    ? isLoggingOut
                                        ? true
                                        : false
                                    : false
                            }
                            sx={{
                                display:
                                    item.label === "Log Out"
                                        ? isLoggedIn
                                            ? "block"
                                            : "none"
                                        : "block",
                            }}
                        >
                            {item.label === "Log Out" ? (
                                isLoggingOut ? (
                                    <CircularProgress
                                        thickness={5}
                                        size="1.75em"
                                    />
                                ) : (
                                    item.label
                                )
                            ) : (
                                item.label
                            )}
                        </MenuItem>
                    </NavLink>
                ))}
            </MenuList>
        </Menu>
    );
};

export default PopoverMenu;
